export const static_translation = {
    "dexsire_is_respect": "Dexsire ist Respekt",
    "safety_and_dignity": "Sicherheit und Würde",
    "register_right_now": "Registrieren Sie sich jetzt.",
    "register": "Registrieren",
    "our_escorts": "Unsere Escorts",
    "discover_some_models_available_to_you": "Entdecken Sie einige für Sie verfügbare Models",
    "load_more": "Mehr laden",
    "connecting_you_to_the_highest_level_of_experience": "Wir verbinden Sie mit dem höchsten Erfahrungsniveau.",
    "find_escorts_by_region": "Finden Sie Escorts nach Region",
    "contact_us": "Kontaktieren Sie uns",
    "frequently_asked_questions": "Häufig gestellte Fragen",
    "copyright_2024_dexsire": "© Copyright 2024 Dexsire, Schweiz. Alle Rechte vorbehalten.",
    "privacy_policy": "Datenschutzrichtlinie",
    "terms_of_service": "Servicebedingungen",
    "and": "und",
    "service_terms": "Servicebedingungen",
    "canton":"Kanton",
    "ville":"Ville",
    "services_offered":"Angebotene Dienstleistungen",
    "category":"Kategorie",
    "clear_filter":"Filter löschen",
    "find_escorts":"Escorts finden",
    "find_the_ideal_model":"Finden Sie das ideale Model für sich",
    "login_register": "Anmelden/Registrieren",
    "announcement": "Ankündigung",
    "my_profile": "Mein Profil",
    "exit": "Beenden",
    "home": "Startseite",
    "advanced_search": "erweiterte Suche",
    "advertise_here": "Hier werben",
    "contact": "Kontakt",
    "im_a_escorts": "Ich bin eine Escorts",
    "im_a_customer": "Ich bin Kunde",
    "i_already_have_registration": "Ich bin bereits registriert",
    "i_want_to_sign_up": "Ich möchte mich anmelden",
    "login": "Anmelden",
    "enter_your_username_and_password": "Geben Sie Ihren Benutzernamen und Ihr Passwort ein.",
    "i_forgot_my_password": "Ich habe mein Passwort vergessen",
    "to_enter": "Zum Anmelden",
    "email": "E-Mail",
    "password": "Passwort",
    "fill_email_field": "E-Mail-Feld ausfüllen",
    "fill_name_field": "Namensfeld ausfüllen",
    "fill_password_field": "Passwortfeld ausfüllen",
    "fill_360_video": "360-Grad-Video ausfüllen",
    "minimum_4_photos": "Mindestens 4 Fotos",
    "fill_pseudo_field": "Pseudofeld ausfüllen",
    "fill_category_field": "Kategoriefeld ausfüllen",
    "fill_bio_field": "Biofeld ausfüllen",
    "fill_age_field": "Altersfeld ausfüllen",
    "your_age_must_be_over_18": "Sie müssen über 18 Jahre alt sein",
    "fill_phone_field": "Telefonfeld ausfüllen",
    "fill_canton_field": "Kantonfeld ausfüllen",
    "fill_ville_field": "Villefeld ausfüllen",
    "fill_nationality_field": "Nationalitätsfeld ausfüllen",
    "fill_size_field": "Größenfeld ausfüllen",
    "fill_weight_field": "Gewichtsfeld ausfüllen",
    "fill_hair_color_field": "Haarfarbefeld ausfüllen",
    "fill_breasts_field": "Brüstefeld ausfüllen",
    "fill_eyes_color_field": "Augenfarbefeld ausfüllen",
    "select_at_least_one_mobility_option": "Wählen Sie mindestens eine Mobilitätsoption aus",
    "select_at_least_one_payment_option": "Wählen Sie mindestens eine Zahlungsoption",
    "select_at_least_one_language_option": "Wählen Sie mindestens eine Sprachoption",
    "fill_in_your_details": "Geben Sie Ihre Daten ein",
    "user_registration": "Benutzerregistrierung",
    "plan": "Plan",
    "select_a_plan": "Wählen Sie einen Plan aus",
    "personal_data": "Persönliche Daten",
    "profile": "Profil",
    "appearance": "Aussehen",
    "payment": "Zahlung",
    "model_registration": "Modelregistrierung",
    "buy_highlights": "Highlights kaufen",
    "fill_in_your_profile": "Fügen Sie Ihr Profil ein",
    "name":"Name",
    "confirm_password":"Passwort bestätigen",
    "fill_all_fields":"Alle Felder ausfüllen",
    "invalid_email":"Ungültige E-Mail",
    "password_and_confirmation_not_match":"Passwort und Bestätigung stimmen nicht überein",
    "account_successfully_registred":"Konto erfolgreich registriert!",
    "registration_completed_successfully":"Registrierung erfolgreich abgeschlossen",
    "you_must_verify":"Sie müssen Ihre E-Mail bestätigen, um Ihr Konto zu aktivieren. Wenn Sie es nicht in Ihrem Posteingang finden, überprüfen Sie bitte Ihren Spam-Ordner.",
    "take_the_opportunity":"Nutzen Sie die Gelegenheit, Ihr Profil hervorzuheben, investieren Sie in Credits, um ganz oben zu bleiben.",
    "want_to_buy_later":"Möchten Sie später kaufen",
    "i_want_to_buy_credits_now":"Ich möchte jetzt Credits kaufen",
    "advance":"Vorrücken",
    "now_its_time_to_report_your_appearance":"Jetzt ist es Zeit, Ihr Erscheinen zu melden",
    "verification_photo":"Verifizierungsfoto",
    "exemple":"Beispiel",
    "send_a_photo_holding":"Senden Sie ein Foto, auf dem Sie ein Papier mit der Aufschrift dexsire.com halten",
    "drag_the_image_here_or_click_here":"Ziehen Sie das Bild hierher oder klicken Sie hier",
    "i_agree_with_the_platform":"Ich stimme den Plattform",
    "accept_terms_to_continue":"Bedingungen akzeptieren, um fortzufahren",
    "pseudo": "Pseudo",
    "bio": "Bio",
    "age": "Alter",
    "phone": "Telefon",
    "nationality": "Nationalität",
    "size": "Größe",
    "weight": "Gewicht",
    "height": "Größe",
    "language": "Sprache",
    "hair_color": "Haarfarbe",
    "breasts": "Brüste",
    "eye_color": "Augenfarbe",
    "mobility": "Mobilität",
    "payments_accepted": "Akzeptierte Zahlungen",
    "languages_spoken": "Gesprochene Sprachen",
    "what_services_do_you_offer": "Welche Dienste bieten Sie an?",
    "describe_yourself_below": "Beschreiben Sie sich unten:",
    "note": "Hinweis:",
    "the_file_is_too_long": "Die Datei ist zu groß. Die maximale Größe beträgt 50 MB.",
    "upload_a_360": "Laden Sie ein 360º-Video hoch",
    "instruction_on_how": "Anleitung zum Aufzeichnen eines 360º-Videos",
    "drag_video": "Ziehen Sie das Video hierher oder klicken Sie hier",
    "supported_video": "Unterstützte Dateien: MP4 oder AVI",
    "maximum_video": "Maximale Dateigröße: 50 MB",
    "upload": "Hochladen",
    "drag_image": "Ziehen Sie das Bild hierher oder klicken Sie hier",
    "supported_image": "Unterstützte Dateien: JPG und PNG",
    "maximum_image": "Maximale Dateigröße: 8 MB. Mindestens 4 Fotos.",
    "the_image_is_too_big": "Die Datei ist zu groß. Die maximale Größe beträgt 8 MB.",
    "in_addition": "Zusätzlich zu den regelmäßigen Hervorhebungen, die Teil der Bedienfeldfunktionen sind, haben Sie auch die Möglichkeit, zusätzliche Hervorhebungen zu erwerben. Dadurch können Sie Ihre Anzeigen häufiger oder für längere Zeiträume schalten und so Ihre Präsenz auf der Plattform weiter steigern.",
    "license": "Lizenz",
    "license_5days": "5 Tage - Lizenz",
    "license_5days_price": "89,90 Franken",
    "payment_via": "Die Zahlung für Ihr Abonnement erfolgt über",
    "continue": "Weiter",
    "choose_plan": "Plan auswählen",
    "announcement_title_1": "Klare und intuitive Benutzeroberfläche",
    "announcement_desc_1": "Wir bieten eine klare und intuitive Benutzeroberfläche, damit Models ihre Anzeigen effizient und unkompliziert verwalten können. Unsere Plattform ist so konzipiert, dass sie einfach zu bedienen ist und ein stressfreies und angenehmes Anzeigenverwaltungserlebnis bietet.",
    "announcement_title_2": "Anzeigenverwaltungsoptionen",
    "announcement_desc_2": "Haben Sie mit unseren Optionen zum Erstellen, Bearbeiten und Löschen die volle Kontrolle über Ihre Anzeigen. Sie können neue Anzeigen erstellen, vorhandene Informationen bearbeiten und Anzeigen nach Bedarf entfernen – alles an einem Ort.",
    "announcement_title_3": "Aktualisieren persönlicher Informationen",
    "announcement_desc_3": "Halten Sie Ihre Informationen ganz einfach auf dem neuesten Stand. Mit unserer Plattform können Sie persönliche Informationen wie Fotos und Beschreibungen aktualisieren und so sicherstellen, dass Ihre Kunden die neuesten Informationen zu Ihren Diensten erhalten.",
    "announcement_title_4": "Alle 45 Minuten hervorheben",
    "announcement_desc_4": "Heben Sie alle 45 Minuten hervor, um Ihre Sichtbarkeit zu maximieren und mehr Kunden anzuziehen. Mit nur einem Klick können Sie sicherstellen, dass Ihre Anzeige prominent angezeigt wird und sich von den anderen abhebt, um eine größere Aufmerksamkeit zu erzielen.",
    "announcement_title_5": "Hervorhebungen kaufen",
    "announcement_desc_5": "Erhöhen Sie Ihre Sichtbarkeit und Reichweite, indem Sie zusätzliche Hervorhebungen für Ihre Anzeigen kaufen. Wir bieten flexible Optionen, sodass Sie den Plan wählen können, der Ihren Anforderungen und Ihrem Budget am besten entspricht, und so sicherstellen können, dass Ihr Profil die Aufmerksamkeit erhält, die es verdient.",
    "announcement_title_ads": "Hier werben",
    "announcement_desc_ads_1": "Werden Sie Mitglied und genießen Sie eine Plattform, die sich der Förderung Ihrer Sicherheit, Sichtbarkeit und Ihres Erfolgs widmet.",
    "announcement_desc_ads_2": "Wenn Sie sich bei LUXEO registrieren, haben Sie Zugriff auf eine Reihe exklusiver Vorteile, darunter:",
    "announcement_desc_ads_3": "Verschwenden Sie keine Zeit! Registrieren Sie sich jetzt und begeben Sie sich mit uns bei LUXEO auf diese erfolgreiche Reise.",
    "announcement_action_ads": "Jetzt registrieren",
    "announcement_section_title1": "Benutzerdefiniertes Profil",
    "announcement_section_desc1": "Erstellen Sie ein einzigartiges und attraktives Profil, das Ihre Talente, Dienstleistungen und Persönlichkeit hervorhebt.",
    "announcement_section_title2": "Erweitern Sie Ihre Sichtbarkeit",
    "announcement_section_desc2": "Erreichen Sie ein breiteres Publikum und steigern Sie Ihre Geschäftsmöglichkeiten, indem Sie in unserem Bereich „Featured Girls“ vorgestellt werden.",
    "announcement_section_title3": "Benutzerfreundlichkeit",
    "announcement_section_desc3": "Unsere intuitive und benutzerfreundliche Plattform vereinfacht die Verwaltung Ihres Profils und die Interaktion mit potenziellen Kunden.",
    "announcement_section_title4": "Positive Bewertungen",
    "announcement_section_desc4": "Erhalten Sie wertvolles Feedback von zufriedenen Kunden und steigern Sie Ihren Ruf in der Community.",
    "announcement_section_title5": "Sonderaktionen",
    "announcement_section_desc5": "Erhalten Sie exklusiven Zugang zu Sonderangeboten und Werbeveranstaltungen, um Ihr Geschäft anzukurbeln.",
    "announcement_form_title": "Anmelden",
    "announcement_form_subtitle": "Ich bin bereits Werbetreibender",
    "announcement_form_action1": "Registrieren",
    "announcement_form_action_title": "Ich habe keinen Kontakt auf der Plattform",
    "announcement_form_action2": "Konto erstellen",
    "announcement_form_email": "E-Mail",
    "announcement_form_password": "Passwort",
    "announcement_form_forgot": "Ich habe mein Passwort vergessen",
    "announcement_form_submit": "Anmelden",
    "forgot_title": "Ich habe mein Passwort vergessen",
    "forgot_subtitle": "Geben Sie Ihre Registrierungs-E-Mail ein",
    "forgot_input_email": "E-Mail",
    "forgot_action": "Link zur Passworterneuerung senden",
    "forgot_success_title": "E-Mail erfolgreich gesendet.",
    "forgot_success_text": "Wir haben Ihnen eine E-Mail mit einem Link zur Erneuerung Ihres Passworts gesendet. Wenn Sie ihn nicht finden können, überprüfen Sie Ihren Spam-Ordner.",
    "create_password_validate1": "Neues Passwortfeld ausfüllen",
    "create_password_validate2": "Neues Passwortbestätigungsfeld ausfüllen",
    "create_password_validate3": "Passwort und Passwortbestätigung stimmen nicht überein",
    "create_password_success": "Passwort erfolgreich erstellt",
    "create_password_title": "Ich habe mein Passwort vergessen",
    "create_password_text": "Neues Passwort erstellen",
    "create_password_input_1": "Neues Passwort",
    "create_password_input_2": "Neues Passwort bestätigen",
    "create_password_input_submit": "Speichern",
    "purchase_title": "Profil markieren",
    "purchase_subtitle": "Credits kaufen",
    "purchase_text": "Wählen Sie aus, wie viele Credits Sie kaufen möchten",
    "purchase_action": "Ich möchte später kaufen",
    "purchase_selected": "Ausgewählt",
    "purchase_select": "Auswählen",
    "customer_profile_name": "Name",
    "customer_profile_user": "Benutzer seit",
    "customer_profile_status": "Status",
    "customer_profile_email": "E-Mail",
    "customer_profile_subs_type": "Abonnementtyp",
    "customer_profile_subs_value": "Abonnementwert",
    "customer_profile_saved": "Gespeichert",
    "customer_profile_save": "Speichern",
    "customer_profile_edit": "Informationen bearbeiten",
    "customer_profile_delete": "Konto löschen",
    "customer_profile_fav": "Escorts, die Sie zu Ihren Favoriten hinzugefügt haben",
    "gallery_video": "Videogalerie",
    "gallery_image": "Fotogalerie",
    "gallery_load_more": "Weitere laden",
    "aboutme_contact": "Kontaktieren Sie mich",
    "aboutme_contact_text": "Ich beantworte keine Sprachanrufe",
    "aboutme_contact_some_photos": "Sehen Sie sich einige Fotos und Videos an",
    "aboutme_contact_photos": "Fotos",
    "aboutme_contact_videos": "Videos",
    "review_service": "Service",
    "review_hygiene": "Hygiene",
    "review_socialtime": "Gesellige Zeit",
    "review_apparence": "Aussehen",
    "review_location": "Standort",
    "review_comment": "Kommentar",
    "review_fill_all_fields": "Füllen Sie alle Felder aus",
    "review_what_customer_saying": "Was einige Kunden sagen:",
    "review_left_comment": "Haben Sie Ihren Kommentar hinterlassen",
    "review_add_your_review": "Fügen Sie Ihre Bewertung hinzu",
    "review_sent_review": "Bewertung gesendet",
    "escortservice_success": "Erfolgreich gespeichert",
    "escortservice_title": "Kontaktieren Sie mich",
    "escortservice_text": "Ich beantworte keine Sprachanrufe",
    "contact_title": "Kontakt",
    "contact_email": "E-Mail",
    "contact_whats_desc": "Sprechen Sie mit uns über WhatsApp.",
    "contact_whats_label": "Klicken Sie hier",
    "contact_privacy_title": "Datenschutzrichtlinie",
    "contact_privacy_desc": "Erhalten Sie wertvolles Feedback von zufriedenen Kunden und steigern Sie Ihren Ruf in der Community.",
    "contact_privacy_label": "Zugriff auf FAQ",
    "contactform_name": "Ihr Name",
    "contactform_email": "Ihre beste E-Mail",
    "contactform_subject": "Betreff",
    "contactform_message": "Nachricht",
    "contactform_fill_fields": "Alle Felder ausfüllen",
    "contactform_invalidmail": "Ungültige E-Mail",
    "contactform_success": "Kontakt erfolgreich gesendet",
    "contactform_title": "Benötigen Sie Hilfe? Es wird mir ein Vergnügen sein, mit Ihnen zu sprechen.",
    "contactform_action": "Nachricht senden",
    "faq_title": "Häufig gestellte Fragen",
    "faq_subtitle": "Häufig gestellte Fragen von Benutzern zur Verwendung der Anwendung, ihren Funktionen und Richtlinien.",
    "faq_text": "Nicht gefunden, wonach Sie gesucht haben?",
    "faq_subtext": "Kontaktieren Sie uns.",
    "faq_action": "Nachricht senden",
    "notfound_title": "Fehler 404",
    "notfound_text": "Die Seite, auf die Sie zugreifen wollten, existiert nicht oder ist vorübergehend nicht erreichbar.",
    "notfound_action": "Zur Startseite zurückkehren",
    "advancedsearch_canton": "Kanton",
    "advancedsearch_age": "Alter",
    "advancedsearch_services": "Angebotene Dienste",
    "advancedsearch_category": "Kategorie",
    "advancedsearch_customerreviews": "Kundenrezensionen",
    "advancedsearch_nationality": "Nationalität/ethnische Herkunft",
    "advancedsearch_lang": "Gesprochene Sprachen",
    "advancedsearch_appearance": "Äußeres Erscheinungsbild",
    "advancedsearch_find": "Escorts finden",
    "advancedsearch_title": "Erweiterte Suche",
    "terms_title1": "1. Einleitung",
    "terms_content1": "Die Seite mit den Nutzungsbedingungen ist ein wesentlicher Bestandteil unserer Plattform und definiert die Bedingungen, die die Nutzung unserer Dienste durch Benutzer regeln. Diese Seite legt die Rechte und Pflichten sowohl der Benutzer als auch des Unternehmens fest und gewährleistet ein sicheres und transparentes Erlebnis für alle Beteiligten.",
    "terms_title2": "2. Annahme der Bedingungen",
    "terms_content2": "Wir erklären, dass die Nutzung unserer Plattform eineAutomatische und verbindliche Annahme der Servicebedingungen.",
    "terms_title3": "3. Beschreibung der Dienste",
    "terms_content3": "Wir beschreiben die von unserer Plattform angebotenen Dienste detailliert, einschließlich ihrer Funktionen, Merkmale und Einschränkungen. Dadurch erhalten die Benutzer ein klares Verständnis davon, was sie bei der Nutzung unserer Dienste erwarten können.",
    "terms_title4": "4. Pflichten der Benutzer",
    "terms_content4": "Wir klären die Pflichten der Benutzer bei der Nutzung unserer Plattform auf, einschließlich: Bereitstellung genauer und aktueller Informationen bei der Registrierung, Achtung der Rechte an geistigem Eigentum, Wahrung der Sicherheit der Zugangsdaten und Nichtverletzung geltender Gesetze oder Rechte Dritter.",
    "terms_title5": "5. Geistiges Eigentum",
    "terms_content5": "Wir informieren die Benutzer über das Eigentum an Urheberrechten, Marken und anderen Rechten an geistigem Eigentum in Bezug auf unsere Plattform und deren Inhalte. Die Benutzer verpflichten sich, diese Rechte zu respektieren und unsere Inhalte nicht unbefugt zu verwenden.",
    "terms_title6": "6. Datenschutzrichtlinie",
    "terms_content6": "Wir verweisen auf unsere Datenschutzrichtlinie und erklären, wie wir mit Benutzerinformationen umgehen. Benutzer werden aufgefordert, unsere Datenschutzrichtlinie zu lesen, um zu verstehen, wie ihre Informationen gesammelt, verwendet und geschützt werden.",
    "terms_title7": "7. Änderungen der Servicebedingungen",
    "terms_content7": "Wir behalten uns das Recht vor, die Servicebedingungen nach Bedarf zu ändern oder zu aktualisieren. Änderungen werden mit der Veröffentlichung der überarbeiteten Bedingungen auf unserer Plattform wirksam.",
    "terms_title8": "8. Haftungsbeschränkung",
    "terms_content8": "Wir stellen das Unternehmen von der Haftung für direkte, indirekte, zufällige, Folge- oder Strafschäden frei, die sich aus der Nutzung unserer Dienste ergeben. Benutzer erklären sich damit einverstanden, unsere Dienste auf eigenes Risiko zu nutzen.",
    "terms_title9": "9. Beendigung des Dienstes",
    "terms_content9": "Wir behalten uns das Recht vor, den Zugriff von Benutzern auf unsere Plattform ohne vorherige Ankündigung zu beenden oder auszusetzen, wenn gegen die Nutzungsbedingungen verstoßen wird.",
    "terms_title10": "10. Allgemeine Bestimmungen",
    "terms_content10": "Wir nehmen Bestimmungen zu geltendem Recht, Gerichtsbarkeit, Rechtsverzicht, Vertragsintegrität und anderen allgemeinen Bestimmungen auf, um die Durchsetzbarkeit und Wirksamkeit der Nutzungsbedingungen sicherzustellen.",
    "terms_title11": "11. Kontakt",
    "terms_content11": "Wir stellen Kontaktinformationen zur Verfügung, damit Benutzer uns bei Fragen, Bedenken oder Problemen bezüglich der Servicebedingungen kontaktieren können.",
    "terms_title": "Servicebedingungen",
    "terms_subtitle": "Lesen Sie die Servicebedingungen der Plattform sorgfältig durch, bevor Sie fortfahren.",
    "purchasedetails_title": "Kaufdetails",
    "purchasedetails_text1": "Sie erhalten Ihre Anzeige",
    "purchasedetails_text2": "Nach diesem Zeitraum müssen Sie Ihr Abonnement erneut aktivieren.",
    "purchasedetails_text3": "Sie können Ihre Anzeige jederzeit vor Ablauf der Frist pausieren. Auf diese Weise werden die Credits auch pausiert, damit Sie sie jederzeit verwenden können.",
    "purchasedetails_text4": "Planwert",
    "purchasedetails_text_francs": "Franken",
    "purchasedetails_text_confirm": "Zahlung bestätigen",
    "purchasedetails_text_congratulations1": "Herzlichen Glückwunsch. Ihr",
    "purchasedetails_text_congratulations2": "vorgestellter Plan wurde erfolgreich aktiviert!",
    "profilemodal_option1": "Kundenprofil",
    "profilemodal_option2": "Interne Seite",
    "profilemodal_option3": "Administrativer Eigentümer",
    "profilemodal_option4": "Administrative Begleitung",
    "profilemodal_title": "Profiltyp auswählen",
    "privacy_title1": "1. Einleitung",
    "privacy_conte1": "Unsere Datenschutzseite soll unsere Benutzer darüber informieren, wie wir ihre persönlichen Daten sammeln, verwenden und schützen, während sie unsere Plattform nutzen. Die Privatsphäre unserer Benutzer hat für uns Priorität, und auf dieser Seite werden unsere Datenschutzpraktiken in Übereinstimmung mit den geltenden Gesetzen und Vorschriften ausführlich beschrieben.",
    "privacy_title2": "2. Erfasste Informationen",
    "privacy_conte2": "In diesem Abschnitt beschreiben wir die Arten personenbezogener Daten, die wir von Benutzern erfassen, und die Methoden, mit denen diese Informationen erhalten werden. Dies umfasst unter anderem: Von Benutzern während der Registrierung bereitgestellte Informationen wie Name, E-Mail, Geburtsdatum usw. Nutzungsdaten wie Browserverlauf, Plattforminteraktionen, Benutzereinstellungen usw. Automatisch durch Cookies und ähnliche Technologien erfasste Informationen.",
    "privacy_title3": "3. Verwendung von Informationen",
    "privacy_conte3": "Wir erklären, wie personenbezogene Daten von Benutzern von unserer Plattform verwendet werden. Dies kann Folgendes umfassen: Personalisierung der Benutzererfahrung und Bereitstellung relevanter Inhalte. Kontinuierliche Verbesserung unserer Dienste und Funktionen. Kommunikation mit Benutzern über Updates, Sonderangebote usw. Einhaltung gesetzlicher und behördlicher Verpflichtungen.",
    "privacy_title4": "4. Informationsweitergabe",
    "privacy_conte4": "Wir erläutern im Detail, mit wem wir Benutzerinformationen teilen und aus welchen Gründen wir diese Weitergabe vornehmen. Dabei kann es sich um folgende Partner handeln: Servicepartner, die uns bei der Bereitstellungidierung unserer Dienste. Justizbehörden, wenn gesetzlich vorgeschrieben oder als Reaktion auf offizielle Anfragen. Dritte im Falle einer Fusion, Übernahme oder Veräußerung von Vermögenswerten.",
    "privacy_title5": "5. Informationsschutz",
    "privacy_conte5": "Wir erläutern die Sicherheitsmaßnahmen, die wir ergreifen, um Benutzerinformationen vor unbefugtem Zugriff, Missbrauch, Änderung oder Offenlegung zu schützen. Dies kann Folgendes umfassen: Datenverschlüsselung. Zugriffskontrollen. Regelmäßige Überwachung von Systemen und Netzwerken.",
    "privacy_title6": "6. Benutzerrechte",
    "privacy_conte6": "Wir informieren Benutzer über ihre Rechte in Bezug auf ihre persönlichen Daten, einschließlich des Rechts auf Zugriff, Korrektur, Löschung oder Einschränkung der Verwendung ihrer Daten. Wir stellen auch Informationen zur Verfügung, wie Benutzer diese Rechte ausüben können.",
    "privacy_title7": "7. Änderungen der Datenschutzrichtlinie",
    "privacy_conte7": "Wir behalten uns das Recht vor, unsere Datenschutzrichtlinie nach Bedarf zu aktualisieren oder zu ändern. Alle wesentlichen Änderungen werden den Benutzern über unsere Plattform oder auf andere geeignete Weise mitgeteilt.",
    "privacy_title8": "8. Kontakt",
    "privacy_conte8": "Wir stellen Kontaktinformationen zur Verfügung, damit Benutzer uns kontaktieren können, wenn sie Fragen, Bedenken oder Anfragen zum Datenschutz haben.",
    "privacy_title9": "9. Datum des Inkrafttretens",
    "privacy_conte9": "Wir geben das Datum des Inkrafttretens unserer Datenschutzrichtlinie an und informieren Benutzer darüber, wie wichtig es ist, diese Seite regelmäßig zu überprüfen, um über unsere Datenschutzpraktiken auf dem Laufenden zu bleiben.",
    "privacy_title10": "10. Einhaltung gesetzlicher Vorschriften",
    "privacy_conte10": "Wir erklären unsere Verpflichtung zur Einhaltung der geltenden Datenschutzgesetze und -vorschriften und informieren die Nutzer über ihre Rechte und Rechtsmittel im Falle von Datenschutzverletzungen.",
    "privacy_title": "Datenschutzrichtlinie",
    "privacy_subtitle": "Lesen Sie die Nutzungsbedingungen der Plattform sorgfältig durch, bevor Sie fortfahren.",
    "deleteaccount_success": "Konto erfolgreich gelöscht",
    "deleteaccount_title": "Sie sind dabei, Ihr Konto dauerhaft zu löschen.",
    "deleteaccount_text": "Wenn Sie sich entschieden haben, Ihr Konto zu diesem Zeitpunkt nicht zu löschen, können Sie diese Seite einfach schließen und unsere Plattform wie gewohnt weiter nutzen. Wenn Sie Fragen haben oder Hilfe benötigen, zögern Sie nicht, unser Support-Team zu kontaktieren. Wir sind hier, um Ihnen zu helfen!",
    "deleteaccount_confirm": "Löschung des Kontos bestätigen",
    "deleteaccount_cancel": "Ich möchte nicht löschen",
    "deleteaccount_success_title": "Ihr Konto wurde erfolgreich gelöscht.",
    "deleteaccount_success_text": "Es war uns eine Freude, dass Sie diese Zeit mit uns verbracht haben. Denken Sie daran, dass Sie jederzeit willkommen sind, wenn Sie uns brauchen. Wir sind hier, um Ihnen zu helfen.",
    "ageverification_title": "Altersüberprüfung",
    "ageverification_text1": "Diese Website enthält Inhalte, die auf Personen über 18 Jahren beschränkt sind. Indem Sie auf \"Bestätigen\" klicken, bestätigen Sie, dass Sie nach den Gesetzen Ihrer Gerichtsbarkeit volljährig sind. Wenn Sie das erforderliche Mindestalter nicht erreicht haben, verlassen Sie diese Site bitte umgehend.",
    "ageverification_text2": "Eine falsche Altersbestätigung verstößt gegen die Nutzungsbedingungen dieser Site.",
    "ageverification_text3": "Sind Sie über 18 Jahre alt?",
    "ageverification_text4": "Jahre alt?",
    "ageverification_yes": "Ja",
    "ageverification_no": "Nein",
    "ageverification_policy": "Datenschutzrichtlinie",
    "ageverification_terms": "Nutzungsbedingungen",
    "escortinfo_reviews": "Von Kunden abgegebene Bewertungen",
    "follower": "Follower",
    "unfollow": "Folgen",
    "imagesuccessfully": "Bild erfolgreich hochgeladen",
    "admin_side_option1": "Home",
    "admin_side_option2": "Über mich",
    "admin_side_option3": "Fotos",
    "admin_side_option4": "Videos",
    "admin_side_option5": "Bewertungen",
    "admin_side_option6": "Dienste",
    "admin_side_option7": "Anzeigen",
    "admin_side_option8": "Zusätzliche Credits",
    "admin_side_option9": "Pläne",
    "admin_side_option10": "Statistiken",
    "admin_side_option11": "Support",
    "admin_side_option12": "Home",
    "admin_side_option13": "Benutzer",
    "admin_side_option14": "Escorts",
    "admin_side_option15": "Berichte",
    "admin_sidefooter_option1": "Konto löschen",
    "admin_sidefooter_option2": "Beenden",
    "admin_dashboard_title1": "Favoriten",
    "admin_dashboard_subtitle1": "Benutzer, die Ihnen folgen",
    "admin_dashboard_title2": "Anzeigenklicks",
    "admin_dashboard_subtitle2": "Letzte 30 Tage",
    "admin_dashboard_title3": "Ihre Credits",
    "admin_dashboard_subtitle3": "Die Credits sind aufgebraucht",
    "admin_dashboard_title4": "Abonnementstatus",
    "admin_dashboard_subtitle4": "Läuft bald ab",
    "admin_dashboard_credit": "Credit",
    "admin_dashboard_announcement": "credAnnouncementit",
    "admin_dashboard_visit": "Besuche Ihres Profils",
    "admin_dashboard_visits": "Besuche",
    "admin_dashboard_activead": "aktive Anzeige",
    "admin_dashboard_aboutme_success": "Über mich erfolgreich aktualisiert",
    "admin_dashboard_aboutme_title": "Über mich",
    "admin_dashboard_aboutme_phone": "Telefon",
    "admin_dashboard_aboutme_whats": "Whatsapp",
    "admin_dashboard_aboutme_telegram": "Telegram",
    "admin_dashboard_aboutme_size": "Größe",
    "admin_dashboard_aboutme_weight": "Gewicht",
    "admin_dashboard_aboutme_nationality": "Nationalität",
    "admin_dashboard_aboutme_haircolor": "Haarfarbe",
    "admin_dashboard_aboutme_breasts": "Brüste",
    "admin_dashboard_aboutme_eyecolor": "Augenfarbe",
    "admin_dashboard_aboutme_gender": "Präferenz (Geschlecht)",
    "admin_dashboard_credits_credit": "Credit",
    "admin_dashboard_credits_franc": "Franken",
    "admin_dashboard_credits_item1_title": "Wie funktioniert das Abonnement?",
    "admin_dashboard_credits_item1_subtitle": "Sie können sich für einen bestimmten Zeitraum anmelden, um auf der Plattform vorgestellt zu werden.",
    "admin_dashboard_credits_item1_button": "30 Tage",
    "admin_dashboard_credits_item1_footer": "Nach diesem Zeitraum müssen Sie Ihr Abonnement erneuern, damit Ihre Anzeigen auf der Plattform hervorgehoben werden und die Chancen steigen, von Benutzern eingestellt zu werden.",
    "admin_dashboard_credits_item2_title": "Credits und Zeiträume",
    "admin_dashboard_credits_item2_subtitle": "Für jeden Tag mit vorgestellter Anzeige benötigen Sie 1 Credit.",
    "admin_dashboard_credits_item2_footer": "Für jeden Tag mit vorgestellter Anzeige benötigen Sie 1 Credit.",
    "admin_dashboard_credits_item3_title": "Kauf von Credits",
    "admin_dashboard_credits_item3_subtitle": "Wählen Sie aus, wie viele Credits Sie kaufen möchten.",
    "admin_dashboard_credits_title": "Ihre Credits",
    "admin_dashboard_credits_subtitle": "Ihre Credits sind abgelaufen. Sie müssen mehr Credits kaufen, um auf der Plattform vorgestellt zu werden.",
    "admin_dashboard_plans1_title": "Basisplan",
    "admin_dashboard_plans1_subtitle": "5 Tage lang vorgestellt",
    "admin_dashboard_plans1_text": "Ihre Anzeige kann 5 Tage lang vorgestellt werden.",
    "admin_dashboard_plans1_label": "Plan auswählen",
    "admin_dashboard_plans2_title": "Bronzeplan",
    "admin_dashboard_plans2_subtitle": "15 Tage lang vorgestellt",
    "admin_dashboard_plans2_text": "Ihre Anzeige kann 2 Wochen lang vorgestellt werden.",
    "admin_dashboard_plans2_label": "Plan auswählen",
    "admin_dashboard_plans3_title": "Premiumplan",
    "admin_dashboard_plans3_subtitle": "30 Tage lang vorgestellt",
    "admin_dashboard_plans3_text": "Ihre Anzeige kann 1 Monat lang angezeigt werden.",
    "admin_dashboard_plans3_label": "Plan auswählen",
    "admin_dashboard_plans_title": "Ihre Credits",
    "admin_dashboard_plans_subtitle": "Ihre Credits sind abgelaufen. Sie müssen mehr Credits kaufen, um auf der Plattform vorgestellt zu werden.",
    "admin_dashboard_plans_choose": "Plan auswählen",
    "admin_dashboard_plans_buymore": "Mehr Credits kaufen",
    "admin_dashboard_stats_12": "12 Monate",
    "admin_dashboard_stats_6": "6 Monate",
    "admin_dashboard_stats_30": "30 Tage",
    "admin_dashboard_stats_7": "7 Tage",
    "admin_dashboard_opt1_title": "Zu Favoriten hinzugefügt",
    "admin_dashboard_opt1_text": "Ergänzungen",
    "admin_dashboard_opt2_title": "Klicks auf die WhatsApp-Schaltfläche",
    "admin_dashboard_opt2_text": "Klicks",
    "admin_dashboard_sup_subject": "Betreff",
    "admin_dashboard_sup_message": "Nachricht",
    "admin_dashboard_sup_fill": "Alle Felder ausfüllen",
    "admin_dashboard_sup_success": "Support erfolgreich gesendet",
    "admin_dashboard_sup_title": "Bei Fragen steht Ihnen unser Support-Team gerne zur Verfügung.",
    "admin_dashboard_sup_action": "Nachricht senden",
    "admin_dashboard_supfooter_email": "E-Mail",
    "admin_dashboard_supfooter_phone": "Telefon",
    "admin_dashboard_supfooter_item1_title": "Häufig gestellte Fragen",
    "admin_dashboard_supfooter_item1_subtitle": "Wir haben einige der hier gestellten Fragen getrennt aufgeführt.",
    "admin_dashboard_supfooter_item1_label": "Zugriff auf FAQ",
    "admin_dashboard_supfooter_item2_title": "Datenschutzrichtlinie",
    "admin_dashboard_supfooter_item2_subtitle": "Erfahren Sie mehr über Datenschutzrichtlinien.",
    "admin_dashboard_supfooter_item2_label": "Zugriff",
    "admin_dashboard_supfooter_item3_title": "Servicebedingungen",
    "admin_dashboard_supfooter_item3_subtitle": "Erfahren Sie mehr über die Servicebedingungen.",
    "admin_dashboard_supfooter_item3_label": "Zugriff",
    "admin_dashboard_delete_text": "Wenn Sie sich entschieden haben, unsere Plattform nicht weiter zu nutzen, bieten wir Ihnen die Möglichkeit, Ihr Konto dauerhaft zu löschen. Dieser Vorgang ist unumkehrbar und führt zur vollständigen Entfernung aller mit Ihrem Konto verknüpften Informationen, einschließlich Ihres Profils, Ihres Aktivitätsverlaufs und Ihrer persönlichen Daten. Bitte beachten Sie, dass Sie mit der Löschung Ihres Kontos den Zugriff auf alle von der Plattform angebotenen Funktionen und Dienste verlieren. Darüber hinaus werden alle zuvor abgeschlossenen Transaktionen, Nachrichten oder Interaktionen dauerhaft gelöscht und können nicht wiederhergestellt werden. Wenn Sie sicher sind, dass Sie mit der Löschung Ihres Kontos fortfahren möchten, bestätigen Sie Ihre Entscheidung bitte unten. Nach der Bestätigung wird Ihr Konto dauerhaft gelöscht und Sie werden automatischautomatisch von der Plattform abgemeldet",
    "admin_dashboard_delete_title": "Was müssen Sie wissen, bevor Sie Ihr Konto löschen?",
    "admin_dashboard_delete_action": "Mein Konto löschen",
    "what_are_you_looking_for": "Wonach suchen Sie?",
    "admin_dashboardowner_item1_title": "Neue Benutzer",
    "admin_dashboardowner_item1_subtitle": "Letzte 30 Tage",
    "admin_dashboardowner_item1_legend": "Neue Benutzer",
    "admin_dashboardowner_item2_title": "Neue Escort",
    "admin_dashboardowner_item2_subtitle": "Letzte 30 Tage",
    "admin_dashboardowner_item2_legend": "Neue Models",
    "admin_dashboardowner_item3_title": "Besuche auf der Website",
    "admin_dashboardowner_item3_subtitle": "Letzte 30 Tage",
    "admin_dashboardowner_item4_title": "Zahlungsmethoden",
    "admin_dashboardowner_item4_subtitle": "Von Ihren Kunden verwendete Methode",
    "admin_dashboardowner_item4_value": "Streifen",
    "admin_dashboardowner_escortcontrol_title": "Zu genehmigende Escorts",
    "admin_dashboardowner_escortcontrol_subtitle": "Stellen Sie sicher, dass das Modell alle Plattformrichtlinien erfüllt.",
    "admin_dashboardowner_escortcontrol_approve": "Zu genehmigende Escorts",
    "admin_dashboardowner_escortcontrol_seeall": "Alle Anfragen anzeigen",
    "admin_dashboardowner_escortcontrol_reject": "Ablehnen",
    "admin_dashboardowner_escortcontrol_seemore": "Mehr anzeigen",
    "admin_dashboardowner_top_title": "Top Escorts",
    "admin_dashboardowner_top_subtitle": "Letzte 30 Tage",
    "admin_dashboardowner_top_complete": "Komplette Rangliste",
    "admin_dashboardowner_older": "Älter",
    "admin_dashboardowner_newest": "Neueste",
    "admin_dashboardowner_user": "Aktive Benutzer",
    "admin_dashboardowner_deleted": "Ihre Konten wurden gelöscht",
    "admin_dashboardowner_platformuser": "Plattformbenutzer",
    "admin_dashboardowner_usersince": "Benutzer seit",
    "admin_dashboardowner_lastaccess": "Letzter Zugriff",
    "admin_dashboardowner_selectperiod": "Zeitraum auswählen",
    "admin_dashboardowner_sortby": "Sortieren nach",
    "admin_dashboardowner_newuser": "Neue Benutzer",
    "admin_dashboardowner_newescorts": "neue Escorts",
    "admin_dashboardowner_newmodels": "neue Models",
    "admin_dashboardowner_modelstitle": "Models",
    "admin_dashboardowner_userstitle": "Benutzer",
    "admin_dashboardowner_modelranking": "Model-Ranking",
    "admin_dashboardowner_busiestaccess": "Zugriffszeiten mit den meisten Zugriffen auf der Plattform",
    "admin_dashboardowner_hits": "Zugriffe",
    "admin_dashboardowner_accessesperiod": "Zugriffe nach Zeitraum",
    "admin_dashboardowner_models": "Models",
    "admin_dashboardowner_activeuser": "aktive Benutzer",
    "admin_dashboardowner_deletedaccount": "Ihre Konten wurden gelöscht",
    "admin_dashboardowner_user_name": "Name",
    "admin_dashboardowner_user_email": "E-Mail",
    "admin_dashboardowner_user_phone": "Telefon",
    "admin_dashboardowner_user_whatsapp": "Whatsapp",
    "admin_dashboardowner_user_telegram": "Telegram",
    "admin_dashboardowner_user_usersince": "Benutzer seit",
    "admin_dashboardowner_user_status": "Status",
    "admin_dashboardowner_user_active": "Aktiv",
    "admin_dashboardowner_user_inactive": "Inaktiv",
    "admin_dashboardowner_user_lastaccess": "Letzter Zugriff",
    "admin_dashboardowner_user_modify": "Informationen ändern",
    "admin_dashboardowner_user_save": "Speichern",
    "admin_dashboardowner_user_redefine": "Passwort neu festlegen",
    "admin_dashboardowner_user_suspend": "Konto sperren",
    "admin_dashboardowner_user_sendemail": "E-Mail senden",
    "admin_dashboardowner_user_success": "Erfolgreich aktualisiert",
    "admin_dashboardowner_user_successsuspend": "Erfolgreich gesperrt",
    "admin_dashboardowner_user_successreset": "E-Mail zum Zurücksetzen des Passworts gesendet",
    "admin_dashboardowner_user_back": "Zurück",
    "admin_dashboardowner_user_actions": "Admin-Aktionen",
    "admin_dashboardowner_escorts_it1_title": "Aktive Models",
    "admin_dashboardowner_escorts_it2_title": "Ihre Konten wurden gelöscht",
    "admin_dashboardowner_escorts_it3_title": "Escorts gesperrt",
    "admin_dashboardowner_escorts_col1_title": "Plattform-Benutzer",
    "admin_dashboardowner_escorts_col2_title": "Benutzer seit",
    "admin_dashboardowner_escorts_col3_title": "Letzter Zugriff",
    "admin_dashboardowner_escorts_col4_title": "Datum der Kontolöschung",
    "admin_dashboardowner_escorts_col5_title": "Datum der Sperrung",
    "admin_dashboardowner_loadmore": "Mehr laden",
    "availability_hours": "Verfügbarkeitsstunden",
    "price": "Preis",
    "where_do_you_serve": "Wo dienen Sie",
    "availability": "Verfügbarkeit",
    "duration": "Dauer",
    "value": "Wert",
    "location": "Standort",
    "about_me": "Über mich",
    "service": "Dienstleistung",
    "hygiene": "Hygiene",
    "socialtime": "Gesellige Zeit",
    "nodata": "Datum nicht verfügbar",
    "nocomment": "Keine Kommentare verfügbar.",
    "annon": "Anonym",
    "noreview": "Keine Bewertungen verfügbar.",
    "addverification": "Verifizierungsabzeichen hinzufügen",
    "removeerification": "Verifizierungsabzeichen entfernen",
    "admin_dashboardowner_morning": "Morgen",
    "admin_dashboardowner_afternoon": "Nachmittag",
    "admin_dashboardowner_night": "Nacht",
    "admin_dashboardowner_early_morning": "Frühmorgens",
    "admin_dashboardowner_activeescorts": "Aktive Escorts",
    "admin_dashboardowner_activeusers": "Aktive Benutzer",
    "admin_dashboardowner_bannedescorts": "Gesperrte Escorts",
    "admin_dashboardowner_bannedusers": "Gesperrte Benutzer",
    "admin_dashboardowner_visitprofile": "Profil besuchen",
    "city_state": "Stadt Staat",
    "posts": "Beiträge",
    "videos": "Videos",
    "likes": "Likes",
    "comments": "Kommentare",
}