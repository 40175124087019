export const static_translation = {
    "dexsire_is_respect": "دكسير هو الاحترام,",        
    "safety_and_dignity": "السلامة والكرامة",        
    "register_right_now": "سجل الآن.",        
    "register": "تسجيل",        
    "our_escorts": "مرافقتنا",        
    "discover_some_models_available_to_you": "اكتشف بعض النماذج المتاحة لك",        
    "load_more": "تحميل المزيد",        
    "connecting_you_to_the_highest_level_of_experience": "ربطك بأعلى مستوى من التجربة.",        
    "find_escorts_by_region": "ابحث عن المرافقات حسب المنطقة",        
    "contact_us": "اتصل بنا",        
    "frequently_asked_questions": "الأسئلة المتكررة",        
    "copyright_2024_dexsire": "© حقوق الطبع والنشر 2024 دكسير، سويسرا. جميع الحقوق محفوظة.",        
    "privacy_policy": "سياسة الخصوصية",        
    "terms_of_service": "شروط الخدمة",        
    "and": "و",        
    "service_terms": "شروط الخدمة",    
    "canton": "كانتون",
    "ville": "مدينة",
    "services_offered": "الخدمات المقدمة",
    "category": "الفئة",
    "clear_filter": "مسح الفلتر",
    "find_escorts": "ابحث عن المرافقات",
    "find_the_ideal_model": "ابحث عن النموذج المثالي لك",
    "login_register": "تسجيل الدخول / التسجيل",    
    "announcement": "إعلان",    
    "my_profile": "ملفي الشخصي",    
    "exit": "خروج",    
    "home": "الصفحة الرئيسية",    
    "advanced_search": "بحث متقدم",    
    "advertise_here": "إعلان هنا",    
    "contact": "اتصال",    
    "im_a_escorts": "أنا مرافقة",
    "im_a_customer": "أنا عميل",
    "i_already_have_registration": "لدي تسجيل مسبق",
    "i_want_to_sign_up": "أريد التسجيل",
    "login": "تسجيل الدخول",
    "enter_your_username_and_password": "أدخل اسم المستخدم وكلمة المرور.",
    "i_forgot_my_password": "نسيت كلمة المرور",
    "to_enter": "للدخول",
    "email": "البريد الإلكتروني",
    "password": "كلمة المرور",
    "fill_email_field": "املأ حقل البريد الإلكتروني",
    "fill_name_field": "املأ حقل الاسم",
    "fill_password_field": "املأ حقل كلمة المرور",
    "fill_360_video": "املأ فيديو 360",
    "minimum_4_photos": "حد أدنى 4 صور",
    "fill_pseudo_field": "املأ حقل الاسم المستعار",
    "fill_category_field": "املأ حقل الفئة",
    "fill_bio_field": "املأ حقل السيرة الذاتية",
    "fill_age_field": "املأ حقل العمر",
    "your_age_must_be_over_18": "يجب أن يكون عمرك أكثر من 18 سنة",
    "fill_phone_field": "املأ حقل الهاتف",
    "fill_canton_field": "املأ حقل الكانتون",
    "fill_ville_field": "املأ حقل المدينة",
    "fill_nationality_field": "املأ حقل الجنسية",
    "fill_size_field": "املأ حقل المقاس",
    "fill_weight_field": "املأ حقل الوزن",
    "fill_hair_color_field": "املأ حقل لون الشعر",
    "fill_breasts_field": "املأ حقل الثدي",
    "fill_eyes_color_field": "املأ حقل لون العين",
    "select_at_least_one_mobility_option": "اختر خيار واحد على الأقل للتنقل",
    "select_at_least_one_payment_option": "اختر خيار دفع واحد على الأقل",
    "select_at_least_one_language_option": "اختر خيار لغة واحد على الأقل",
    "fill_in_your_details": "املأ تفاصيلك",
    "user_registration": "تسجيل المستخدم",
    "plan": "الخطة",
    "select_a_plan": "اختر خطة",
    "personal_data": "البيانات الشخصية",
    "profile": "الملف الشخصي",
    "appearance": "المظهر",
    "payment": "الدفع",
    "model_registration": "تسجيل النموذج",
    "buy_highlights": "شراء تميز",
    "fill_in_your_profile": "املأ ملفك الشخصي",
    "name": "الاسم", 
    "confirm_password": "تأكيد كلمة المرور",
    "fill_all_fields": "املأ جميع الحقول",
    "invalid_email": "بريد إلكتروني غير صالح",
    "password_and_confirmation_not_match": "كلمة المرور وتأكيدها غير متطابقين",
    "account_successfully_registred": "تم تسجيل الحساب بنجاح!",
    "registration_completed_successfully": "تم إتمام التسجيل بنجاح",
    "you_must_verify": "يجب عليك التحقق من بريدك الإلكتروني لتفعيل حسابك. إذا لم تجد الرسالة في صندوق الوارد، تحقق من مجلد البريد المزعج.",
    "take_the_opportunity": "استغل الفرصة لتمييز ملفك الشخصي، استثمر في الاعتمادات للبقاء في القمة.",
    "want_to_buy_later": "أريد الشراء لاحقًا",
    "i_want_to_buy_credits_now": "أريد شراء الاعتمادات الآن",
    "advance": "تقدم",
    "now_its_time_to_report_your_appearance": "الآن حان الوقت للإبلاغ عن مظهرك",
    "verification_photo": "صورة التحقق",
    "exemple": "مثال",
    "send_a_photo_holding": "أرسل صورة تحمل ورقة مكتوب عليها dexsire.com",
    "drag_the_image_here_or_click_here": "اسحب الصورة هنا أو اضغط هنا",
    "i_agree_with_the_platform": "أوافق على شروط المنصة",
    "accept_terms_to_continue": "قبول الشروط للاستمرار",
    "pseudo": "الاسم المستعار",
    "bio": "السيرة الذاتية",
    "age": "العمر",
    "phone": "الهاتف",
    "nationality": "الجنسية",
    "size": "المقاس",
    "weight": "الوزن",
    "height": "الطول",
    "language": "اللغة",
    "hair_color": "لون الشعر",
    "breasts": "الثدي",
    "eye_color": "لون العين",
    "mobility": "التنقل",
    "payments_accepted": "المدفوعات المقبولة",
    "languages_spoken": "اللغات المتحدث بها",
    "what_services_do_you_offer": "ما الخدمات التي تقدمها؟",
    "describe_yourself_below": "صف نفسك أدناه:",
    "note": "ملاحظة:",
    "the_file_is_too_long": "الملف كبير جدًا. الحجم الأقصى هو 50 ميجابايت.",
    "upload_a_360": "رفع فيديو 360º",
    "instruction_on_how": "تعليمات لتسجيل فيديو 360º",
    "drag_video": "اسحب الفيديو هنا أو اضغط هنا",
    "supported_video": "الملفات المدعومة: MP4 أو AVI",
    "maximum_video": "حجم الملف الأقصى: 50 ميجابايت",
    "upload": "تحميل",
    "drag_image": "اسحب الصورة هنا أو اضغط هنا",
    "supported_image": "الملفات المدعومة: JPG و PNG",
    "maximum_image": "حجم الملف الأقصى: 8 ميجابايت. الحد الأدنى 4 صور.",
    "the_image_is_too_big": "الملف كبير جدًا. الحجم الأقصى هو 8 ميجابايت.",
    "in_addition": "بالإضافة إلى التميز الدوري المضمن كجزء من وظائف لوحة التحكم، سيكون لديك أيضًا خيار شراء تميز إضافي. سيتيح لك ذلك إبراز إعلاناتك بشكل متكرر أو لفترات زمنية أطول، مما يزيد من تعرضك على المنصة.",
    "license": "الرخصة",
    "license_5days": "5 أيام - رخصة",
    "license_5days_price": "89.90 فرنك سويسري",
    "payment_via": "سيتم الدفع لاشتراكك عبر",
    "continue": "استمر",
    "choose_plan": "اختر خطة",
    "announcement_title_1": "واجهة نظيفة وسهلة الاستخدام",
    "announcement_desc_1": "نقدم لك واجهة نظيفة وسهلة الاستخدام حتى تتمكن النماذج من إدارة إعلاناتها بكفاءة ودون تعقيدات. تم تصميم منصتنا لتكون سهلة الاستخدام، مما يوفر لك تجربة إدارة إعلانات خالية من المتاعب.",
    "announcement_title_2": "خيارات إدارة الإعلانات",
    "announcement_desc_2": "تحكم كامل في إعلاناتك مع خيارات إنشاء وتعديل وحذف. يمكنك إنشاء إعلانات جديدة، تعديل المعلومات الموجودة، وحذف الإعلانات حسب الحاجة، كل ذلك في مكان واحد.",
    "announcement_title_3": "دعم الوسائط المتعددة",
    "announcement_desc_3": "نحن ندعم الصور والفيديوهات عالية الجودة لتقديم أفضل عرض لخدماتك. يمكنك تحميل صور عالية الجودة وفيديوهات 360º لزيادة تفاعل العملاء.",
    "announcement_title_4": "خيارات الدفع المرنة",
    "announcement_desc_4": "نقدم خيارات دفع مرنة وآمنة، بما في ذلك إمكانية شراء الاعتمادات لزيادة ظهورك على منصتنا. اختر خطة الدفع الأنسب لك وابدأ التميز.",
    "announcement_title_5": "الدعم الفني 24/7",
    "announcement_desc_5": "فريق الدعم الفني لدينا متاح على مدار الساعة لتلبية احتياجاتك وحل أي مشاكل قد تواجهك. نحن هنا لضمان أفضل تجربة لك على المنصة.",
    "announcement_title_ads": "اعلن هنا",
    "announcement_desc_ads_1": "انضم إلينا واستمتع بمنصة مخصصة لتعزيز أمانك وظهورك ونجاحك.",
    "announcement_desc_ads_2": "عند التسجيل في LUXEO، سيكون لديك وصول إلى سلسلة من الفوائد الحصرية، بما في ذلك:",
    "announcement_desc_ads_3": "لا تضيع الوقت! سجل الآن وابدأ هذه الرحلة الناجحة معنا في LUXEO.",
    "announcement_action_ads": "سجل الآن",
    "announcement_section_title1": "ملف شخصي مخصص",
    "announcement_section_desc1": "أنشئ ملفًا شخصيًا فريدًا وجذابًا يبرز مواهبك وخدماتك وشخصيتك.",
    "announcement_section_title2": "توسيع ظهورك",
    "announcement_section_desc2": "وصل إلى جمهور أوسع وزيِّن فرصك التجارية من خلال أن تكون مميزًا في قسم 'الفتيات المميزات'.",
    "announcement_section_title3": "سهولة الاستخدام",
    "announcement_section_desc3": "منصتنا التفاعلية وسهلة الاستخدام تجعل من إدارة ملفك الشخصي والتفاعل مع العملاء المحتملين أمرًا بسيطًا.",
    "announcement_section_title4": "مراجعات إيجابية",
    "announcement_section_desc4": "احصل على تعليقات قيمة من العملاء الراضين وزيِّن سمعتك في المجتمع.",
    "announcement_section_title5": "عروض خاصة",
    "announcement_section_desc5": "احصل على وصول حصري إلى العروض الخاصة والفعاليات الترويجية لتعزيز عملك.",
    "announcement_form_title": "تسجيل الدخول",
    "announcement_form_subtitle": "أنا بالفعل معلن",
    "announcement_form_action1": "سجل",
    "announcement_form_action_title": "ليس لدي حساب على المنصة",
    "announcement_form_action2": "إنشاء حساب",
    "announcement_form_email": "البريد الإلكتروني",
    "announcement_form_password": "كلمة المرور",
    "announcement_form_forgot": "نسيت كلمة المرور",
    "announcement_form_submit": "تسجيل الدخول",
    "forgot_title": "نسيت كلمة المرور",
    "forgot_subtitle": "أدخل بريدك الإلكتروني المسجل",
    "forgot_input_email": "البريد الإلكتروني",
    "forgot_action": "إرسال رابط لتجديد كلمة المرور",
    "forgot_success_title": "تم إرسال البريد الإلكتروني بنجاح.",
    "forgot_success_text": "لقد أرسلنا إليك بريدًا إلكترونيًا يحتوي على رابط لتجديد كلمة مرورك. إذا لم تجده، تحقق من مجلد البريد المزعج.",
    "create_password_validate1": "املأ حقل كلمة المرور الجديدة",
    "create_password_validate2": "املأ حقل تأكيد كلمة المرور الجديدة",
    "create_password_validate3": "كلمة المرور وتأكيد كلمة المرور غير متطابقين",
    "create_password_success": "تم إنشاء كلمة المرور بنجاح",
    "create_password_title": "نسيت كلمة المرور",
    "create_password_text": "إنشاء كلمة مرور جديدة",
    "create_password_input_1": "كلمة المرور الجديدة",
    "create_password_input_2": "تأكيد كلمة المرور الجديدة",
    "create_password_input_submit": "حفظ",
    "purchase_title": "تمييز ملفك الشخصي",
    "purchase_subtitle": "شراء الاعتمادات",
    "purchase_text": "حدد عدد الاعتمادات التي ترغب في شرائها",
    "purchase_action": "أريد الشراء لاحقًا",
    "purchase_selected": "تم تحديده",
    "purchase_select": "حدد",
    "customer_profile_name": "الاسم",
    "customer_profile_user": "مستخدم منذ",
    "customer_profile_status": "الحالة",
    "customer_profile_email": "البريد الإلكتروني",
    "customer_profile_subs_type": "نوع الاشتراك",
    "customer_profile_subs_value": "قيمة الاشتراك",
    "customer_profile_saved": "تم الحفظ",
    "customer_profile_save": "حفظ",
    "customer_profile_edit": "تعديل المعلومات",
    "customer_profile_delete": "حذف الحساب",
    "customer_profile_fav": "المرافقات التي أضفتها إلى المفضلة",
    "gallery_video": "معرض الفيديو",
    "gallery_image": "معرض الصور",
    "gallery_load_more": "تحميل المزيد",
    "aboutme_contact": "اتصل بي",
    "aboutme_contact_text": "لا أجيب على المكالمات الصوتية",
    "aboutme_contact_some_photos": "شاهد بعض الصور والفيديوهات",
    "aboutme_contact_photos": "الصور",
    "aboutme_contact_videos": "الفيديوهات",
    "review_service": "الخدمة",
    "review_hygiene": "النظافة",
    "review_socialtime": "الوقت الاجتماعي",
    "review_apparence": "المظهر",
    "review_location": "الموقع",
    "review_comment": "التعليق",
    "review_fill_all_fields": "املأ جميع الحقول",
    "review_what_customer_saying": "ماذا يقول بعض العملاء:",
    "review_left_comment": "أضف تعليقك",
    "review_add_your_review": "أضف مراجعتك",
    "review_sent_review": "تم إرسال المراجعة",
    "escortservice_success": "تم الحفظ بنجاح",
    "escortservice_title": "اتصل بي",
    "escortservice_text": "لا أجيب على المكالمات الصوتية",
    "contact_title": "اتصل",
    "contact_email": "البريد الإلكتروني",
    "contact_whats_desc": "تحدث معنا عبر WhatsApp.",
    "contact_whats_label": "انقر هنا",
    "contact_privacy_title": "سياسة الخصوصية",
    "contact_privacy_desc": "احصل على تعليقات قيمة من العملاء الراضين وزيِّن سمعتك في المجتمع.",
    "contact_privacy_label": "الوصول إلى الأسئلة الشائعة",
    "contactform_name": "اسمك",
    "contactform_email": "أفضل بريد إلكتروني لديك",
    "contactform_subject": "الموضوع",
    "contactform_message": "الرسالة",
    "contactform_fill_fields": "املأ جميع الحقول",
    "contactform_invalidmail": "بريد إلكتروني غير صالح",
    "contactform_success": "تم إرسال الرسالة بنجاح",
    "contactform_title": "هل تحتاج إلى مساعدة؟ سيكون من دواعي سرورنا التحدث معك.",
    "contactform_action": "إرسال الرسالة",
    "faq_title": "الأسئلة المتكررة",
    "faq_subtitle": "الأسئلة المتكررة من المستخدمين حول استخدام التطبيق وميزاته وسياساته.",
    "faq_text": "لم تجد ما كنت تبحث عنه؟",
    "faq_subtext": "اتصل بنا.",
    "faq_action": "أرسل رسالة",
    "notfound_title": "خطأ 404",
    "notfound_text": "الصفحة التي حاولت الوصول إليها غير موجودة أو أنها غير متاحة مؤقتًا.",
    "notfound_action": "العودة إلى الصفحة الرئيسية",
    "advancedsearch_canton": "الكانتون",
    "advancedsearch_age": "العمر",
    "advancedsearch_services": "الخدمات المقدمة",
    "advancedsearch_category": "الفئة",
    "advancedsearch_customerreviews": "مراجعات العملاء",
    "advancedsearch_nationality": "الجنسية / الأصل العرقي",
    "advancedsearch_lang": "اللغات المحكية",
    "advancedsearch_appearance": "المظهر الجسدي",
    "advancedsearch_find": "ابحث عن المرافقات",
    "advancedsearch_title": "بحث متقدم",
    "terms_title1": "1. مقدمة",
    "terms_content1": "صفحة شروط الخدمة هي جزء أساسي من منصتنا، حيث تحدد الشروط والأحكام التي تحكم استخدام المستخدمين لخدماتنا. هذه الصفحة تضع الحقوق والواجبات للمستخدمين والشركة، مما يضمن تجربة آمنة وشفافة للجميع.",
    "terms_title2": "2. قبول الشروط",
    "terms_content2": "نوضح أن استخدام منصتنا يعني القبول التلقائي والملزم بشروط الخدمة.",
    "terms_title3": "3. وصف الخدمات",
    "terms_content3": "نوضح الخدمات التي تقدمها منصتنا، بما في ذلك وظائفها وخصائصها وقيودها. هذه الشرح يساعد المستخدمين على فهم ما يمكنهم توقعه عند استخدام خدماتنا.",
    "terms_title4": "4. مسؤوليات المستخدم",
    "terms_content4": "نوضح مسؤوليات المستخدمين عند استخدام منصتنا، بما في ذلك: تقديم معلومات دقيقة وحديثة أثناء التسجيل، احترام حقوق الملكية الفكرية، الحفاظ على أمان بيانات الوصول، وعدم انتهاك القوانين السارية أو حقوق الأطراف الثالثة.",
    "terms_title5": "5. الملكية الفكرية",
    "terms_content5": "نخبر المستخدمين عن ملكية حقوق الطبع والنشر والعلامات التجارية وحقوق الملكية الفكرية الأخرى المتعلقة بمنصتنا ومحتوياتها. يوافق المستخدمون على احترام هذه الحقوق وعدم استخدام محتوياتنا بطريقة غير مصرح بها.",
    "terms_title6": "6. سياسة الخصوصية",
    "terms_content6": "نذكر سياسة الخصوصية الخاصة بنا ونوضح كيف نتعامل مع معلومات المستخدمين. يُشجَّع المستخدمون على مراجعة سياسة الخصوصية لفهم كيفية جمع واستخدام وحماية معلوماتهم.",
    "terms_title7": "7. تعديلات على شروط الخدمة",
    "terms_content7": "نحتفظ بحق تعديل أو تحديث شروط الخدمة حسب الحاجة. تصبح التغييرات سارية عند نشر شروط الخدمة المعدلة على منصتنا.",
    "terms_title8": "8. تحديد المسؤولية",
    "terms_content8": "نُعفى الشركة من المسؤولية عن الأضرار المباشرة أو غير المباشرة أو العرضية أو التبعية أو العقابية الناتجة عن استخدام خدماتنا. يوافق المستخدمون على استخدام خدماتنا على مسؤوليتهم الخاصة.",
    "terms_title9": "9. إنهاء الخدمة",
    "terms_content9": "نحتفظ بحق إنهاء أو تعليق وصول المستخدمين إلى منصتنا، دون إشعار مسبق، في حالة انتهاك شروط الخدمة.",
    "terms_title10": "10. الأحكام العامة",
    "terms_content10": "نضمن أحكامًا تتعلق بالقانون الساري، والاختصاص القضائي، والتنازل عن الحقوق، وصحة العقد، وغيرها من الأحكام العامة لضمان تنفيذ فعالية شروط الخدمة.",
    "terms_title11": "11. الاتصال",
    "terms_content11": "نوفر معلومات الاتصال لتمكين المستخدمين من الاتصال بنا لأي أسئلة أو استفسارات أو مشكلات متعلقة بشروط الخدمة.",
    "terms_title": "شروط الخدمة",
    "terms_subtitle": "يرجى قراءة شروط الخدمة بعناية قبل المتابعة.",
    "purchasedetails_title": "تفاصيل الشراء",
    "purchasedetails_text1": "سيتم عرض إعلانك",
    "purchasedetails_text2": "بعد هذه الفترة، يجب عليك إعادة تفعيل اشتراكك.",
    "purchasedetails_text3": "يمكنك إيقاف إعلانك في أي وقت قبل انتهاء المهلة. وبذلك سيتم أيضًا إيقاف الاعتمادات لاستخدامها في أي وقت.",
    "purchasedetails_text4": "قيمة الخطة",
    "purchasedetails_text_francs": "فرنك",
    "purchasedetails_text_confirm": "تأكيد الدفع",
    "purchasedetails_text_congratulations1": "مبروك. تم تفعيل خطتك المميزة بنجاح!",
    "profilemodal_option1": "ملف العميل",
    "profilemodal_option2": "الصفحة الداخلية",
    "profilemodal_option3": "مالك الإدارة",
    "profilemodal_option4": "مرافق إداري",
    "profilemodal_title": "اختر نوع الملف الشخصي",
    "privacy_title1": "1. مقدمة",
    "privacy_conte1": "تهدف صفحة الخصوصية لدينا إلى إبلاغ مستخدمينا بكيفية جمع واستخدام وحماية معلوماتهم الشخصية أثناء استخدامهم لمنصتنا. تعتبر خصوصية مستخدمينا أولوية بالنسبة لنا، وتوضح هذه الصفحة ممارساتنا في الخصوصية بما يتوافق مع القوانين واللوائح المعمول بها.",
    "privacy_title2": "2. المعلومات التي يتم جمعها",
    "privacy_conte2": "في هذا القسم، نصف أنواع المعلومات الشخصية التي نقوم بجمعها من المستخدمين وطرق جمع هذه المعلومات. يشمل ذلك على سبيل المثال لا الحصر: المعلومات التي يقدمها المستخدمون أثناء التسجيل مثل الاسم والبريد الإلكتروني وتاريخ الميلاد، إلخ. بيانات الاستخدام مثل تاريخ التصفح، تفاعلات المنصة، تفضيلات المستخدم، إلخ. المعلومات التي يتم جمعها تلقائيًا من خلال ملفات تعريف الارتباط والتقنيات المماثلة.",
    "privacy_title3": "3. استخدام المعلومات",
    "privacy_conte3": "نوضح كيف يتم استخدام معلومات المستخدمين الشخصية بواسطة منصتنا. قد يشمل ذلك: تخصيص تجربة المستخدم وتقديم المحتوى المناسب، التحسين المستمر لخدماتنا وميزاتها، التواصل مع المستخدمين حول التحديثات والعروض الخاصة، الامتثال للالتزامات القانونية والتنظيمية.",
    "privacy_title4": "4. مشاركة المعلومات",
    "privacy_conte4": "نوضح من نشارك معه معلومات المستخدمين والأسباب وراء ذلك. قد يشمل ذلك: شركاء الخدمة الذين يساعدوننا في تقديم خدماتنا، السلطات القانونية، عندما يكون ذلك مطلوبًا بموجب القانون أو استجابة لطلبات رسمية، الأطراف الثالثة في حالة اندماج أو استحواذ أو بيع الأصول.",
    "privacy_title5": "5. حماية المعلومات",
    "privacy_conte5": "نوضح التدابير الأمنية التي نطبقها لحماية معلومات المستخدمين من الوصول غير المصرح به أو سوء الاستخدام أو التغيير أو الإفشاء. يشمل ذلك على سبيل المثال لا الحصر: تشفير البيانات، ضوابط الوصول، المراقبة المنتظمة للأنظمة والشبكات.",
    "privacy_title6": "6. حقوق المستخدم",
    "privacy_conte6": "نبلغ المستخدمين بحقوقهم المتعلقة بمعلوماتهم الشخصية، بما في ذلك الحق في الوصول إليها، تصحيحها، حذفها أو تقييد استخدامها. كما نوفر معلومات حول كيفية ممارسة هذه الحقوق.",
    "privacy_title7": "7. تغييرات في سياسة الخصوصية",
    "privacy_conte7": "نحتفظ بحق تعديل سياسة الخصوصية لدينا حسب الحاجة. سيتم إعلام المستخدمين بأي تغييرات جوهرية من خلال منصتنا أو بطرق أخرى مناسبة.",
    "privacy_title8": "8. الاتصال",
    "privacy_conte8": "نقدم معلومات الاتصال لكي يتمكن المستخدمون من التواصل معنا إذا كانت لديهم أي أسئلة أو مخاوف أو طلبات تتعلق بالخصوصية.",
    "privacy_title9": "9. تاريخ النفاذ",
    "privacy_conte9": "نحدد تاريخ نفاذ سياسة الخصوصية الخاصة بنا وننبه المستخدمين إلى أهمية مراجعة هذه الصفحة بانتظام للبقاء على اطلاع بأحدث ممارساتنا في الخصوصية.",
    "privacy_title10": "10. الامتثال القانوني",
    "privacy_conte10": "نعلن التزامنا بالامتثال لقوانين ولوائح حماية البيانات السارية ونوضح للمستخدمين حقوقهم ووسائل العلاج في حال حدوث انتهاكات للخصوصية.",
    "privacy_title": "سياسة الخصوصية",
    "privacy_subtitle": "يرجى قراءة شروط الاستخدام الخاصة بالمنصة بعناية قبل المتابعة.",
    "deleteaccount_success": "تم حذف الحساب بنجاح",
    "deleteaccount_title": "أنت على وشك حذف حسابك بشكل نهائي.",
    "deleteaccount_text": "إذا قررت عدم حذف حسابك في الوقت الحالي، يمكنك ببساطة إغلاق هذه الصفحة ومتابعة استخدام منصتنا كالمعتاد. إذا كان لديك أي أسئلة أو تحتاج إلى المساعدة، لا تتردد في الاتصال بفريق الدعم لدينا. نحن هنا لمساعدتك!",
    "deleteaccount_confirm": "تأكيد حذف الحساب",
    "deleteaccount_cancel": "لا أرغب في الحذف",
    "deleteaccount_success_title": "تم حذف حسابك بنجاح.",
    "deleteaccount_success_text": "كان من دواعي سرورنا أن تكون معنا في هذه الفترة. تذكر أنك مرحب بك في أي وقت إذا كنت بحاجة إلينا. سنكون هنا لمساعدتك.",
    "ageverification_title": "التحقق من العمر",
    "ageverification_text1": "تحتوي هذه الموقع على محتوى مخصص للأشخاص الذين تزيد أعمارهم عن 18 عامًا. من خلال النقر على 'تأكيد'، فإنك تصادق على أنك بلغت السن القانوني وفقًا لقوانين منطقتك. إذا لم تكن من العمر المطلوب، يرجى مغادرة هذا الموقع فورًا.",
    "ageverification_text2": "تأكيد العمر الزائف يعد انتهاكًا لشروط الاستخدام الخاصة بهذا الموقع.",
    "ageverification_text3": "هل أنت فوق 18 عامًا؟",
    "ageverification_text4": "سنوات؟",
    "ageverification_yes": "نعم",
    "ageverification_no": "لا",
    "ageverification_policy": "سياسة الخصوصية",
    "ageverification_terms": "شروط الاستخدام",
    "escortinfo_reviews": "التعليقات التي تركها العملاء",
    "follower": "متابع",
    "unfollow": "إلغاء المتابعة",
    "imagesuccessfully": "تم تحميل الصورة بنجاح",
    "admin_side_option1": "الرئيسية",
    "admin_side_option2": "عنّي",
    "admin_side_option3": "الصور",
    "admin_side_option4": "الفيديوهات",
    "admin_side_option5": "التقييمات",
    "admin_side_option6": "الخدمات",
    "admin_side_option7": "الإعلانات",
    "admin_side_option8": "اعتمادات إضافية",
    "admin_side_option9": "الخطط",
    "admin_side_option10": "الإحصائيات",
    "admin_side_option11": "الدعم",
    "admin_side_option12": "الرئيسية",
    "admin_side_option13": "المستخدمون",
    "admin_side_option14": "مرافقو escorts",
    "admin_side_option15": "التقارير",
    "admin_sidefooter_option1": "حذف الحساب",
    "admin_sidefooter_option2": "خروج",
    "admin_dashboard_title1": "المفضلة",
    "admin_dashboard_subtitle1": "المستخدمون الذين يتابعونك",
    "admin_dashboard_title2": "نقرات الإعلان",
    "admin_dashboard_subtitle2": "آخر 30 يومًا",
    "admin_dashboard_title3": "رصيدك",
    "admin_dashboard_subtitle3": "الرصيد انتهى",
    "admin_dashboard_title4": "حالة الاشتراك",
    "admin_dashboard_subtitle4": "على وشك الانتهاء",
    "admin_dashboard_credit": "رصيد",
    "admin_dashboard_announcement": "إعلان",
    "admin_dashboard_visit": "الزيارات إلى ملفك الشخصي",
    "admin_dashboard_visits": "الزيارات",
    "admin_dashboard_activead": "إعلان نشط",
    "admin_dashboard_aboutme_success": "تم تحديث ملفي بنجاح",
    "admin_dashboard_aboutme_title": "عنّي",
    "admin_dashboard_aboutme_phone": "الهاتف",
    "admin_dashboard_aboutme_whats": "واتساب",
    "admin_dashboard_aboutme_telegram": "تلغرام",
    "admin_dashboard_aboutme_size": "الحجم",
    "admin_dashboard_aboutme_weight": "الوزن",
    "admin_dashboard_aboutme_nationality": "الجنسية",
    "admin_dashboard_aboutme_haircolor": "لون الشعر",
    "admin_dashboard_aboutme_breasts": "الثديين",
    "admin_dashboard_aboutme_eyecolor": "لون العين",
    "admin_dashboard_aboutme_gender": "التفضيل (الجنس)",
    "admin_dashboard_credits_credit": "رصيد",
    "admin_dashboard_credits_franc": "فرنك",
    "admin_dashboard_credits_item1_title": "كيف يعمل الاشتراك؟",
    "admin_dashboard_credits_item1_subtitle": "يمكنك الاشتراك لفترة معينة لتكون مميزًا في المنصة.",
    "admin_dashboard_credits_item1_button": "30 يومًا",
    "admin_dashboard_credits_item1_footer": "بعد هذه الفترة، يجب عليك تجديد اشتراكك لكي تظل إعلاناتك مميزة على المنصة وتزيد فرصك في أن يتم توظيفك من قبل المستخدمين.",
    "admin_dashboard_credits_item2_title": "الاعتمادات والفترات",
    "admin_dashboard_credits_item2_subtitle": "لكل يوم إعلان مميز، ستحتاج إلى 1 رصيد.",
    "admin_dashboard_credits_item2_footer": "لكل يوم إعلان مميز، ستحتاج إلى 1 رصيد.",
    "admin_dashboard_credits_item3_title": "شراء الاعتمادات",
    "admin_dashboard_credits_item3_subtitle": "اختر عدد الاعتمادات التي ترغب في شرائها.",
    "admin_dashboard_credits_title": "رصيدك",
    "admin_dashboard_credits_subtitle": "انتهت صلاحية اعتماداتك. يجب عليك شراء اعتمادات جديدة لتكون مميزًا على المنصة.",
    "admin_dashboard_plans1_title": "الخطة الأساسية",
    "admin_dashboard_plans1_subtitle": "مميز لمدة 5 أيام",
    "admin_dashboard_plans1_text": "يمكنك جعل إعلانك مميزًا لمدة 5 أيام.",
    "admin_dashboard_plans1_label": "اختر الخطة",
    "admin_dashboard_plans2_title": "الخطة البرونزية",
    "admin_dashboard_plans2_subtitle": "مميز لمدة 15 يومًا",
    "admin_dashboard_plans2_text": "يمكنك جعل إعلانك مميزًا لمدة أسبوعين.",
    "admin_dashboard_plans2_label": "اختر الخطة",
    "admin_dashboard_plans3_title": "الخطة المميزة",
    "admin_dashboard_plans3_subtitle": "مميز لمدة 30 يومًا",
    "admin_dashboard_plans3_text": "يمكنك جعل إعلانك مميزًا لمدة شهر واحد.",
    "admin_dashboard_plans3_label": "اختر الخطة",
    "admin_dashboard_plans_title": "اعتماداتك",
    "admin_dashboard_plans_subtitle": "انتهت صلاحية اعتماداتك. يجب عليك شراء اعتمادات جديدة لتكون مميزًا على المنصة.",
    "admin_dashboard_plans_choose": "اختر الخطة",
    "admin_dashboard_plans_buymore": "اشترِ المزيد من الاعتمادات",
    "admin_dashboard_stats_12": "12 شهرًا",
    "admin_dashboard_stats_6": "6 أشهر",
    "admin_dashboard_stats_30": "30 يومًا",
    "admin_dashboard_stats_7": "7 أيام",
    "admin_dashboard_opt1_title": "تمت إضافته إلى المفضلة",
    "admin_dashboard_opt1_text": "الإضافات",
    "admin_dashboard_opt2_title": "النقرات على زر الواتساب",
    "admin_dashboard_opt2_text": "النقرات",
    "admin_dashboard_sup_subject": "الموضوع",
    "admin_dashboard_sup_message": "الرسالة",
    "admin_dashboard_sup_fill": "املأ جميع الحقول",
    "admin_dashboard_sup_success": "تم إرسال الدعم بنجاح",
    "admin_dashboard_sup_title": "إذا كان لديك أي أسئلة، سيكون فريق الدعم لدينا سعيدًا بالإجابة.",
    "admin_dashboard_sup_action": "إرسال رسالة",
    "admin_dashboard_supfooter_email": "البريد الإلكتروني",
    "admin_dashboard_supfooter_phone": "الهاتف",
    "admin_dashboard_supfooter_item1_title": "الأسئلة الشائعة",
    "admin_dashboard_supfooter_item1_subtitle": "لقد جمعنا بعض الأسئلة التي تُطلب هنا.",
    "admin_dashboard_supfooter_item1_label": "الوصول إلى الأسئلة الشائعة",
    "admin_dashboard_supfooter_item2_title": "سياسة الخصوصية",
    "admin_dashboard_supfooter_item2_subtitle": "افهم قليلاً عن سياسات الخصوصية.",
    "admin_dashboard_supfooter_item2_label": "الوصول",
    "admin_dashboard_supfooter_item3_title": "شروط الخدمة",
    "admin_dashboard_supfooter_item3_subtitle": "افهم قليلاً عن شروط الخدمة.",
    "admin_dashboard_supfooter_item3_label": "الوصول",
    "admin_dashboard_delete_text": "إذا قررت عدم الاستمرار في استخدام منصتنا، نقدم لك خيار حذف حسابك بشكل نهائي. هذه العملية لا يمكن التراجع عنها وستؤدي إلى إزالة جميع المعلومات المرتبطة بحسابك بما في ذلك ملفك الشخصي، وسجل النشاط، والبيانات الشخصية. يجب أن تكون على علم أنه عندما تقوم بحذف حسابك، ستفقد الوصول إلى جميع الميزات والخدمات المقدمة من المنصة. بالإضافة إلى ذلك، سيتم حذف أي معاملات أو رسائل أو تفاعلات تم إتمامها سابقًا بشكل دائم ولا يمكن استرجاعها. إذا كنت متأكدًا أنك تريد المضي قدمًا في حذف حسابك، يرجى تأكيد قرارك أدناه. بمجرد التأكيد، سيتم حذف حسابك نهائيًا وستتم تسجيل الخروج تلقائيًا من المنصة.",
    "admin_dashboard_delete_title": "ماذا يجب أن تعرف قبل حذف حسابك؟",
    "admin_dashboard_delete_action": "حذف حسابي",
    "what_are_you_looking_for": "ماذا تبحث عنه؟",
    "admin_dashboardowner_item1_title": "المستخدمون الجدد",
    "admin_dashboardowner_item1_subtitle": "آخر 30 يومًا",
    "admin_dashboardowner_item1_legend": "المستخدمون الجدد",
    "admin_dashboardowner_item2_title": "المرافقون الجدد",
    "admin_dashboardowner_item2_subtitle": "آخر 30 يومًا",
    "admin_dashboardowner_item2_legend": "الموديلات الجدد",
    "admin_dashboardowner_item3_title": "الزيارات إلى الموقع",
    "admin_dashboardowner_item3_subtitle": "آخر 30 يومًا",
    "admin_dashboardowner_item4_title": "طرق الدفع",
    "admin_dashboardowner_item4_subtitle": "الطريقة المستخدمة من قبل عملائك",
    "admin_dashboardowner_item4_value": "سترايب",
    "admin_dashboardowner_escortcontrol_title": "المرافقون للموافقة",
    "admin_dashboardowner_escortcontrol_subtitle": "تأكد من أن الموديل يلتزم بجميع سياسات المنصة.",
    "admin_dashboardowner_escortcontrol_seeall": "عرض جميع الطلبات",
    "admin_dashboardowner_escortcontrol_approve": "للموافقة",
    "admin_dashboardowner_escortcontrol_reject": "رفض",
    "admin_dashboardowner_escortcontrol_seemore": "عرض المزيد",
    "admin_dashboardowner_top_title": "أفضل المرافقين",
    "admin_dashboardowner_top_subtitle": "آخر 30 يومًا",
    "admin_dashboardowner_top_complete": "التصنيف الكامل",
    "admin_dashboardowner_older": "أقدم",
    "admin_dashboardowner_newest": "أحدث",
    "admin_dashboardowner_user": "المستخدمون النشطون",
    "admin_dashboardowner_deleted": "حذف حساباتهم",
    "admin_dashboardowner_platformuser": "مستخدمو المنصة",
    "admin_dashboardowner_usersince": "المستخدم منذ",
    "admin_dashboardowner_lastaccess": "آخر وصول",
    "admin_dashboardowner_selectperiod": "اختر الفترة",
    "admin_dashboardowner_sortby": "ترتيب حسب",
    "admin_dashboardowner_newuser": "المستخدمون الجدد",
    "admin_dashboardowner_newescorts": "المرافقون الجدد",
    "admin_dashboardowner_newmodels": "الموديلات الجدد",
    "admin_dashboardowner_modelstitle": "الموديلات",
    "admin_dashboardowner_userstitle": "المستخدمون",
    "admin_dashboardowner_modelranking": "تصنيف الموديلات",
    "admin_dashboardowner_busiestaccess": "أوقات الوصول الأكثر ازدحامًا على المنصة",
    "admin_dashboardowner_hits": "النقرات",
    "admin_dashboardowner_accessesperiod": "الوصول حسب الفترة",
    "admin_dashboardowner_models": "الموديلات",
    "admin_dashboardowner_activeuser": "المستخدمون النشطون",
    "admin_dashboardowner_deletedaccount": "حذف حساباتهم",
    "admin_dashboardowner_user_name": "الاسم",
    "admin_dashboardowner_user_email": "البريد الإلكتروني",
    "admin_dashboardowner_user_phone": "الهاتف",
    "admin_dashboardowner_user_whatsapp": "واتساب",
    "admin_dashboardowner_user_telegram": "تلغرام",
    "admin_dashboardowner_user_usersince": "المستخدم منذ",
    "admin_dashboardowner_user_status": "الحالة",
    "admin_dashboardowner_user_active": "نشط",
    "admin_dashboardowner_user_inactive": "غير نشط",
    "admin_dashboardowner_user_lastaccess": "آخر وصول",
    "admin_dashboardowner_user_modify": "تعديل المعلومات",
    "admin_dashboardowner_user_save": "حفظ",
    "admin_dashboardowner_user_redefine": "إعادة تعريف كلمة المرور",
    "admin_dashboardowner_user_suspend": "تعليق الحساب",
    "admin_dashboardowner_user_sendemail": "إرسال بريد إلكتروني",
    "admin_dashboardowner_user_success": "تم التحديث بنجاح",
    "admin_dashboardowner_user_successsuspend": "تم التعليق بنجاح",
    "admin_dashboardowner_user_successreset": "تم إرسال بريد إعادة تعيين كلمة المرور",
    "admin_dashboardowner_user_back": "رجوع",
    "admin_dashboardowner_user_actions": "إجراءات المسؤول",
    "admin_dashboardowner_escorts_it1_title": "الموديلات النشطة",
    "admin_dashboardowner_escorts_it2_title": "حذف الحسابات",
    "admin_dashboardowner_escorts_it3_title": "المرافقون المحظورون",
    "admin_dashboardowner_escorts_col1_title": "مستخدمو المنصة",
    "admin_dashboardowner_escorts_col2_title": "المستخدم منذ",
    "admin_dashboardowner_escorts_col3_title": "آخر وصول",
    "admin_dashboardowner_escorts_col4_title": "تاريخ حذف الحساب",
    "admin_dashboardowner_escorts_col5_title": "تاريخ الحظر",
    "admin_dashboardowner_loadmore": "تحميل المزيد",
    "availability_hours": "ساعات التوفر",
    "price": "السعر",
    "where_do_you_serve": "أين تقدم خدماتك",
    "availability": "التوفر",
    "duration": "المدة",
    "value": "القيمة",
    "location": "الموقع",
    "about_me": "عنّي",
    "service": "الخدمة",
    "hygiene": "النظافة",
    "socialtime": "الوقت الاجتماعي",
    "nodata": "البيانات غير متاحة",
    "nocomment": "لا توجد تعليقات متاحة.",
    "annon": "مجهول",
    "noreview": "لا توجد مراجعات متاحة.",
    "addverification": "إضافة شارة التحقق",
    "removeerification": "إزالة شارة التحقق",
    "admin_dashboardowner_morning": "صباحًا",
    "admin_dashboardowner_afternoon": "مساءً",
    "admin_dashboardowner_night": "ليلاً",
    "admin_dashboardowner_early_morning": "صباح الباكر",
    "admin_dashboardowner_activeescorts": "المرافقون النشطون",
    "admin_dashboardowner_activeusers": "المستخدمون النشطون",
    "admin_dashboardowner_bannedescorts": "المرافقون المحظورون",
    "admin_dashboardowner_bannedusers": "المستخدمون المحظورون",
    "admin_dashboardowner_visitprofile": "زيارة الملف الشخصي",
    "city_state": "المدينة/الدولة",
    "posts": "المنشورات",
    "videos": "الفيديوهات",
    "likes": "الإعجابات",
    "comments": "التعليقات"    
}