export const static_translation = {
    "dexsire_is_respect": "Dexsire é respeito",
    "safety_and_dignity": "segurança e dignidade",
    "register_right_now": "Registre-se agora mesmo.",
    "register": "Registre-se",
    "our_escorts": "Nossas acompanhantes",
    "discover_some_models_available_to_you": "Descubra algumas modelos disponíveis para você",
    "load_more": "Carregue mais",
    "connecting_you_to_the_highest_level_of_experience": "Conectando você ao mais alto nível de experiência.",
    "find_escorts_by_region": "Encontre acompanhantes por região",
    "contact_us": "Entre em contato conosco",
    "frequently_asked_questions": "Perguntas frequentes",
    "copyright_2024_dexsire": "© Copyright 2024 Dexsire, Suíça. Todos os direitos reservados.",
    "privacy_policy": "Política de Privacidade",
    "terms_of_service": "Termos de Serviço",
    "and": "e",
    "service_terms": "Termos de Serviço",
    "canton":"Cantão",
    "ville":"Ville",
    "services_offered":"Serviços oferecidos",
    "category":"Categoria",
    "clear_filter":"Limpar filtro",
    "find_escorts":"Encontre acompanhantes",
    "find_the_ideal_model":"Encontre a modelo ideal para você",
    "login_register": "Login/Registrar",
    "announcement": "Anúncio",
    "my_profile": "Meu perfil",
    "exit": "Sair",
    "home": "Página inicial",
    "advanced_search": "advanced search",
    "advertise_here": "Anuncie aqui",
    "contact": "Contato",
    "im_a_escorts": "Sou uma acompanhante",
    "im_a_customer": "Sou um cliente",
    "i_already_have_registration": "Já tenho registro",
    "i_want_to_sign_up": "Quero me inscrever",
    "login": "Entrar",
    "enter_your_username_and_password": "Digite seu nome de usuário e senha.",
    "i_forgot_my_password": "Esqueci minha senha",
    "to_enter": "Para entrar",
    "email": "E-mail",
    "password": "Senha",
    "fill_email_field": "Preencher campo de e-mail",
    "fill_name_field": "Preencher campo de nome",
    "fill_password_field": "Preencher campo de senha",
    "fill_360_video": "Preencher vídeo 360",
    "minimum_4_photos": "Mínimo de 4 fotos",
    "fill_pseudo_field": "Preencher campo Pseudo",
    "fill_category_field": "Preencher campo Categoria",
    "fill_bio_field": "Preencher campo Biografia",
    "fill_age_field": "Preencher campo Idade",
    "your_age_must_be_over_18": "Sua idade deve ser maior de 18",
    "fill_phone_field": "Preencher campo Telefone",
    "fill_canton_field": "Preencher campo Cantão",
    "fill_ville_field": "Preencher campo Vila",
    "fill_nationality_field": "Preencher campo Nacionalidade",
    "fill_size_field": "Preencher campo Tamanho",
    "fill_weight_field": "Preencher campo Peso",
    "fill_hair_color_field": "Preencher Campo de cor do cabelo",
    "fill_breasts_field": "Campo de preenchimento de seios",
    "fill_eyes_color_field": "Campo de preenchimento de cor dos olhos",
    "select_at_least_one_mobility_option": "Selecione pelo menos uma opção de mobilidade",
    "select_at_least_one_payment_option": "Selecione pelo menos uma opção de pagamento",
    "select_at_least_one_language_option": "Selecione pelo menos uma opção de idioma",
    "fill_in_your_details": "Preencha seus detalhes",
    "user_registration": "Registro de usuário",
    "plan": "Plano",
    "select_a_plan": "Selecione um plano",
    "personal_data": "Dados pessoais",
    "profile": "Perfil",
    "appearance": "Aparência",
    "payment": "Pagamento",
    "model_registration": "Registro de modelo",
    "buy_highlights": "Comprar destaques",
    "fill_in_your_profile": "Preencha seu perfil",
    "name":"Nome",
    "confirm_password":"Confirmar senha",
    "fill_all_fields":"Preencher todos os campos",
    "invalid_email":"E-mail inválido",
    "password_and_confirmation_not_match":"Senha e confirmação não correspondem",
    "account_successfully_registred":"Conta registrada com sucesso!",
    "registration_completed_successfully":"Registro concluído com sucesso",
    "you_must_verify":"Você deve verificar seu e-mail para ativar sua conta. Se não encontrar na sua caixa de entrada, verifique sua pasta de spam.",
    "take_the_opportunity":"Aproveite a oportunidade para destacar seu perfil, invista em créditos para ficar no topo.",
    "want_to_buy_later":"Quero comprar depois",
    "i_want_to_buy_credits_now":"Quero comprar créditos agora",
    "advance":"Avançar",
    "now_its_time_to_report_your_appearance":"Agora é hora de relatar sua aparição",
    "verification_photo":"Foto de verificação",
    "exemple":"Exemplo",
    "send_a_photo_holding":"envie uma foto segurando um papel com dexsire.com escrito nele",
    "drag_the_image_here_or_click_here":"Arraste o imagem aqui ou clique aqui",
    "i_agree_with_the_platform":"Eu concordo com a plataforma",
    "accept_terms_to_continue":"Aceite os termos para continuar",
    "pseudo": "Pseudo",
    "bio": "Bio",
    "age": "Idade",
    "phone": "Telefone",
    "nationality": "Nacionalidade",
    "size": "Tamanho",
    "weight": "Peso",
    "height": "Altura",
    "language": "Idioma",
    "hair_color": "Cor do cabelo",
    "breasts": "Seios",
    "eye_color": "Cor dos olhos",
    "mobility": "Mobilidade",
    "payments_accepted": "Pagamentos aceitos",
    "languages_spoken": "Idiomas falados",
    "what_services_do_you_offer": "Quais serviços você oferece?",
    "describe_yourself_below": "Descreva-se abaixo:",
    "note": "Observação:",
    "the_file_is_too_long": "O arquivo é muito grande. O tamanho máximo é 50 MB.",
    "upload_a_360": "Enviar um vídeo 360º",
    "instruction_on_how": "Instruções sobre como gravar vídeo 360º",
    "drag_video": "Arraste o vídeo aqui ou clique aqui",
    "supported_video": "Arquivos suportados: MP4 ou AVI",
    "maximum_video": "Tamanho máximo do arquivo: 50mb",
    "upload": "Carregar",
    "drag_image": "Arraste a imagem aqui ou clique aqui",
    "supported_image": "Arquivos suportados: JPG e PNG",
    "maximum_image": "Tamanho máximo do arquivo: 8mb. Mínimo de 4 fotos.",
    "the_image_is_too_big": "O arquivo é muito grande. O tamanho máximo é 8MB.",
    "in_addition": "Além do destaque periódico incluído como parte das funcionalidades do painel de controle, você também terá a opção de comprar destaques adicionais. Isso permitirá que você exiba seus anúncios com mais frequência ou por períodos mais longos, aumentando ainda mais sua exposição na plataforma.",
    "license": "Licença",
    "license_5days": "5 dias - Licença",
    "license_5days_price": "89,90 francos",
    "payment_via": "O pagamento da sua assinatura será feito via",
    "continue": "Continuar",
    "choose_plan": "Escolher plano",
    "announcement_title_1": "Interface limpa e intuitiva",
    "announcement_desc_1": "Oferecemos uma interface limpa e intuitiva para que as modelos possam gerenciar seus anúncios de forma eficiente e sem complicações. Nossa plataforma foi projetada para ser fácil de usar, proporcionando uma experiência de gerenciamento de anúncios agradável e sem estresse.",
    "announcement_title_2": "Opções de gerenciamento de anúncios",
    "announcement_desc_2": "Tenha controle total sobre seus anúncios com nossas opções de criar, editar e excluir. Você pode criar novos anúncios, editar informações existentes e remover anúncios conforme necessário, tudo em um só lugar.",
    "announcement_title_3": "Atualizando informações pessoais",
    "announcement_desc_3": "Mantenha suas informações atualizadas com facilidade. Com nossa plataforma, você pode atualizar informações pessoais como fotos e descrição, garantindo que seus clientes recebam as últimas informações sobre seus serviços.",
    "announcement_title_4": "Destaque a cada 45 minutos",
    "announcement_desc_4": "Destaque-se a cada 45 minutos para maximizar sua visibilidade e atrair mais clientes. Com apenas um clique, você pode garantir que seu anúncio seja exibido com destaque, destacando-se entre os demais para maior exposição.",
    "announcement_title_5": "Compre destaques",
    "announcement_desc_5": "Aumente sua visibilidade e alcance comprando destaques adicionais para seus anúncios. Oferecemos opções flexíveis para que você possa escolher o plano que melhor se adapta às suas necessidades e orçamento, garantindo que seu perfil receba a atenção que merece.",
    "announcement_title_ads": "Anuncie aqui",
    "announcement_desc_ads_1": "Junte-se a nós e aproveite uma plataforma dedicada a promover sua segurança, visibilidade e sucesso.",
    "announcement_desc_ads_2": "Ao se registrar no LUXEO, você terá acesso a uma série de benefícios exclusivos, incluindo:",
    "announcement_desc_ads_3": "Não perca tempo! Cadastre-se agora e embarque nessa jornada de sucesso conosco na LUXEO.",
    "announcement_action_ads": "Cadastre-se agora",
    "announcement_section_title1": "Perfil personalizado",
    "announcement_section_desc1": "Crie um perfil único e atraente que destaque seus talentos, serviços e personalidade.",
    "announcement_section_title2": "Expanda sua visibilidade",
    "announcement_section_desc2": "Alcance um público maior e aumente suas oportunidades de negócios sendo destaque em nossa seção 'Garotas em destaque'.",
    "announcement_section_title3": "Facilidade de uso",
    "announcement_section_desc3": "Nossa plataforma intuitiva e fácil de usar simplifica o gerenciamento do seu perfil e a interação com clientes em potencial.",
    "announcement_section_title4": "Avaliações positivas",
    "announcement_section_desc4": "Receba feedback valioso de clientes satisfeitos e aumente sua reputação na comunidade.",
    "announcement_section_title5": "Promoções Especiais",
    "announcement_section_desc5": "Obtenha acesso exclusivo a ofertas especiais e eventos promocionais para impulsionar seu negócio.",
    "announcement_form_title": "Entrar",
    "announcement_form_subtitle": "Já sou um anunciante",
    "announcement_form_action1": "Registrar",
    "announcement_form_action_title": "Não tenho contato na plataforma",
    "announcement_form_action2": "Criar uma conta",
    "announcement_form_email": "E-mail",
    "announcement_form_password": "Senha",
    "announcement_form_forgot": "Esqueci minha senha",
    "announcement_form_submit": "Login",
    "forgot_title": "Esqueci minha senha",
    "forgot_subtitle": "Digite seu e-mail de registro",
    "forgot_input_email": "E-mail",
    "forgot_action": "Enviar link para renovação de senha",
    "forgot_success_title": "E-mail enviado com sucesso.",
    "forgot_success_text": "Enviamos um e-mail com um link para renovar sua senha. Se não conseguir encontrá-lo, verifique sua pasta de spam.",
    "create_password_validate1": "Preencha o campo de nova senha",
    "create_password_validate2": "Preencha o campo de nova confirmação de senha",
    "create_password_validate3": "A senha e a confirmação de senha não correspondem",
    "create_password_success": "Senha criada com sucesso",
    "create_password_title": "Esqueci minha senha",
    "create_password_text": "Criar uma nova senha",
    "create_password_input_1": "Nova senha",
    "create_password_input_2": "Nova confirmação de senha",
    "create_password_input_submit": "Salvar",
    "purchase_title": "Destaque seu perfil",
    "purchase_subtitle": "Compra de créditos",
    "purchase_text": "Selecione quantos créditos deseja comprar",
    "purchase_action": "Quero comprar mais tarde",
    "purchase_selected": "Selecionado",
    "purchase_select": "Selecionar",
    "customer_profile_name": "Nome",
    "customer_profile_user": "Usuário desde",
    "customer_profile_status": "Status",
    "customer_profile_email": "E-mail",
    "customer_profile_subs_type": "Tipo de assinatura",
    "customer_profile_subs_value": "Valor da assinatura",
    "customer_profile_saved": "Salvo",
    "customer_profile_save": "Salvar",
    "customer_profile_edit": "Editar informações",
    "customer_profile_delete": "Excluir conta",
    "customer_profile_fav": "Acompanhantes que você adicionou aos favoritos",
    "gallery_video": "Galeria de vídeos",
    "gallery_image": "Galeria de fotos",
    "gallery_load_more": "Carregar mais",
    "aboutme_contact": "Entre em contato comigo",
    "aboutme_contact_text": "Não atendo chamadas de voz",
    "aboutme_contact_some_photos": "Veja algumas fotos e vídeos",
    "aboutme_contact_photos": "Fotos",
    "aboutme_contact_videos": "Vídeos",
    "review_service": "Serviço",
    "review_hygiene": "Higiene",
    "review_socialtime": "Tempo social",
    "review_apparence": "Aparência",
    "review_location": "Localização",
    "review_comment": "Comentário",
    "review_fill_all_fields": "Preencha todos os campos",
    "review_what_customer_saying": "O que alguns clientes estão dizendo:",
    "review_left_comment": "Deixou seu comentário",
    "review_add_your_review": "Adicione sua avaliação",
    "review_sent_review": "Avaliação enviada",
    "escortservice_success": "Salvo com sucesso",
    "escortservice_title": "Entre em contato comigo",
    "escortservice_text": "Não atendo voz chamadas",
    "contact_title": "Contato",
    "contact_email": "E-mail",
    "contact_whats_desc": "Fale conosco pelo WhatsApp.",
    "contact_whats_label": "Clique aqui",
    "contact_privacy_title": "Política de privacidade",
    "contact_privacy_desc": "Receba feedback valioso de clientes satisfeitos e aumente sua reputação na comunidade.",
    "contact_privacy_label": "Acessar FAQ",
    "contactform_name": "Seu nome",
    "contactform_email": "Seu melhor e-mail",
    "contactform_subject": "Assunto",
    "contactform_message": "Mensagem",
    "contactform_fill_fields": "Preencha todos os campos",
    "contactform_invalidmail": "E-mail inválido",
    "contactform_success": "Contato enviado com sucesso",
    "contactform_title": "Precisa de ajuda? Será um prazer falar com você.",
    "contactform_action": "Enviar mensagem",
    "faq_title": "Perguntas frequentes",
    "faq_subtitle": "Perguntas frequentes dos usuários sobre o uso do aplicativo, seus recursos e políticas.",
    "faq_text": "Não encontrou o que estava procurando?",
    "faq_subtext": "Entre em contato conosco.",
    "faq_action": "Enviar mensagem",
    "notfound_title": "Erro 404",
    "notfound_text": "A página que você tentou acessar não existe ou está temporariamente fora do ar.",
    "notfound_action": "Voltar para a página inicial",
    "advancedsearch_canton": "Cantão",
    "advancedsearch_age": "Idade",
    "advancedsearch_services": "Serviços oferecidos",
    "advancedsearch_category": "Categoria",
    "advancedsearch_customerreviews": "Avaliações de clientes",
    "advancedsearch_nationality": "Nacionalidade/Origem étnica",
    "advancedsearch_lang": "Idiomas falados",
    "advancedsearch_appearance": "Aparência física",
    "advancedsearch_find": "Encontre acompanhantes",
    "advancedsearch_title": "Pesquisa avançada",
    "terms_title1": "1. Introdução",
    "terms_content1": "A página Termos de Serviço é uma parte essencial da nossa plataforma, definindo os termos e condições que regem o uso dos nossos serviços pelos usuários. Esta página estabelece os direitos e responsabilidades dos usuários e da empresa, garantindo uma experiência segura e transparente para todos os envolvidos.",
    "terms_title2": "2. Aceitação dos Termos",
    "terms_content2": "Explicamos que o uso da nossa plataforma implica umaaceitação automática e vinculativa dos Termos de Serviço.",
    "terms_title3": "3. Descrição dos Serviços",
    "terms_content3": "Detalhamos os serviços oferecidos pela nossa plataforma, incluindo suas funcionalidades, características e limitações. Isso dá aos usuários uma compreensão clara do que eles podem esperar ao usar nossos serviços.",
    "terms_title4": "4. Responsabilidades do Usuário",
    "terms_content4": "Esclarecemos as responsabilidades dos usuários ao usar nossa plataforma, incluindo: fornecer informações precisas e atualizadas durante o registro, respeitar os direitos de propriedade intelectual, manter a segurança das credenciais de acesso e não violar as leis aplicáveis ​​ou os direitos de terceiros.",
    "terms_title5": "5. Propriedade Intelectual",
    "terms_content5": "Informamos os usuários sobre a propriedade de direitos autorais, marcas registradas e outros direitos de propriedade intelectual relacionados à nossa plataforma e seu conteúdo. Os usuários concordam em respeitar esses direitos e não usar nosso conteúdo de forma não autorizada.",
    "terms_title6": "6. Política de Privacidade",
    "terms_content6": "Fazemos referência à nossa política de privacidade e explicamos como tratamos as informações do usuário. Os usuários são incentivados a revisar nossa política de privacidade para entender como suas informações são coletadas, usadas e protegidas.",
    "terms_title7": "7. Modificações nos Termos de Serviço",
    "terms_content7": "Reservamo-nos o direito de modificar ou atualizar os Termos de Serviço conforme necessário. As alterações entram em vigor após a publicação dos Termos revisados ​​em nossa plataforma.",
    "terms_title8": "8. Limitação de Responsabilidade",
    "terms_content8": "Isentamos a empresa de responsabilidade por danos diretos, indiretos, incidentais, consequenciais ou punitivos resultantes do uso de nossos serviços. Os usuários concordam em usar nossos serviços por sua conta e risco.",
    "terms_title9": "9. Término do Serviço",
    "terms_content9": "Nós nos reservamos o direito de rescindir ou suspender o acesso dos usuários à nossa plataforma, sem aviso prévio, em caso de violação dos Termos de Serviço.",
    "terms_title10": "10. Disposições Gerais",
    "terms_content10": "Incluímos disposições sobre a lei aplicável, jurisdição, renúncia de direitos, integridade do contrato e outras disposições gerais para garantir a aplicabilidade e eficácia dos Termos de Serviço.",
    "terms_title11": "11. Contato",
    "terms_content11": "Fornecemos informações de contato para que os usuários possam entrar em contato conosco com quaisquer dúvidas, preocupações ou problemas relacionados aos Termos de Serviço.",
    "terms_title": "Termos de serviço",
    "terms_subtitle": "Leia atentamente os termos de serviço da plataforma antes de prosseguir.",
    "purchasedetails_title": "Detalhes da compra",
    "purchasedetails_text1": "Você terá seu anúncio",
    "purchasedetails_text2": "Após esse período, você deve reativar sua assinatura.",
    "purchasedetails_text3": "Você pode pausar seu anúncio a qualquer momento antes do término do prazo. Dessa forma, os créditos também serão pausados ​​para você usar quando quiser.",
    "purchasedetails_text4": "Valor do plano",
    "purchasedetails_text_francs": "francos",
    "purchasedetails_text_confirm": "Confirmar pagamento",
    "purchasedetails_text_congratulations1": "Parabéns. Seu",
    "purchasedetails_text_congratulations2": "o plano em destaque foi ativado com sucesso!",
    "profilemodal_option1": "Perfil do cliente",
    "profilemodal_option2": "Página interna",
    "profilemodal_option3": "Proprietário administrativo",
    "profilemodal_option4": "Acompanhante administrativo",
    "profilemodal_title": "Escolha o tipo de perfil",
    "privacy_title1": "1. Introdução",
    "privacy_conte1": "Nossa página de privacidade tem como objetivo informar nossos usuários sobre como coletamos, usamos e protegemos suas informações pessoais enquanto eles usam nossa plataforma. A privacidade de nossos usuários é uma prioridade para nós, e esta página detalha nossas práticas de privacidade em conformidade com as leis e regulamentos aplicáveis.",
    "privacy_title2": "2. Informações coletadas",
    "privacy_conte2": "Nesta seção, descrevemos os tipos de informações pessoais que coletamos dos usuários e os métodos pelos quais essas informações são obtidas. Isso inclui, mas não está limitado a: Informações fornecidas pelos usuários durante o registro, como nome, e-mail, data de nascimento, etc. Dados de uso, como histórico de navegação, interações na plataforma, preferências do usuário, etc. Informações coletadas automaticamente por meio de cookies e tecnologias semelhantes.",
    "privacy_title3": "3. Uso das informações",
    "privacy_conte3": "Explicamos como as informações pessoais dos usuários são usadas por nossa plataforma. Isso pode incluir: Personalização da experiência do usuário e entrega de conteúdo relevante. Melhoria contínua de nossos serviços e recursos. Comunicar com os usuários sobre atualizações, ofertas especiais, etc. Conformidade com obrigações legais e regulatórias.",
    "privacy_title4": "4. Compartilhamento de informações",
    "privacy_conte4": "Detalhamos com quem compartilhamos as informações do usuário e os motivos para tal compartilhamento. Isso pode envolver: Parceiros de serviço que nos auxiliam a forneceridentificando nossos serviços. Autoridades legais, quando exigido por lei ou em resposta a solicitações oficiais. Terceiros em caso de fusão, aquisição ou venda de ativos.",
    "privacy_title5": "5. Proteção de informações",
    "privacy_conte5": "Explicamos as medidas de segurança que implementamos para proteger as informações dos usuários contra acesso não autorizado, uso indevido, alteração ou divulgação. Isso pode incluir: Criptografia de dados. Controles de acesso. Monitoramento regular de sistemas e redes.",
    "privacy_title6": "6. Direitos do usuário",
    "privacy_conte6": "Informamos os usuários sobre seus direitos em relação às suas informações pessoais, incluindo o direito de acessar, corrigir, excluir ou limitar o uso de seus dados. Também fornecemos informações sobre como os usuários podem exercer esses direitos.",
    "privacy_title7": "7. Alterações na Política de Privacidade",
    "privacy_conte7": "Reservamo-nos o direito de atualizar ou modificar nossa política de privacidade conforme necessário. Quaisquer alterações significativas serão comunicadas aos usuários por meio de nossa plataforma ou por outros meios apropriados.",
    "privacy_title8": "8. Contato",
    "privacy_conte8": "Fornecemos informações de contato para que os usuários possam entrar em contato conosco caso tenham dúvidas, preocupações ou solicitações relacionadas à privacidade.",
    "privacy_title9": "9. Data efetiva",
    "privacy_conte9": "Indicamos a data efetiva de nossa política de privacidade e informamos os usuários sobre a importância de revisar regularmente esta página para se manterem atualizados com nossas práticas de privacidade.",
    "privacy_title10": "10. Conformidade Legal",
    "privacy_conte10": "Declaramos nosso compromisso com a conformidade com as leis e regulamentos de proteção de dados aplicáveis ​​e informamos os usuários sobre seus direitos e recursos em caso de violações de privacidade.",
    "privacy_title": "Política de Privacidade",
    "privacy_subtitle": "Leia atentamente os termos de uso da plataforma antes de prosseguir.",
    "deleteaccount_success": "Conta excluída com sucesso",
    "deleteaccount_title": "Você está prestes a excluir sua conta permanentemente.",
    "deleteaccount_text": "Se você decidiu não excluir sua conta neste momento, pode simplesmente fechar esta página e continuar usando nossa plataforma normalmente. Se você tiver dúvidas ou precisar de assistência, não hesite em entrar em contato com nossa equipe de suporte. Estamos aqui para ajudar!",
    "deleteaccount_confirm": "Confirmar exclusão da conta",
    "deleteaccount_cancel": "Não quero excluir",
    "deleteaccount_success_title": "Sua conta foi excluída com sucesso.",
    "deleteaccount_success_text": "Foi um prazer ter você passando esse tempo conosco. Lembre-se de que você é bem-vindo sempre que precisar. Estaremos aqui para ajudar você.",
    "ageverification_title": "Verificação de idade",
    "ageverification_text1": "Este site contém conteúdo restrito a pessoas com mais de 18 anos. Ao clicar em \"Confirmar\", você está certificando que é maior de idade segundo as leis de sua jurisdição. Se você não tem a idade mínima necessária, saia deste site imediatamente.",
    "ageverification_text2": "A confirmação falsa de idade é uma violação dos termos de uso deste site.",
    "ageverification_text3": "Você tem mais de 18 anos?",
    "ageverification_text4": "anos de idade?",
    "ageverification_yes": "Sim",
    "ageverification_no": "Não",
    "ageverification_policy": "Política de Privacidade",
    "ageverification_terms": "Termos de uso",
    "escortinfo_reviews": "Avaliações deixadas por clientes",
    "follower": "Seguidor",
    "unfollow": "Seguir",
    "imagesuccessfully": "Imagem enviada com sucesso",
    "admin_side_option1": "Página inicial",
    "admin_side_option2": "Sobre mim",
    "admin_side_option3": "Fotos",
    "admin_side_option4": "Vídeos",
    "admin_side_option5": "Avaliações",
    "admin_side_option6": "Serviços",
    "admin_side_option7": "Anúncios",
    "admin_side_option8": "Créditos adicionais",
    "admin_side_option9": "Planos",
    "admin_side_option10": "Estatísticas",
    "admin_side_option11": "Suporte",
    "admin_side_option12": "Página inicial",
    "admin_side_option13": "Usuários",
    "admin_side_option14": "Acompanhantes",
    "admin_side_option15": "Relatórios",
    "admin_sidefooter_option1": "Excluir conta",
    "admin_sidefooter_option2": "Sair",
    "admin_dashboard_title1": "Favoritos",
    "admin_dashboard_subtitle1": "Usuários que estão seguindo você",
    "admin_dashboard_title2": "Cliques em anúncios",
    "admin_dashboard_subtitle2": "Últimos 30 dias",
    "admin_dashboard_title3": "Seus créditos",
    "admin_dashboard_subtitle3": "Os créditos acabaram",
    "admin_dashboard_title4": "Status da assinatura",
    "admin_dashboard_subtitle4": "Quase expirando",
    "admin_dashboard_credit": "crédito",
    "admin_dashboard_announcement": "credAnnouncementit",
    "admin_dashboard_visit": "Visitas ao seu perfil",
    "admin_dashboard_visits": "visitas",
    "admin_dashboard_activead": "anúncio ativo",
    "admin_dashboard_aboutme_success": "Sobre mim atualizado com sucesso",
    "admin_dashboard_aboutme_title": "Sobre mim",
    "admin_dashboard_aboutme_phone": "Telefone",
    "admin_dashboard_aboutme_whats": "Whatsapp",
    "admin_dashboard_aboutme_telegram": "Telegram",
    "admin_dashboard_aboutme_size": "Tamanho",
    "admin_dashboard_aboutme_weight": "Peso",
    "admin_dashboard_aboutme_nationality": "Nacionalidade",
    "admin_dashboard_aboutme_haircolor": "Cor do cabelo",
    "admin_dashboard_aboutme_breasts": "Seios",
    "admin_dashboard_aboutme_eyecolor": "Cor dos olhos",
    "admin_dashboard_aboutme_gender": "Preferência (Gênero)",
    "admin_dashboard_credits_credit": "crédito",
    "admin_dashboard_credits_franc": "francos",
    "admin_dashboard_credits_item1_title": "Como funciona a assinatura?",
    "admin_dashboard_credits_item1_subtitle": "Você pode assinar por um tempo específico para ser destaque na plataforma.",
    "admin_dashboard_credits_item1_button": "30 dias",
    "admin_dashboard_credits_item1_footer": "Após esse período, você precisa renovar sua assinatura para que seus anúncios sejam destacados na plataforma e aumentem as chances de ser contratado pelos usuários.",
    "admin_dashboard_credits_item2_title": "Créditos e períodos",
    "admin_dashboard_credits_item2_subtitle": "Para cada dia de anúncio em destaque, você precisará de 1 crédito.",
    "admin_dashboard_credits_item2_footer": "Para cada dia de anúncio em destaque, você precisará de 1 crédito.",
    "admin_dashboard_credits_item3_title": "Compra de créditos",
    "admin_dashboard_credits_item3_subtitle": "Selecione quantos créditos você quer comprar.",
    "admin_dashboard_credits_title": "Seus créditos",
    "admin_dashboard_credits_subtitle": "Seus créditos expiraram. Você precisa comprar mais créditos para ser destaque na plataforma.",
    "admin_dashboard_plans1_title": "Plano Básico",
    "admin_dashboard_plans1_subtitle": "Destaque por 5 dias",
    "admin_dashboard_plans1_text": "Você pode ter seu anúncio em destaque por 5 dias.",
    "admin_dashboard_plans1_label": "Escolha o plano",
    "admin_dashboard_plans2_title": "Plano Bronze",
    "admin_dashboard_plans2_subtitle": "Destaque por 15 dias",
    "admin_dashboard_plans2_text": "Você pode ter seu anúncio em destaque por 2 semanas.",
    "admin_dashboard_plans2_label": "Escolha o plano",
    "admin_dashboard_plans3_title": "Plano Premium",
    "admin_dashboard_plans3_subtitle": "Destaque por 30 dias",
    "admin_dashboard_plans3_text": "Você pode ter seu anúncio destacado por 1 mês.",
    "admin_dashboard_plans3_label": "Escolha o plano",
    "admin_dashboard_plans_title": "Seus créditos",
    "admin_dashboard_plans_subtitle": "Seus créditos expiraram. Você precisa comprar mais créditos para ser destaque na plataforma.",
    "admin_dashboard_plans_choose": "Escolher plano",
    "admin_dashboard_plans_buymore": "Comprar mais créditos",
    "admin_dashboard_stats_12": "12 meses",
    "admin_dashboard_stats_6": "6 meses",
    "admin_dashboard_stats_30": "30 dias",
    "admin_dashboard_stats_7": "7 dias",
    "admin_dashboard_opt1_title": "Adicionado aos favoritos",
    "admin_dashboard_opt1_text": "adições",
    "admin_dashboard_opt2_title": "Cliques no botão do Whatsapp",
    "admin_dashboard_opt2_text": "cliques",
    "admin_dashboard_sup_subject": "Assunto",
    "admin_dashboard_sup_message": "Mensagem",
    "admin_dashboard_sup_fill": "Preencha todos os campos",
    "admin_dashboard_sup_success": "Suporte enviado com sucesso",
    "admin_dashboard_sup_title": "Se você tiver alguma dúvida, nossa equipe de suporte ficará feliz em responder.",
    "admin_dashboard_sup_action": "Enviar mensagem",
    "admin_dashboard_supfooter_email": "E-mail",
    "admin_dashboard_supfooter_phone": "Telefone",
    "admin_dashboard_supfooter_item1_title": "Perguntas frequentes",
    "admin_dashboard_supfooter_item1_subtitle": "Separamos algumas perguntas que são solicitadas aqui.",
    "admin_dashboard_supfooter_item1_label": "Perguntas frequentes sobre acesso",
    "admin_dashboard_supfooter_item2_title": "Política de privacidade",
    "admin_dashboard_supfooter_item2_subtitle": "Entenda um pouco mais sobre as políticas de privacidade.",
    "admin_dashboard_supfooter_item2_label": "Acesso",
    "admin_dashboard_supfooter_item3_title": "Termos de serviço",
    "admin_dashboard_supfooter_item3_subtitle": "Entenda um pouco mais sobre os termos de serviço.",
    "admin_dashboard_supfooter_item3_label": "Acesso",
    "admin_dashboard_delete_text": "Se você decidiu não continuar usando nossa plataforma, oferecemos a opção de excluir permanentemente sua conta. Este processo é irreversível e resultará na remoção completa de todas as informações associadas à sua conta, incluindo seu perfil, histórico de atividades e dados pessoais. Esteja ciente de que, ao excluir sua conta, você perderá o acesso a todos os recursos e serviços oferecidos pela plataforma. Além disso, quaisquer transações, mensagens ou interações concluídas anteriormente serão excluídas permanentemente e não poderão ser recuperadas. Se tiver certeza de que deseja prosseguir com a exclusão de sua conta, confirme sua decisão abaixo. Após a confirmação, sua conta será excluída permanentemente e você será automacamente desconectado da plataforma",
    "admin_dashboard_delete_title": "O que você precisa saber antes de excluir sua conta?",
    "admin_dashboard_delete_action": "Excluir minha conta",
    "what_are_you_looking_for": "O que você está procurando?",
    "admin_dashboardowner_item1_title": "Novos usuários",
    "admin_dashboardowner_item1_subtitle": "Últimos 30 dias",
    "admin_dashboardowner_item1_legend": "novos usuários",
    "admin_dashboardowner_item2_title": "Nova acompanhante",
    "admin_dashboardowner_item2_subtitle": "Últimos 30 dias",
    "admin_dashboardowner_item2_legend": "novas modelos",
    "admin_dashboardowner_item3_title": "Visitas ao site",
    "admin_dashboardowner_item3_subtitle": "Últimos 30 dias",
    "admin_dashboardowner_item4_title": "Métodos de pagamento",
    "admin_dashboardowner_item4_subtitle": "Método usado pelos seus clientes",
    "admin_dashboardowner_item4_value": "stripe",
    "admin_dashboardowner_escortcontrol_title": "Acompanhantes para aprovar",
    "admin_dashboardowner_escortcontrol_subtitle": "Certifique-se de que o modelo atenda a todas as políticas da plataforma.",
    "admin_dashboardowner_escortcontrol_approve": "Acompanhantes para aprovar",
    "admin_dashboardowner_escortcontrol_seeall": "Ver todas as solicitações",
    "admin_dashboardowner_escortcontrol_reject": "Rejeitar",
    "admin_dashboardowner_escortcontrol_seemore": "Ver mais",
    "admin_dashboardowner_top_title": "Melhores acompanhantes",
    "admin_dashboardowner_top_subtitle": "Últimos 30 dias",
    "admin_dashboardowner_top_complete": "Classificação completa",
    "admin_dashboardowner_older": "Mais antigo",
    "admin_dashboardowner_newest": "Mais recente",
    "admin_dashboardowner_user": "Usuários ativos",
    "admin_dashboardowner_deleted": "Suas contas foram excluídas",
    "admin_dashboardowner_platformuser": "Usuários da plataforma",
    "admin_dashboardowner_usersince": "Usuário desde",
    "admin_dashboardowner_lastaccess": "Último acesso",
    "admin_dashboardowner_selectperiod": "Selecione o período",
    "admin_dashboardowner_sortby": "Classificar por",
    "admin_dashboardowner_newuser": "novos usuários",
    "admin_dashboardowner_newescorts": "novas acompanhantes",
    "admin_dashboardowner_newmodels": "novas modelos",
    "admin_dashboardowner_modelstitle": "Modelos",
    "admin_dashboardowner_userstitle": "Usuários",
    "admin_dashboardowner_modelranking": "Classificação de modelos",
    "admin_dashboardowner_busiestaccess": "Horários de acesso mais movimentados na plataforma",
    "admin_dashboardowner_hits": "acessos",
    "admin_dashboardowner_accessesperiod": "Acessos por período",
    "admin_dashboardowner_models": "modelos",
    "admin_dashboardowner_activeuser": "usuários ativos",
    "admin_dashboardowner_deletedaccount": "Suas contas foram excluídas",
    "admin_dashboardowner_user_name": "Nome",
    "admin_dashboardowner_user_email": "E-mail",
    "admin_dashboardowner_user_phone": "Telefone",
    "admin_dashboardowner_user_whatsapp": "Whatsapp",
    "admin_dashboardowner_user_telegram": "Telegrama",
    "admin_dashboardowner_user_usersince": "Usuário desde",
    "admin_dashboardowner_user_status": "Status",
    "admin_dashboardowner_user_active": "Ativo",
    "admin_dashboardowner_user_inactive": "Inativo",
    "admin_dashboardowner_user_lastaccess": "Último acesso",
    "admin_dashboardowner_user_modify": "Modificar informações",
    "admin_dashboardowner_user_save": "Salvar",
    "admin_dashboardowner_user_redefine": "Redefinir senha",
    "admin_dashboardowner_user_suspend": "Suspender conta",
    "admin_dashboardowner_user_sendemail": "Enviar e-mail",
    "admin_dashboardowner_user_success": "Atualizado com sucesso",
    "admin_dashboardowner_user_successsuspend": "Suspenso com sucesso",
    "admin_dashboardowner_user_successreset": "E-mail de redefinição de senha enviado",
    "admin_dashboardowner_user_back": "Voltar",
    "admin_dashboardowner_user_actions": "Ações do administrador",
    "admin_dashboardowner_escorts_it1_title": "Modelos ativos",
    "admin_dashboardowner_escorts_it2_title": "Suas contas foram excluídas",
    "admin_dashboardowner_escorts_it3_title": "Escorts banidos",
    "admin_dashboardowner_escorts_col1_title": "Usuários da plataforma",
    "admin_dashboardowner_escorts_col2_title": "Usuário desde",
    "admin_dashboardowner_escorts_col3_title": "Último acesso",
    "admin_dashboardowner_escorts_col4_title": "Data de exclusão da conta",
    "admin_dashboardowner_escorts_col5_title": "Data do banimento",
    "admin_dashboardowner_loadmore": "Carregar mais",
    "availability_hours": "Horário de disponibilidade",
    "price": "Preço",
    "where_do_you_serve": "Onde você atende",
    "availability": "Disponibilidade",
    "duration": "Duração",
    "value": "Valor",
    "location": "Localização",
    "about_me": "Sobre mim",
    "service": "Serviço",
    "hygiene": "Higiene",
    "socialtime": "Horário social",
    "nodata": "Data não disponível",
    "nocomment": "Nenhum comentário disponível.",
    "annon": "Anônimo",
    "noreview": "Nenhuma avaliação disponível.",
    "addverification": "Adicionar selo de verificação",
    "removeerification": "Remover selo de verificação",
    "admin_dashboardowner_morning": "Manhã",
    "admin_dashboardowner_afternoon": "Tarde",
    "admin_dashboardowner_night": "Noite",
    "admin_dashboardowner_early_morning": "Manhã cedo",
    "admin_dashboardowner_activeescorts": "Acompanhantes ativas",
    "admin_dashboardowner_activeusers": "Usuários ativos",
    "admin_dashboardowner_bannedescorts": "Acompanhantes banidas",
    "admin_dashboardowner_bannedusers": "Usuários banidos",
    "admin_dashboardowner_visitprofile": "Visitar perfil",
    "city_state": "Cidade Estado",
    "posts": "posts",
    "videos": "vídeos",
    "likes": "curtidas",
    "comments": "comentários",
}