export const static_translation = {
    "dexsire_is_respect": "Dexsire — это уважение",
    "safety_and_dignity": "безопасность и достоинство",
    "register_right_now": "Зарегистрируйтесь прямо сейчас.",
    "register": "Зарегистрироваться",
    "our_escorts": "Наши эскорты",
    "discover_some_models_available_to_you": "Откройте для себя некоторые доступные вам модели",
    "load_more": "Загрузить еще",
    "connecting_you_to_the_highest_level_of_experience": "Подключение вас к самому высокому уровню опыта.",
    "find_escorts_by_region": "Найти эскорты по региону",
    "contact_us": "Связаться с нами",
    "frequently_asked_questions": "Часто задаваемые вопросы",
    "copyright_2024_dexsire": "© Авторские права 2024 Dexsire, Швейцария. Все права защищены.",
    "privacy_policy": "Политика конфиденциальности",
    "terms_of_service": "Условия обслуживания",
    "and": "and",
    "service_terms": "Условия обслуживания",
    "canton":"Кантон",
    "ville":"Город",
    "services_offered":"Предлагаемые услуги",
    "category":"Категория",
    "clear_filter":"Очистить фильтр",
    "find_escorts":"Найти эскортниц",
    "find_the_ideal_model":"Найти идеальную модель для вас",
    "login_register": "Войти/Зарегистрироваться",
    "announcement": "Объявление",
    "my_profile": "Мой профиль",
    "exit": "Выйти",
    "home": "Главная",
    "advanced_search": "расширенный поиск",
    "advertise_here": "Рекламируйте здесь",
    "contact": "Контакт",
    "im_a_escorts": "Я эскорт",
    "im_a_customer": "Я клиент",
    "i_already_have_registration": "У меня уже есть регистрация",
    "i_want_to_sign_up": "Я хочу зарегистрироваться",
    "login": "Войти",
    "enter_your_username_and_password": "Введите свое имя пользователя и пароль",
    "i_forgot_my_password": "Я забыл свой пароль",
    "to_enter": "Чтобы войти",
    "email": "Электронная почта",
    "password": "Пароль",
    "fill_email_field": "Заполните поле электронной почты",
    "fill_name_field": "Заполните поле имени",
    "fill_password_field": "Заполните поле пароля",
    "fill_360_video": "Заполните 360 video",
    "minimum_4_photos": "Минимум 4 фото",
    "fill_pseudo_field": "Заполнить псевдополе",
    "fill_category_field": "Заполнить поле категории",
    "fill_bio_field": "Заполнить поле биографии",
    "fill_age_field": "Заполнить поле возраста",
    "your_age_must_be_over_18": "Ваш возраст должен быть старше 18 лет",
    "fill_phone_field": "Заполнить поле телефона",
    "fill_canton_field": "Заполнить поле кантона",
    "fill_ville_field": "Заполнить поле города",
    "fill_nationality_field": "Заполнить поле национальности",
    "fill_size_field": "Заполнить поле размера",
    "fill_weight_field": "Заполнить поле веса",
    "fill_hair_color_field": "Заполнить поле цвета волос",
    "fill_breasts_field": "Заполнить поле груди",
    "fill_eyes_color_field": "Заполните поле цвета глаз",
    "select_at_least_one_mobility_option": "Выберите хотя бы один вариант мобильности",
    "select_at_least_one_payment_option": "Выберите хотя бы один вариант оплаты",
    "select_at_least_one_language_option": "Выберите хотя бы один вариант языка",
    "fill_in_your_details": "Заполните свои данные",
    "user_registration": "Регистрация пользователя",
    "plan": "План",
    "select_a_plan": "Выберите план",
    "personal_data": "Персональные данные",
    "profile": "Профиль",
    "appearance": "Внешний вид",
    "payment": "Оплата",
    "model_registration": "Регистрация модели",
    "buy_highlights": "Купить highlights",
    "fill_in_your_profile": "Заполните свой профиль",
    "name":"Имя",
    "confirm_password":"Подтвердите пароль",
    "fill_all_fields":"Заполните все поля",
    "invalid_email":"Неверный адрес электронной почты",
    "password_and_confirmation_not_match":"Пароль и подтверждение не совпадают",
    "account_successfully_registred":"Учетная запись успешно зарегистрирована!",
    "registration_completed_successfully":"Регистрация успешно завершена",
    "you_must_verify":"Вы должны подтвердить свою электронную почту, чтобы активировать свою учетную запись. Если вы не нашли его в папке «Входящие», проверьте папку со спамом.",
    "take_the_opportunity":"Воспользуйтесь возможностью выделить свой профиль, инвестируйте в кредиты, чтобы оставаться наверху.",
    "want_to_buy_later":"Хочу купить позже",
    "i_want_to_buy_credits_now":"Я хочу купить кредиты сейчас",
    "advance":"Аванс",
    "now_its_time_to_report_your_appearance":"Теперь пришло время сообщить о своей внешности",
    "verification_photo":"Фотография для подтверждения",
    "exemple":"Пример",
    "send_a_photo_holding":"отправьте фотографию, держа в руках бумагу с надписью dexsire.com",
    "drag_the_image_here_or_click_here":"Перетащите изображение сюда или нажмите сюда",
    "i_agree_with_the_platform":"Я согласен с platform's",
    "accept_terms_to_continue":"Принять условия для продолжения",
    "pseudo": "Псевдо",
    "bio": "Биография",
    "age": "Возраст",
    "phone": "Телефон",
    "nationality": "Национальность",
    "size": "Размер",
    "weight": "Вес",
    "height": "Рост",
    "language": "Язык",
    "hair_color": "Цвет волос",
    "breasts": "Грудь",
    "eye_color": "Цвет глаз",
    "mobility": "Мобильность",
    "payments_accepted": "Принимаемые платежи",
    "languages_spoken": "Языки общения",
    "what_services_do_you_offer": "Какие услуги вы предлагаете?",
    "describe_yourself_below": "Опишите себя ниже:",
    "note": "Примечание:",
    "the_file_is_too_long": "Файл слишком большой. Максимальный размер — 50 МБ.",
    "upload_a_360": "Загрузить видео 360º",
    "instruction_on_how": "Инструкции по записи видео 360º",
    "drag_video": "Перетащите видео сюда или нажмите здесь",
    "supported_video": "Поддерживаемые файлы: MP4 или AVI",
    "maximum_video": "Максимальный размер файла: 50 МБ",
    "upload": "Загрузить",
    "drag_image": "Перетащите изображение сюда или нажмите здесь",
    "supported_image": "Поддерживаемые файлы: JPG и PNG",
    "maximum_image": "Максимальный размер файла: 8 МБ. Минимум 4 фотографии.",
    "the_image_is_too_big": "Файл слишком большой. Максимальный размер — 8 МБ.",
    "in_addition": "В дополнение к периодическому выделению, включенному в функции панели управления, у вас также будет возможность приобрести дополнительные выделения. Это позволит вам показывать свои объявления чаще или в течение более длительных периодов времени, что еще больше увеличит вашу экспозицию на платформе.",
    "license": "Лицензия",
    "license_5days": "5 дней — Лицензия",
    "license_5days_price": "89,90 франков",
    "payment_via": "Оплата подписки будет производиться через",
    "continue": "Продолжить",
    "choose_plan": "Выбрать план",
    "announcement_title_1": "Чистый и интуитивно понятный интерфейс",
    "announcement_desc_1": "Мы предлагаем чистый и интуитивно понятный интерфейс, чтобы модели могли эффективно и без сложностей управлять своими объявлениями. Наша платформа разработана так, чтобы быть простой в использовании, обеспечивая легкий и приятный опыт управления рекламой.",
    "announcement_title_2": "Параметры управления рекламой",
    "announcement_desc_2": "Полностью контролируйте свои объявления с нашими параметрами создания, редактирования и удаления. Вы можете создавать новые объявления, редактировать существующую информацию и удалять объявления по мере необходимости, все в одном месте.",
    "announcement_title_3": "Обновление личной информации",
    "announcement_desc_3": "С легкостью поддерживайте актуальность своей информации. С нашей платформой вы можете обновлять личную информацию, такую ​​как фотографии и описание, гарантируя, что ваши клиенты получат самую свежую информацию о ваших услугах.",
    "announcement_title_4": "Выделяйте каждые 45 минут",
    "announcement_desc_4": "Выделяйтесь каждые 45 минут, чтобы максимизировать свою видимость и привлечь больше клиентов. Всего одним щелчком мыши вы можете гарантировать, что ваша реклама будет отображаться на видном месте, выделяясь среди остальных для большего охвата.",
    "announcement_title_5": "Купить выделение",
    "announcement_desc_5": "Увеличьте свою видимость и охват, купив дополнительные выделения для своих объявлений. Мы предлагаем гибкие возможности, чтобы вы могли выбрать план, который наилучшим образом соответствует вашим потребностям и бюджету, гарантируя, что ваш профиль получит заслуженное внимание.",
    "announcement_title_ads": "Рекламируйте здесь",
    "announcement_desc_ads_1": "Присоединяйтесь к нам и наслаждайтесь платформой, посвященной повышению вашей безопасности, видимости и успеха.",
    "announcement_desc_ads_2": "Зарегистрировавшись в LUXEO, вы получите доступ к ряду эксклюзивных преимуществ, включая:",
    "announcement_desc_ads_3": "Не теряйте времени! Зарегистрируйтесь сейчас и начните это успешное путешествие вместе с нами в LUXEO.",
    "announcement_action_ads": "Зарегистрируйтесь сейчас",
    "announcement_section_title1": "Индивидуальный профиль",
    "announcement_section_desc1": "Создайте уникальный и привлекательный профиль, который подчеркнет ваши таланты, услуги и индивидуальность.",
    "announcement_section_title2": "Расширьте свою видимость",
    "announcement_section_desc2": "Охватите более широкую аудиторию и расширьте возможности своего бизнеса, разместив себя в нашем разделе \"Избранные девушки\".",
    "announcement_section_title3": "Простота использования",
    "announcement_section_desc3": "Наша интуитивно понятная и удобная платформа упрощает управление вашим профилем и взаимодействие с потенциальными клиентами.",
    "announcement_section_title4": "Положительные отзывы",
    "announcement_section_desc4": "Получайте ценные отзывы от довольных клиентов и повышайте свою репутацию в сообществе.",
    "announcement_section_title5": "Специальные акции",
    "announcement_section_desc5": "Получите эксклюзивный доступ к специальным предложениям и рекламным мероприятиям для развития вашего бизнеса.",
    "announcement_form_title": "Войти",
    "announcement_form_subtitle": "Я уже рекламодатель",
    "announcement_form_action1": "Зарегистрироваться",
    "announcement_form_action_title": "У меня нет контактов на платформе",
    "announcement_form_action2": "Создать учетную запись",
    "announcement_form_email": "Электронная почта",
    "announcement_form_password": "Пароль",
    "announcement_form_forgot": "Я забыл свой пароль",
    "announcement_form_submit": "Войти",
    "forgot_title": "Я забыл свой пароль",
    "forgot_subtitle": "Введите свой регистрационный адрес электронной почты",
    "forgot_input_email": "Электронная почта",
    "forgot_action": "Отправить ссылку для получения пароля renewal",
    "forgot_success_title": "Письмо успешно отправлено.",
    "forgot_success_text": "Мы отправили вам письмо со ссылкой для обновления пароля. Если вы не можете его найти, проверьте папку со спамом.",
    "create_password_validate1": "Заполните поле нового пароля",
    "create_password_validate2": "Заполните поле подтверждения нового пароля",
    "create_password_validate3": "Пароль и подтверждение пароля не совпадают",
    "create_password_success": "Пароль создан успешно",
    "create_password_title": "Я забыл свой пароль",
    "create_password_text": "Создайте новый пароль",
    "create_password_input_1": "Новый пароль",
    "create_password_input_2": "Подтверждение нового пароля",
    "create_password_input_submit": "Сохранить",
    "purchase_title": "Выделите свой профиль",
    "purchase_subtitle": "Покупка кредитов",
    "purchase_text": "Выберите, сколько кредитов вы хотите купить",
    "purchase_action": "Я хочу купить позже",
    "purchase_selected": "Выбрано",
    "purchase_select": "Выбрать",
    "customer_profile_name": "Имя",
    "customer_profile_user": "Пользователь с",
    "customer_profile_status": "Статус",
    "customer_profile_email": "Электронная почта",
    "customer_profile_subs_type": "Тип подписки",
    "customer_profile_subs_value": "Стоимость подписки",
    "customer_profile_saved": "Сохранено",
    "customer_profile_save": "Сохранить",
    "customer_profile_edit": "Изменить информацию",
    "customer_profile_delete": "Удалить учетную запись",
    "customer_profile_fav": "Эскорты, которые вы добавили в избранное",
    "gallery_video": "Видеогалерея",
    "gallery_image": "Фотогалерея",
    "gallery_load_more": "Загрузить еще",
    "aboutme_contact": "Связаться со мной",
    "aboutme_contact_text": "Я не отвечаю на голосовые звонки",
    "aboutme_contact_some_photos": "Посмотреть некоторые фотографии и видео",
    "aboutme_contact_photos": "Фотографии",
    "aboutme_contact_videos": "Видео",
    "review_service": "Сервис",
    "review_hygiene": "Гигиена",
    "review_socialtime": "Социальное время",
    "review_apparence": "Внешний вид",
    "review_location": "Местоположение",
    "review_comment": "Комментарий",
    "review_fill_all_fields": "Заполните все поля",
    "review_what_customer_saying": "Что говорят некоторые клиенты:",
    "review_left_comment": "Оставил свой комментарий",
    "review_add_your_review": "Добавить свой отзыв",
    "review_sent_review": "Отправленный отзыв",
    "escortservice_success": "Сохранено успешно",
    "escortservice_title": "Свяжитесь со мной",
    "escortservice_text": "Я не отвечаю на голосовые звонки",
    "contact_title": "Связаться",
    "contact_email": "Электронная почта",
    "contact_whats_desc": "Поговорите с нами через WhatsApp.",
    "contact_whats_label": "Нажмите здесь",
    "contact_privacy_title": "Политика конфиденциальности",
    "contact_privacy_desc": "Получите ценные отзывы от довольных клиентов и повысьте свою репутацию в сообществе.",
    "contact_privacy_label": "Доступ к FAQ",
    "contactform_name": "Ваше имя",
    "contactform_email": "Ваш лучший email",
    "contactform_subject": "Тема",
    "contactform_message": "Сообщение",
    "contactform_fill_fields": "Заполните все поля",
    "contactform_invalidmail": "Неверный email",
    "contactform_success": "Контакт успешно отправлен",
    "contactform_title": "Нужна помощь? Будет приятно пообщаться с вами.",
    "contactform_action": "Отправить сообщение",
    "faq_title": "Часто задаваемые вопросы",
    "faq_subtitle": "Часто задаваемые вопросы пользователей об использовании приложения, его функциях и политиках.",
    "faq_text": "Не нашли то, что искали?",
    "faq_subtext": "Свяжитесь с нами.",
    "faq_action": "Отправить сообщение",
    "notfound_title": "Ошибка 404",
    "notfound_text": "Страница, к которой вы пытались получить доступ, не существует или временно недоступна.",
    "notfound_action": "Вернуться на главную",
    "advancedsearch_canton": "Кантон",
    "advancedsearch_age": "Возраст",
    "advancedsearch_services": "Предлагаемые услуги",
    "advancedsearch_category": "Категория",
    "advancedsearch_customerreviews": "Отзывы клиентов",
    "advancedsearch_nationality": "Национальность/этническое происхождение",
    "advancedsearch_lang": "Разговорные языки",
    "advancedsearch_appearance": "Внешний вид",
    "advancedsearch_find": "Найти эскортниц",
    "advancedsearch_title": "Расширенный поиск",
    "terms_title1": "1. Введение",
    "terms_content1": "Страница \"Условия обслуживания\" является неотъемлемой частью нашей платформы, определяя положения и условия, которые регулируют использование пользователями наших услуг. Эта страница устанавливает права и обязанности как пользователей, так и компании, обеспечивая безопасный и прозрачный опыт для всех участников.",
    "terms_title2": "2. Принятие условий",
    "terms_content2": "Мы объясняем, что использование нашей платформы подразумевает автоматическое и обязательное принятие Условий обслуживания.",
    "terms_title3": "3. Описание услуг",
    "terms_content3": "Мы подробно описываем услуги, предлагаемые нашей платформой, включая их функциональные возможности, характеристикиs и ограничения. Это дает пользователям четкое понимание того, чего они могут ожидать при использовании наших услуг.",
    "terms_title4": "4. Обязанности пользователей",
    "terms_content4": "Мы разъясняем обязанности пользователей при использовании нашей платформы, в том числе: предоставление точной и актуальной информации при регистрации, соблюдение прав интеллектуальной собственности, поддержание безопасности учетных данных доступа и ненарушение применимых законов или прав третьих лиц.",
    "terms_title5": "5. Интеллектуальная собственность",
    "terms_content5": "Мы информируем пользователей о владении авторскими правами, товарными знаками и другими правами интеллектуальной собственности, связанными с нашей платформой и ее контентом. Пользователи соглашаются уважать эти права и не использовать наш контент несанкционированным образом.",
    "terms_title6": "6. Политика конфиденциальности",
    "terms_content6": "Мы ссылаемся на нашу политику конфиденциальности и объясняем, как мы обращаемся с информацией пользователей. Пользователям рекомендуется ознакомиться с нашей политикой конфиденциальности, чтобы понять, как собирается, используется и защищается их информация.",
    "terms_title7": "7. Изменения в Условиях обслуживания",
    "terms_content7": "Мы оставляем за собой право изменять или обновлять Условия обслуживания по мере необходимости. Изменения вступают в силу после публикации пересмотренных Условий на нашей платформе.",
    "terms_title8": "8. Ограничение ответственности",
    "terms_content8": "Мы освобождаем компанию от ответственности за прямые, косвенные, случайные, последующие или штрафные убытки, возникшие в результате использования наших услуг. Пользователи соглашаются использовать наши услуги на свой страх и риск.",
    "terms_title9": "9. Прекращение обслуживания",
    "terms_content9": "Мы оставляем за собой право прекратить или приостановить доступ пользователей к нашей платформе без предварительного уведомления в случае нарушения Условий обслуживания.",
    "terms_title10": "10. Общие положения",
    "terms_content10": "Мы включаем положения о применимом праве, юрисдикции, отказе от прав, целостности договора и другие общие положения для обеспечения применимости и эффективности Условий обслуживания.",
    "terms_title11": "11. Контакты",
    "terms_content11": "Мы предоставляем контактную информацию, чтобы пользователи могли связаться с нами по любым вопросам, проблемам или проблемам, касающимся Условий обслуживания.",
    "terms_title": "Условия обслуживания",
    "terms_subtitle": "Внимательно прочитайте условия обслуживания платформы, прежде чем продолжить.",
    "purchasedetails_title": "Сведения о покупке",
    "purchasedetails_text1": "Вы получите свою рекламу",
    "purchasedetails_text2": "По истечении этого периода вы должны повторно активировать свою подписку.",
    "purchasedetails_text3": "Вы можете приостановить свою рекламу в любое время до окончания крайнего срока. Таким образом, кредиты также будут приостановлены, чтобы вы могли использовать их, когда захотите.",
    "purchasedetails_text4": "Стоимость плана",
    "purchasedetails_text_francs": "франки",
    "purchasedetails_text_confirm": "Подтвердите платеж",
    "purchasedetails_text_congratulations1": "Поздравляем. Ваш",
    "purchasedetails_text_congratulations2": "предлагаемый план был успешно активирован!",
    "profilemodal_option1": "Профиль клиента",
    "profilemodal_option2": "Внутренняя страница",
    "profilemodal_option3": "Административный владелец",
    "profilemodal_option4": "Административный эскорт",
    "profilemodal_title": "Выберите тип профиля",
    "privacy_title1": "1. Введение",
    "privacy_conte1": "Цель нашей страницы конфиденциальности — информировать пользователей о том, как мы собираем, используем и защищаем их персональные данные, пока они используют нашу платформу. Конфиденциальность наших пользователей является для нас приоритетом, и на этой странице подробно описываются наши методы обеспечения конфиденциальности в соответствии с действующими законами и правилами.",
    "privacy_title2": "2. Собираемая информация",
    "privacy_conte2": "В этом разделе мы описываем типы персональных данных, которые мы собираем у пользователей, и методы, с помощью которых эта информация получается. Сюда входит, помимо прочего: Информация, предоставленная пользователями при регистрации, такая как имя, адрес электронной почты, дата рождения и т. д. Данные об использовании, такие как история просмотров, взаимодействие с платформой, предпочтения пользователя и т. д. Информация, собираемая автоматически с помощью файлов cookie и аналогичных технологий.",
    "privacy_title3": "3. Использование информации",
    "privacy_conte3": "Мы объясняем, как персональные данные пользователей используются нашей платформой. Это может включать: Персонализацию пользовательского опыта и предоставление соответствующего контента. Постоянное совершенствование наших услуг и функций. Информирование пользователей об обновлениях, специальных предложениях и т. д. Соблюдение правовых и нормативных обязательств.",
    "privacy_title4": "4. Обмен информацией",
    "privacy_conte4": "Мы подробно рассказываем, с кем мы делимся информацией о пользователях и о причинах такого обмена. Это может касаться: Сервисных партнеров, которые помогают нам предоставлять наши услуги. Юридических органов, когда это требуется по закону или в ответ на официальные запросы. Третьих лиц в случае слияния, приобретения или продажи активов.",
    "privacy_title5": "5. Защита информации",
    "privacy_conte5": "Мы объясняем меры безопасности, которые мы применяем для защиты информации пользователей от несанкционированного доступа, неправомерного использования, изменения или раскрытия. Это может включать: Шифрование данных. Контроль доступа. Регулярный мониторинг систем и сетей.",
    "privacy_title6": "6. Права пользователя",
    "privacy_conte6": "Мы информируем пользователей об их правах в отношении их личной информации, включая право на доступ, исправление, удаление или ограничение использования своих данных. Мы также предоставляем информацию о том, как пользователи могут осуществлять эти права.",
    "privacy_title7": "7. Изменения в Политике конфиденциальности",
    "privacy_conte7": "Мы оставляем за собой право обновлять или изменять нашу Политику конфиденциальности по мере необходимости. Любые существенные изменения будут сообщены пользователям через нашу платформу или другими соответствующими способами.",
    "privacy_title8": "8. Контакты",
    "privacy_conte8": "Мы предоставляем контактную информацию, чтобы пользователи могли связаться с нами, если у них возникнут какие-либо вопросы, опасения или запросы, связанные с конфиденциальностью.",
    "privacy_title9": "9. Дата вступления в силу",
    "privacy_conte9": "Мы указываем дату вступления в силу нашей политики конфиденциальности и информируем пользователей о важности регулярного просмотра этой страницы, чтобы оставаться в курсе наших правил конфиденциальности.",
    "privacy_title10": "10. Соблюдение правовых норм",
    "privacy_conte10": "Мы заявляем о своей приверженности соблюдению действующих законов и правил о защите данных и информируем пользователей об их правах и средствах правовой защиты в случае нарушения конфиденциальности.",
    "privacy_title": "Политика конфиденциальности",
    "privacy_subtitle": "Внимательно прочтите условия использования платформы, прежде чем продолжить.",
    "deleteaccount_success": "Аккаунт успешно удален",
    "deleteaccount_title": "Вы собираетесь окончательно удалить свой аккаунт.",
    "deleteaccount_text": "Если вы решили не удалять свой аккаунт в данный момент, вы можете просто закрыть эту страницу и продолжить пользоваться нашей платформой как обычно. Если у вас есть вопросы или вам нужна помощь, не стесняйтесь обращаться в нашу службу поддержки. Мы здесь, чтобы помочь!",
    "deleteaccount_confirm": "Подтвердите удаление учетной записи",
    "deleteaccount_cancel": "Я не хочу удалять",
    "deleteaccount_success_title": "Ваша учетная запись успешно удалена.",
    "deleteaccount_success_text": "Было приятно, что вы провели это время с нами. Помните, что мы всегда рады вам. Мы будем здесь, чтобы помочь вам.",
    "ageverification_title": "Проверка возраста",
    "ageverification_text1": "Этот веб-сайт содержит контент, предназначенный только для лиц старше 18 лет. Нажимая «Подтвердить», вы подтверждаете, что достигли совершеннолетия в соответствии с законами вашей юрисдикции. Если вы не достигли требуемого минимального возраста, немедленно покиньте этот сайт.",
    "ageverification_text2": "Ложное подтверждение возраста является нарушением условий использования этого сайта.",
    "ageverification_text3": "Вам больше 18 лет",
    "ageverification_text4": "лет?",
    "ageverification_yes": "Да",
    "ageverification_no": "Нет",
    "ageverification_policy": "Политика конфиденциальности",
    "ageverification_terms": "Условия использования",
    "escortinfo_reviews": "Отзывы, оставленные клиентами",
    "follower": "Подписчик",
    "unfollow": "Подписаться",
    "imagesuccessfully": "Изображение успешно загружено",
    "admin_side_option1": "Главная",
    "admin_side_option2": "Обо мне",
    "admin_side_option3": "Фотографии",
    "admin_side_option4": "Видео",
    "admin_side_option5": "Рейтинги",
    "admin_side_option6": "Услуги",
    "admin_side_option7": "Реклама",
    "admin_side_option8": "Дополнительные кредиты",
    "admin_side_option9": "Планы",
    "admin_side_option10": "Статистика",
    "admin_side_option11": "Поддержка",
    "admin_side_option12": "Главная",
    "admin_side_option13": "Пользователи",
    "admin_side_option14": "Эскорт",
    "admin_side_option15": "Отчеты",
    "admin_sidefooter_option1": "Удалить учетную запись",
    "admin_sidefooter_option2": "Выход",
    "admin_dashboard_title1": "Избранное",
    "admin_dashboard_subtitle1": "Пользователи, которые следят за вами",
    "admin_dashboard_title2": "Клики по рекламе",
    "admin_dashboard_subtitle2": "Последние 30 дней",
    "admin_dashboard_title3": "Ваши кредиты",
    "admin_dashboard_subtitle3": "Кредиты закончились",
    "admin_dashboard_title4": "Статус подписки",
    "admin_dashboard_subtitle4": "Почти истекает",
    "admin_dashboard_credit": "кредит",
    "admin_dashboard_announcement": "credAnnouncementit",
    "admin_dashboard_visit": "Посещения вашего профиля",
    "admin_dashboard_visits": "посещения",
    "admin_dashboard_activead": "активное объявление",
    "admin_dashboard_aboutme_success": "Обо мне успешно обновлено",
    "admin_dashboard_aboutme_title": "Обо мне",
    "admin_dashboard_aboutme_phone": "Телефон",
    "admin_dashboard_aboutme_whats": "Whatsapp",
    "admin_dashboard_aboutme_telegram": "Telegram",
    "admin_dashboard_aboutme_size": "Размер",
    "admin_dashboard_aboutme_weight": "Вес",
    "admin_dashboard_aboutme_nationality": "Нацияnality",
    "admin_dashboard_aboutme_haircolor": "Цвет волос",
    "admin_dashboard_aboutme_breasts": "Грудь",
    "admin_dashboard_aboutme_eyecolor": "Цвет глаз",
    "admin_dashboard_aboutme_gender": "Предпочтение (пол)",
    "admin_dashboard_credits_credit": "кредит",
    "admin_dashboard_credits_franc": "франки",
    "admin_dashboard_credits_item1_title": "Как работает подписка?",
    "admin_dashboard_credits_item1_subtitle": "Вы можете подписаться на определенное время, чтобы быть представленными на платформе.",
    "admin_dashboard_credits_item1_button": "30 дней",
    "admin_dashboard_credits_item1_footer": "По истечении этого срока вам необходимо продлить подписку, чтобы ваши объявления были выделены на платформе и увеличить шансы быть нанятым пользователями.",
    "admin_dashboard_credits_item2_title": "Кредиты и периоды",
    "admin_dashboard_credits_item2_subtitle": "За каждый день показа рекламы вам понадобится 1 кредит.",
    "admin_dashboard_credits_item2_footer": "За каждый день показа рекламы вам понадобится 1 кредит.",
    "admin_dashboard_credits_item3_title": "Покупка кредитов",
    "admin_dashboard_credits_item3_subtitle": "Выберите, сколько кредитов вы хотите купить.",
    "admin_dashboard_credits_title": "Ваши кредиты",
    "admin_dashboard_credits_subtitle": "Ваши кредиты истекли. Вам необходимо купить больше кредитов, чтобы быть представленным на платформе.",
    "admin_dashboard_plans1_title": "Базовый план",
    "admin_dashboard_plans1_subtitle": "Представлено на 5 дней",
    "admin_dashboard_plans1_text": "Ваша реклама может быть представлена ​​на 5 дней.",
    "admin_dashboard_plans1_label": "Выбрать план",
    "admin_dashboard_plans2_title": "Бронзовый план",
    "admin_dashboard_plans2_subtitle": "Представлено на 15 дней",
    "admin_dashboard_plans2_text": "Ваша реклама может быть представлена ​​на 2 недели.",
    "admin_dashboard_plans2_label": "Выбрать план",
    "admin_dashboard_plans3_title": "Премиум-план",
    "admin_dashboard_plans3_subtitle": "Представлено на 30 дней",
    "admin_dashboard_plans3_text": "Вы можете разместить свою рекламу на 1 месяц",
    "admin_dashboard_plans3_label": "Выбрать план",
    "admin_dashboard_plans_title": "Ваши кредиты",
    "admin_dashboard_plans_subtitle": "Ваши кредиты истекли. Вам нужно купить больше кредитов, чтобы быть представленным на платформе.",
    "admin_dashboard_plans_choose": "Выбрать план",
    "admin_dashboard_plans_buymore": "Купить больше кредитов",
    "admin_dashboard_stats_12": "12 месяцев",
    "admin_dashboard_stats_6": "6 месяцев",
    "admin_dashboard_stats_30": "30 дней",
    "admin_dashboard_stats_7": "7 дней",
    "admin_dashboard_opt1_title": "Добавлено в избранное",
    "admin_dashboard_opt1_text": "дополнения",
    "admin_dashboard_opt2_title": "Нажатия на кнопку Whatsapp",
    "admin_dashboard_opt2_text": "клики",
    "admin_dashboard_sup_subject": "Тема",
    "admin_dashboard_sup_message": "Сообщение",
    "admin_dashboard_sup_fill": "Заполните все поля",
    "admin_dashboard_sup_success": "Поддержка успешно отправлена",
    "admin_dashboard_sup_title": "Если у вас есть вопросы, наша служба поддержки будет рада ответить.",
    "admin_dashboard_sup_action": "Отправить сообщение",
    "admin_dashboard_supfooter_email": "Электронная почта",
    "admin_dashboard_supfooter_phone": "Телефон",
    "admin_dashboard_supfooter_item1_title": "Часто задаваемые вопросы",
    "admin_dashboard_supfooter_item1_subtitle": "Мы выделили некоторые вопросы, которые запрашиваются здесь.",
    "admin_dashboard_supfooter_item1_label": "Доступ к часто задаваемым вопросам",
    "admin_dashboard_supfooter_item2_title": "Политика конфиденциальности",
    "admin_dashboard_supfooter_item2_subtitle": "Узнайте больше о политике конфиденциальности.",
    "admin_dashboard_supfooter_item2_label": "Доступ",
    "admin_dashboard_supfooter_item3_title": "Условия обслуживания",
    "admin_dashboard_supfooter_item3_subtitle": "Узнайте больше о условиях обслуживания.",
    "admin_dashboard_supfooter_item3_label": "Доступ",
    "admin_dashboard_delete_text": "Если вы решили не продолжать пользоваться нашей платформой, мы предлагаем возможность окончательно удалить вашу учетную запись. Этот процесс необратим и приведет к полному удалению всей информации, связанной с вашей учетной записью, включая ваш профиль, историю активности и персональные данные. Обратите внимание, что при удалении учетной записи вы потеряете доступ ко всем функциям и услугам, предлагаемым платформой. Кроме того, любые ранее выполненные транзакции, сообщения или взаимодействия будут удалены навсегда и не могут быть восстановлены. Если вы уверены, что хотите продолжить удаление учетной записи, подтвердите свое решение ниже. После подтверждения ваша учетная запись будет удалена навсегда, и вы автоматически выйдете из платформы",
    "admin_dashboard_delete_title": "Что вам нужно знать перед удалением учетной записи?",
    "admin_dashboard_delete_action": "Удалить мою учетную запись",
    "what_are_you_looking_for": "Чегоt вы ищете?",
    "admin_dashboardowner_item1_title": "Новые пользователи",
    "admin_dashboardowner_item1_subtitle": "Последние 30 дней",
    "admin_dashboardowner_item1_legend": "новые пользователи",
    "admin_dashboardowner_item2_title": "Новый эскорт",
    "admin_dashboardowner_item2_subtitle": "Последние 30 дней",
    "admin_dashboardowner_item2_legend": "новые модели",
    "admin_dashboardowner_item3_title": "Посещения веб-сайта",
    "admin_dashboardowner_item3_subtitle": "Последние 30 дней",
    "admin_dashboardowner_item4_title": "Способы оплаты",
    "admin_dashboardowner_item4_subtitle": "Способ, используемый вашими клиентами",
    "admin_dashboardowner_item4_value": "stripe",
    "admin_dashboardowner_escortcontrol_title": "Эскорты для одобрения",
    "admin_dashboardowner_escortcontrol_subtitle": "Убедитесь, что модель соответствует всем политикам платформы.",
    "admin_dashboardowner_escortcontrol_seeall": "Просмотреть все запросы",
    "admin_dashboardowner_escortcontrol_approve": "Одобрить",
    "admin_dashboardowner_escortcontrol_reject": "Отклонить",
    "admin_dashboardowner_escortcontrol_seemore": "Посмотреть больше",
    "admin_dashboardowner_top_title": "Лучшие эскорты",
    "admin_dashboardowner_top_subtitle": "Последние 30 дней",
    "admin_dashboardowner_top_complete": "Полный рейтинг",
    "admin_dashboardowner_older": "Старые",
    "admin_dashboardowner_newest": "Самые новые",
    "admin_dashboardowner_user": "Активные пользователи",
    "admin_dashboardowner_deleted": "Удалил ваши аккаунты",
    "admin_dashboardowner_platformuser": "Пользователи платформы",
    "admin_dashboardowner_usersince": "Пользователь с",
    "admin_dashboardowner_lastaccess": "Последний доступ",
    "admin_dashboardowner_selectperiod": "Выберите период",
    "admin_dashboardowner_sortby": "Сортировать по",
    "admin_dashboardowner_newuser": "новые пользователи",
    "admin_dashboardowner_newescorts": "новые эскорты",
    "admin_dashboardowner_newmodels": "новые модели",
    "admin_dashboardowner_modelstitle": "Модели",
    "admin_dashboardowner_userstitle": "Пользователи",
    "admin_dashboardowner_modelranking": "Рейтинг моделей",
    "admin_dashboardowner_busiestaccess": "Самое загруженное время доступа на платформе",
    "admin_dashboardowner_hits": "обращений",
    "admin_dashboardowner_accessesperiod": "Обращений по периодам",
    "admin_dashboardowner_models": "моделей",
    "admin_dashboardowner_activeuser": "активных пользователей",
    "admin_dashboardowner_deletedaccount": "Удалил ваши учетные записи",
    "admin_dashboardowner_user_name": "Имя",
    "admin_dashboardowner_user_email": "Электронная почта",
    "admin_dashboardowner_user_phone": "Телефон",
    "admin_dashboardowner_user_whatsapp": "Whatsapp",
    "admin_dashboardowner_user_telegram": "Telegram",
    "admin_dashboardowner_user_usersince": "Пользователь с",
    "admin_dashboardowner_user_status": "Статус",
    "admin_dashboardowner_user_active": "Активен",
    "admin_dashboardowner_user_inactive": "Неактивен",
    "admin_dashboardowner_user_lastaccess": "Последний доступ",
    "admin_dashboardowner_user_modify": "Изменить информацию",
    "admin_dashboardowner_user_save": "Сохранить",
    "admin_dashboardowner_user_redefine": "Переопределить пароль",
    "admin_dashboardowner_user_suspend": "Приостановить действие аккаунта",
    "admin_dashboardowner_user_sendemail": "Отправить письмо",
    "admin_dashboardowner_user_success": "Обновлено успешно",
    "admin_dashboardowner_user_successsuspend": "Приостановлено успешно",
    "admin_dashboardowner_user_successreset": "Отправлено письмо со сбросом пароля",
    "admin_dashboardowner_user_back": "Назад",
    "admin_dashboardowner_user_actions": "Действия администратора",
    "admin_dashboardowner_escorts_it1_title": "Активные модели",
    "admin_dashboardowner_escorts_it2_title": "Удалил ваши учетные записи",
    "admin_dashboardowner_escorts_it3_title": "Запрещены эскорты",
    "admin_dashboardowner_escorts_col1_title": "Пользователи платформы",
    "admin_dashboardowner_escorts_col2_title": "Пользователь с",
    "admin_dashboardowner_escorts_col3_title": "Последний доступ",
    "admin_dashboardowner_escorts_col4_title": "Дата удаления аккаунта",
    "admin_dashboardowner_escorts_col5_title": "Дата блокировки",
    "admin_dashboardowner_loadmore": "Загрузить еще",
    "availability_hours": "Часы доступности",
    "price": "Цена",
    "where_do_you_serve": "Где вы обслуживаете",
    "availability": "Доступность",
    "duration": "Длительность",
    "value": "Значение",
    "location": "Местоположение",
    "about_me": "Обо мне",
    "service": "Услуга",
    "hygiene": "Гигиена",
    "socialtime": "Социальное время",
    "nodata": "Дата недоступна",
    "nocomment": "Нет доступных комментариев.",
    "annon": "Анонимный",
    "noreview": "Нет доступных отзывов.",
    "addverification": "Добавить значок подтверждения",
    "removeerification": "Удалить значок подтверждения",
    "admin_dashboardowner_morning": "Утро",
    "admin_dashboardowner_afternoon": "День",
    "admin_dashboardowner_night": "Ночь",
    "admin_dashboardowner_early_morning": "Раннее утро",
    "admin_dashboardowner_activeescorts": "Aактивные эскорты",
    "admin_dashboardowner_activeusers": "Активные пользователи",
    "admin_dashboardowner_bannedescorts": "Запрещенные эскорты",
    "admin_dashboardowner_bannedusers": "Запрещенные пользователи",
    "admin_dashboardowner_visitprofile": "Посетить профиль",
    "city_state": "Город-штат",
    "posts": "posts",
    "videos": "видео",
    "likes": "likes",
    "comments": "комментарии",
}