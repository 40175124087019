export const static_translation = {
    "dexsire_is_respect": "Dexsire è rispetto",
    "safety_and_dignity": "sicurezza e dignità",
    "register_right_now": "Registrati subito.",
    "register": "Registrati",
    "our_escorts": "Le nostre escort",
    "discover_some_models_available_to_you": "Scopri alcune modelle disponibili per te",
    "load_more": "Carica altro",
    "connecting_you_to_the_highest_level_of_experience": "Ti mettiamo in contatto con il livello di esperienza più elevato.",
    "find_escorts_by_region": "Trova escort per regione",
    "contact_us": "Contattaci",
    "frequently_asked_questions": "Domande frequenti",
    "copyright_2024_dexsire": "© Copyright 2024 Dexsire, Svizzera. Tutti i diritti riservati.",
    "privacy_policy": "Informativa sulla privacy",
    "terms_of_service": "Termini di servizio",
    "and": "e",
    "service_terms": "Termini di servizio",
    "canton":"Canton",
    "ville":"Ville",
    "services_offered":"Servizi offerti",
    "category":"Categoria",
    "clear_filter":"Cancella filtro",
    "find_escorts":"Trova escort",
    "find_the_ideal_model":"Trova la modella ideale per te",
    "login_register": "Accedi/Registrati",
    "announcement": "Annuncio",
    "my_profile": "Il mio profilo",
    "exit": "Esci",
    "home": "Home",
    "advanced_search": "ricerca avanzata",
    "advertise_here": "Pubblicizza qui",
    "contact": "Contatto",
    "im_a_escorts": "Sono una escort",
    "im_a_customer": "Sono un cliente",
    "i_already_have_registration": "Ho già la registrazione",
    "i_want_to_sign_up": "Voglio registrarmi",
    "login": "Accedi",
    "enter_your_username_and_password": "Inserisci il tuo nome utente e la tua password.",
    "i_forgot_my_password": "Ho dimenticato la mia password",
    "to_enter": "Per entrare",
    "email": "E-mail",
    "password": "Password",
    "fill_email_field": "Compila il campo e-mail",
    "fill_name_field": "Compila il campo nome",
    "fill_password_field": "Compila il campo password",
    "fill_360_video": "Compila il video a 360 gradi",
    "minimum_4_photos": "Minimo 4 foto",
    "fill_pseudo_field": "Riempi campo Pseudo",
    "fill_category_field": "Riempi campo Categoria",
    "fill_bio_field": "Riempi campo Biografia",
    "fill_age_field": "Riempi campo Età",
    "your_age_must_be_over_18": "La tua età deve essere superiore a 18 anni",
    "fill_phone_field": "Riempi campo Telefono",
    "fill_canton_field": "Riempi campo Canton",
    "fill_ville_field": "Riempi campo Ville",
    "fill_nationality_field": "Riempi campo Nazionalità",
    "fill_size_field": "Riempi campo Taglia",
    "fill_weight_field": "Riempi campo Peso",
    "fill_hair_color_field": "Riempi campo Colore Capelli",
    "fill_breasts_field": "Riempi campo Seno",
    "fill_eyes_color_field": "Riempi campo Colore Occhi",
    "select_at_least_one_mobility_option": "Seleziona almeno un'opzione di mobilità",
    "select_at_least_one_payment_option": "Seleziona almeno un'opzione di pagamento",
    "select_at_least_one_language_option": "Seleziona almeno un'opzione di lingua",
    "fill_in_your_details": "Inserisci i tuoi dati",
    "user_registration": "Registrazione utente",
    "plan": "Piano",
    "select_a_plan": "Seleziona un piano",
    "personal_data": "Dati personali",
    "profile": "Profilo",
    "appearance": "Aspetto",
    "payment": "Pagamento",
    "model_registration": "Registrazione modello",
    "buy_highlights": "Acquista punti salienti",
    "fill_in_your_profile": "Inserisci il tuo profilo",
    "name":"Nome",
    "confirm_password":"Conferma password",
    "fill_all_fields":"Inserisci tutti i campi",
    "invalid_email":"Email non valida",
    "password_and_confirmation_not_match":"Password e conferma non corrispondono",
    "account_successfully_registred":"Account registrato con successo!",
    "registration_completed_successfully":"Registrazione completata con successo",
    "you_must_verify":"Devi verificare la tua email per attivare il tuo account. Se non lo trovi nella posta in arrivo, controlla la cartella spam.",
    "take_the_opportunity":"Cogli l'occasione per mettere in risalto il tuo profilo, investi in crediti per rimanere al top.",
    "want_to_buy_later":"Vuoi acquistare più tardi",
    "i_want_to_buy_credits_now":"Voglio acquistare crediti ora",
    "advance":"Avanzata",
    "now_its_time_to_report_your_appearance":"Ora è il momento di segnalare il tuo aspetto",
    "verification_photo":"Foto di verifica",
    "exemple":"Esempio",
    "send_a_photo_holding":"invia una foto in cui tieni in mano un foglio con scritto dessire.com",
    "drag_the_image_here_or_click_here":"Trascina l'immagine qui o clicca qui",
    "i_agree_with_the_platform":"Accetto le condizioni della piattaforma",
    "accept_terms_to_continue":"Accetta i termini per continuare",
    "pseudo": "Pseudo",
    "bio": "Bio",
    "age": "Età",
    "phone": "Telefono",
    "nationality": "Nazionalità",
    "size": "Taglia",
    "weight": "Peso",
    "height": "Altezza",
    "language": "Lingua",
    "hair_color": "Colore dei capelli",
    "breasts": "Seno",
    "eye_color": "Colore degli occhi",
    "mobility": "Mobilità",
    "payments_accepted": "Pagamenti accettati",
    "languages_spoken": "Lingue parlate",
    "what_services_do_you_offer": "Quali servizi offri?",
    "describe_yourself_below": "Descriviti qui sotto:",
    "note": "Nota:",
    "the_file_is_too_long": "Il file è troppo grande. La dimensione massima è 50 MB.",
    "upload_a_360": "Carica un video a 360º",
    "instruction_on_how": "Istruzioni su come registrare un video a 360º",
    "drag_video": "Trascina il video qui o clicca qui",
    "supported_video": "File supportati: MP4 o AVI",
    "maximum_video": "Dimensione massima del file: 50 MB",
    "upload": "Carica",
    "drag_image": "Trascina l'immagine qui o clicca qui",
    "supported_image": "File supportati: JPG e PNG",
    "maximum_image": "Dimensione massima del file: 8 MB. Minimo 4 foto.",
    "the_image_is_too_big": "Il file è troppo grande. La dimensione massima è 8 MB.",
    "in_addition": "Oltre all'evidenziazione periodica inclusa come parte delle funzionalità del pannello di controllo, avrai anche la possibilità di acquistare ulteriori evidenziazioni. Ciò ti consentirà di mostrare i tuoi annunci più frequentemente o per periodi di tempo più lunghi, aumentando ulteriormente la tua esposizione sulla piattaforma.",
    "license": "Licenza",
    "license_5days": "5 giorni - Licenza",
    "license_5days_price": "89,90 franchi",
    "payment_via": "Il pagamento per il tuo abbonamento verrà effettuato tramite",
    "continue": "Continua",
    "choose_plan": "Scegli il piano",
    "announcement_title_1": "Interfaccia pulita e intuitiva",
    "announcement_desc_1": "Offriamo un'interfaccia pulita e intuitiva in modo che i modelli possano gestire i loro annunci in modo efficiente e senza complicazioni. La nostra piattaforma è progettata per essere facile da usare, offrendo un'esperienza di gestione degli annunci piacevole e senza stress.",
    "announcement_title_2": "Opzioni di gestione degli annunci",
    "announcement_desc_2": "Avere il pieno controllo sui tuoi annunci con le nostre opzioni di creazione, modifica ed eliminazione. Puoi creare nuovi annunci, modificare le informazioni esistenti e rimuovere gli annunci quando necessario, tutto in un unico posto.",
    "announcement_title_3": "Aggiornamento delle informazioni personali",
    "announcement_desc_3": "Mantieni le tue informazioni aggiornate con facilità. Con la nostra piattaforma, puoi aggiornare le informazioni personali come foto e descrizione, assicurandoti che i tuoi clienti ricevano le ultime informazioni sui tuoi servizi.",
    "announcement_title_4": "Evidenzia ogni 45 minuti",
    "announcement_desc_4": "Distinguiti ogni 45 minuti per massimizzare la tua visibilità e attrarre più clienti. Con un solo clic, puoi assicurarti che il tuo annuncio venga visualizzato in modo prominente, distinguendosi dagli altri per una maggiore esposizione.",
    "announcement_title_5": "Acquista evidenziazioni",
    "announcement_desc_5": "Aumenta la tua visibilità e portata acquistando evidenziazioni aggiuntive per i tuoi annunci. Offriamo opzioni flessibili in modo che tu possa scegliere il piano più adatto alle tue esigenze e al tuo budget, assicurandoti che il tuo profilo riceva l'attenzione che merita.",
    "announcement_title_ads": "Pubblicizza qui",
    "announcement_desc_ads_1": "Unisciti a noi e goditi una piattaforma dedicata alla promozione della tua sicurezza, visibilità e successo.",
    "announcement_desc_ads_2": "Quando ti registri con LUXEO, avrai accesso a una serie di vantaggi esclusivi, tra cui:",
    "announcement_desc_ads_3": "Non perdere tempo! Registrati ora e intraprendi questo viaggio di successo con noi su LUXEO.",
    "announcement_action_ads": "Registrati ora",
    "announcement_section_title1": "Profilo personalizzato",
    "announcement_section_desc1": "Crea un profilo unico e attraente che evidenzi i tuoi talenti, servizi e personalità.",
    "announcement_section_title2": "Aumenta la tua visibilità",
    "announcement_section_desc2": "Raggiungi un pubblico più ampio e aumenta le tue opportunità di business comparendo nella nostra sezione \"Ragazze in evidenza\".",
    "announcement_section_title3": "Facilità d'uso",
    "announcement_section_desc3": "La nostra piattaforma intuitiva e intuitiva semplifica la gestione del tuo profilo e l'interazione con potenziali clienti.",
    "announcement_section_title4": "Recensioni positive",
    "announcement_section_desc4": "Ricevi feedback preziosi da clienti soddisfatti e aumenta la tua reputazione nella community.",
    "announcement_section_title5": "Promozioni speciali",
    "announcement_section_desc5": "Ottieni l'accesso esclusivo a offerte speciali ed eventi promozionali per incrementare la tua attività.",
    "announcement_form_title": "Accedi",
    "announcement_form_subtitle": "Sono già un inserzionista",
    "announcement_form_action1": "Registrati",
    "announcement_form_action_title": "Non ho contatti sulla piattaforma",
    "announcement_form_action2": "Crea un account",
    "announcement_form_email": "E-mail",
    "announcement_form_password": "Password",
    "announcement_form_forgot": "Ho dimenticato la password",
    "announcement_form_submit": "Accedi",
    "forgot_title": "Ho dimenticato la password",
    "forgot_subtitle": "Inserisci la tua e-mail di registrazione",
    "forgot_input_email": "Email",
    "forgot_action": "Invia collegamento per password renewal",
    "forgot_success_title": "Email inviata correttamente.",
    "forgot_success_text": "Ti abbiamo inviato un'email con un link per rinnovare la tua password. Se non riesci a trovarlo, controlla la cartella spam.",
    "create_password_validate1": "Compila il campo nuova password",
    "create_password_validate2": "Compila il campo di conferma nuova password",
    "create_password_validate3": "Password e conferma password non corrispondono",
    "create_password_success": "Password creata correttamente",
    "create_password_title": "Ho dimenticato la password",
    "create_password_text": "Crea una nuova password",
    "create_password_input_1": "Nuova password",
    "create_password_input_2": "Conferma nuova password",
    "create_password_input_submit": "Salva",
    "purchase_title": "Evidenzia il tuo profilo",
    "purchase_subtitle": "Acquisto di crediti",
    "purchase_text": "Seleziona quanti crediti vuoi acquistare",
    "purchase_action": "Voglio acquistare later",
    "purchase_selected": "Selezionato",
    "purchase_select": "Seleziona",
    "customer_profile_name": "Nome",
    "customer_profile_user": "Utente da",
    "customer_profile_status": "Stato",
    "customer_profile_email": "E-mail",
    "customer_profile_subs_type": "Tipo di abbonamento",
    "customer_profile_subs_value": "Valore dell'abbonamento",
    "customer_profile_saved": "Salvato",
    "customer_profile_save": "Salva",
    "customer_profile_edit": "Modifica informazioni",
    "customer_profile_delete": "Elimina account",
    "customer_profile_fav": "Escort che hai aggiunto ai preferiti",
    "gallery_video": "Galleria video",
    "gallery_image": "Galleria fotografica",
    "gallery_load_more": "Carica altro",
    "aboutme_contact": "Contattami",
    "aboutme_contact_text": "Non rispondo alle chiamate vocali",
    "aboutme_contact_some_photos": "Guarda alcune foto e video",
    "aboutme_contact_photos": "Foto",
    "aboutme_contact_videos": "Video",
    "review_service": "Servizio",
    "review_hygiene": "Igiene",
    "review_socialtime": "Tempo sociale",
    "review_apparence": "Aspetto",
    "review_location": "Posizione",
    "review_comment": "Commento",
    "review_fill_all_fields": "Compila tutti i campi",
    "review_what_customer_saying": "Cosa dicono alcuni clienti:",
    "review_left_comment": "Hai lasciato il tuo commento",
    "review_add_your_review": "Aggiungi la tua recensione",
    "review_sent_review": "Recensione inviata",
    "escortservice_success": "Salvato correttamente",
    "escortservice_title": "Contattami",
    "escortservice_text": "Non rispondo alle chiamate vocali",
    "contact_title": "Contatta",
    "contact_email": "E-mail",
    "contact_whats_desc": "Parla con noi tramite WhatsApp.",
    "contact_whats_label": "Clicca qui",
    "contact_privacy_title": "Informativa sulla privacy",
    "contact_privacy_desc": "Ricevi feedback preziosi da clienti soddisfatti e aumenta la tua reputazione nella community.",
    "contact_privacy_label": "Accedi alle FAQ",
    "contactform_name": "Il tuo nome",
    "contactform_email": "La tua migliore e-mail",
    "contactform_subject": "Oggetto",
    "contactform_message": "Messaggio",
    "contactform_fill_fields": "Compila tutti i campi",
    "contactform_invalidmail": "E-mail non valida",
    "contactform_success": "Contatto inviato con successo",
    "contactform_title": "Hai bisogno di aiuto? Sarà un piacere parlare con te.",
    "contactform_action": "Invia messaggio",
    "faq_title": "Domande frequenti",
    "faq_subtitle": "Domande frequenti degli utenti sull'utilizzo dell'applicazione, sulle sue funzionalità e policy.",
    "faq_text": "Non hai trovato quello che cercavi?",
    "faq_subtext": "Contattaci.",
    "faq_action": "Invia messaggio",
    "notfound_title": "Errore 404",
    "notfound_text": "La pagina a cui hai provato ad accedere non esiste o è temporaneamente inattiva.",
    "notfound_action": "Torna alla home page",
    "advancedsearch_canton": "Canton",
    "advancedsearch_age": "Età",
    "advancedsearch_services": "Servizi offerti",
    "advancedsearch_category": "Categoria",
    "advancedsearch_customerreviews": "Recensioni dei clienti",
    "advancedsearch_nationality": "Nazionalità/Origine etnica",
    "advancedsearch_lang": "Lingue parlate",
    "advancedsearch_appearance": "Aspetto fisico",
    "advancedsearch_find": "Trova escort",
    "advancedsearch_title": "Ricerca avanzata",
    "terms_title1": "1. Introduzione",
    "terms_content1": "La pagina Termini di servizio è una parte essenziale della nostra piattaforma, che definisce i termini e le condizioni che regolano l'utilizzo dei nostri servizi da parte degli utenti. Questa pagina stabilisce i diritti e le responsabilità sia degli utenti che dell'azienda, garantendo un'esperienza sicura e trasparente per tutti i soggetti coinvolti.",
    "terms_title2": "2. Accettazione dei termini",
    "terms_content2": "Spieghiamo che l'utilizzo della nostra piattaforma implica l'accettazione automatica e vincolante dei Termini di servizio.",
    "terms_title3": "3. Descrizione dei servizi",
    "terms_content3": "Descriviamo in dettaglio i servizi offerti dalla nostra piattaforma, incluse le loro funzionalità, caratteristiches e limitazioni. Ciò fornisce agli utenti una chiara comprensione di cosa possono aspettarsi quando utilizzano i nostri servizi.",
    "terms_title4": "4. Responsabilità dell'utente",
    "terms_content4": "Chiariamo le responsabilità degli utenti quando utilizzano la nostra piattaforma, tra cui: fornire informazioni accurate e aggiornate durante la registrazione, rispettare i diritti di proprietà intellettuale, mantenere la sicurezza delle credenziali di accesso e non violare le leggi applicabili o i diritti di terze parti.",
    "terms_title5": "5. Proprietà intellettuale",
    "terms_content5": "Informiamo gli utenti sulla titolarità di copyright, marchi commerciali e altri diritti di proprietà intellettuale relativi alla nostra piattaforma e ai suoi contenuti. Gli utenti accettano di rispettare tali diritti e di non utilizzare i nostri contenuti in modo non autorizzato.",
    "terms_title6": "6. Informativa sulla privacy",
    "terms_content6": "Facciamo riferimento alla nostra informativa sulla privacy e spieghiamo come trattiamo le informazioni degli utenti. Gli utenti sono invitati a rivedere la nostra politica sulla privacy per comprendere come le loro informazioni vengono raccolte, utilizzate e protette.",
    "terms_title7": "7. Modifiche ai Termini di servizio",
    "terms_content7": "Ci riserviamo il diritto di modificare o aggiornare i Termini di servizio se necessario. Le modifiche diventano effettive al momento della pubblicazione dei Termini rivisti sulla nostra piattaforma.",
    "terms_title8": "8. Limitazione di responsabilità",
    "terms_content8": "Esoneriamo la società da responsabilità per danni diretti, indiretti, incidentali, consequenziali o punitivi derivanti dall'uso dei nostri servizi. Gli utenti accettano di utilizzare i nostri servizi a proprio rischio.",
    "terms_title9": "9. Cessazione del servizio",
    "terms_content9": "Ci riserviamo il diritto di terminare o sospendere l'accesso degli utenti alla nostra piattaforma, senza preavviso, in caso di violazione dei Termini di servizio.",
    "terms_title10": "10. Disposizioni generali",
    "terms_content10": "Includiamo disposizioni sulla legge applicabile, giurisdizione, rinuncia ai diritti, integrità del contratto e altre disposizioni generali per garantire l'applicabilità e l'efficacia dei Termini di servizio.",
    "terms_title11": "11. Contatto",
    "terms_content11": "Forniamo informazioni di contatto in modo che gli utenti possano contattarci per qualsiasi domanda, dubbio o problema riguardante i Termini di servizio.",
    "terms_title": "Termini di servizio",
    "terms_subtitle": "Leggere attentamente i termini di servizio della piattaforma prima di procedere.",
    "purchasedetails_title": "Dettagli di acquisto",
    "purchasedetails_text1": "Avrai il tuo annuncio",
    "purchasedetails_text2": "Dopo questo periodo, devi riattivare il tuo abbonamento.",
    "purchasedetails_text3": "Puoi mettere in pausa il tuo annuncio in qualsiasi momento prima della scadenza. In questo modo i crediti saranno anche sospesi per poterli utilizzare quando vuoi.",
    "purchasedetails_text4": "Valore del piano",
    "purchasedetails_text_francs": "franchi",
    "purchasedetails_text_confirm": "Conferma pagamento",
    "purchasedetails_text_congratulations1": "Congratulazioni. Il tuo",
    "purchasedetails_text_congratulations2": "il piano in evidenza è stato attivato con successo!",
    "profilemodal_option1": "Profilo cliente",
    "profilemodal_option2": "Pagina interna",
    "profilemodal_option3": "Proprietario amministrativo",
    "profilemodal_option4": "Scorta amministrativa",
    "profilemodal_title": "Scegli il tipo di profilo",
    "privacy_title1": "1. Introduzione",
    "privacy_conte1": "La nostra pagina sulla privacy ha lo scopo di informare i nostri utenti su come raccogliamo, utilizziamo e proteggiamo le loro informazioni personali mentre utilizzano la nostra piattaforma. La privacy dei nostri utenti è una priorità per noi e questa pagina descrive le nostre pratiche sulla privacy in conformità con le leggi e i regolamenti applicabili.",
    "privacy_title2": "2. Informazioni raccolte",
    "privacy_conte2": "In questa sezione, descriviamo i tipi di informazioni personali che raccogliamo dagli utenti e i metodi con cui tali informazioni vengono ottenute. Ciò include, ma non è limitato a: Informazioni fornite dagli utenti durante la registrazione, come nome, e-mail, data di nascita, ecc. Dati di utilizzo come cronologia di navigazione, interazioni con la piattaforma, preferenze dell'utente, ecc. Informazioni raccolte automaticamente tramite cookie e tecnologie simili.",
    "privacy_title3": "3. Utilizzo delle informazioni",
    "privacy_conte3": "Spieghiamo come le informazioni personali degli utenti vengono utilizzate dalla nostra piattaforma. Ciò può includere: Personalizzazione dell'esperienza utente e fornitura di contenuti pertinenti. Miglioramento continuo dei nostri servizi e funzionalità. Comunicare con gli utenti su aggiornamenti, offerte speciali, ecc. Conformità agli obblighi legali e normativi.",
    "privacy_title4": "4. Condivisione delle informazioni",
    "privacy_conte4": "Descriviamo in dettaglio con chi condividiamo le informazioni degli utenti e i motivi di tale condivisione. Ciò può riguardare: Partner di servizi che ci assistono nella fornitura dei nostri servizi. Autorità legali, quando richiesto dalla legge o in risposta a richieste ufficiali. Terze parti in caso di fusione, acquisizione o vendita di asset.",
    "privacy_title5": "5. Protezione delle informazioni",
    "privacy_conte5": "Spieghiamo le misure di sicurezza che implementiamo per proteggere le informazioni degli utenti da accessi non autorizzati, uso improprio, alterazione o divulgazione. Ciò può includere: Crittografia dei dati. Controlli di accesso. Monitoraggio regolare di sistemi e reti.",
    "privacy_title6": "6. Diritti dell'utente",
    "privacy_conte6": "Informiamo gli utenti sui loro diritti in relazione alle loro informazioni personali, incluso il diritto di accedere, correggere, eliminare o limitare l'uso dei loro dati. Forniamo inoltre informazioni su come gli utenti possono esercitare tali diritti.",
    "privacy_title7": "7. Modifiche all'Informativa sulla privacy",
    "privacy_conte7": "Ci riserviamo il diritto di aggiornare o modificare la nostra informativa sulla privacy se necessario. Eventuali modifiche significative saranno comunicate agli utenti tramite la nostra piattaforma o con altri mezzi appropriati.",
    "privacy_title8": "8. Contatti",
    "privacy_conte8": "Forniamo informazioni di contatto in modo che gli utenti possano contattarci in caso di domande, dubbi o richieste relative alla privacy.",
    "privacy_title9": "9. Data di entrata in vigore",
    "privacy_conte9": "Indichiamo la data di entrata in vigore della nostra politica sulla privacy e informiamo gli utenti dell'importanza di rivedere regolarmente questa pagina per rimanere aggiornati sulle nostre pratiche sulla privacy.",
    "privacy_title10": "10. Conformità legale",
    "privacy_conte10": "Dichiariamo il nostro impegno a rispettare le leggi e i regolamenti applicabili in materia di protezione dei dati e informiamo gli utenti dei loro diritti e rimedi in caso di violazioni della privacy.",
    "privacy_title": "Informativa sulla privacy",
    "privacy_subtitle": "Leggere attentamente i termini di utilizzo della piattaforma prima di procedere.",
    "deleteaccount_success": "Account eliminato correttamente",
    "deleteaccount_title": "Stai per eliminare definitivamente il tuo account.",
    "deleteaccount_text": "Se hai deciso di non eliminare il tuo account in questo momento, puoi semplicemente chiudere questa pagina e continuare a utilizzare la nostra piattaforma come al solito. Se hai domande o hai bisogno di assistenza, non esitare a contattare il nostro team di supporto. Siamo qui per aiutarti!",
    "deleteaccount_confirm": "Conferma eliminazione account",
    "deleteaccount_cancel": "Non voglio eliminare",
    "deleteaccount_success_title": "Il tuo account è stato eliminato con successo.",
    "deleteaccount_success_text": "È stato un piacere averti qui con noi. Ricorda che sei il benvenuto ogni volta che hai bisogno di noi. Saremo qui per aiutarti.",
    "ageverification_title": "Verifica dell'età",
    "ageverification_text1": "Questo sito web contiene contenuti riservati a persone di età superiore ai 18 anni. Cliccando su \"Conferma\", dichiari di essere maggiorenne secondo le leggi della tua giurisdizione. Se non hai l'età minima richiesta, abbandona immediatamente questo sito.",
    "ageverification_text2": "La falsa conferma dell'età è una violazione dei termini di utilizzo di questo sito.",
    "ageverification_text3": "Hai più di 18 anni",
    "ageverification_text4": "anni di età?",
    "ageverification_yes": "Sì",
    "ageverification_no": "No",
    "ageverification_policy": "Informativa sulla privacy",
    "ageverification_terms": "Termini di utilizzo",
    "escortinfo_reviews": "Recensioni lasciate dai clienti",
    "follower": "Follower",
    "unfollow": "Segui",
    "imagesuccessfully": "Immagine caricata correttamente",
    "admin_side_option1": "Home",
    "admin_side_option2": "Informazioni su di me",
    "admin_side_option3": "Foto",
    "admin_side_option4": "Video",
    "admin_side_option5": "Valutazioni",
    "admin_side_option6": "Servizi",
    "admin_side_option7": "Pubblicità",
    "admin_side_option8": "Crediti aggiuntivi",
    "admin_side_option9": "Piani",
    "admin_side_option10": "Statistiche",
    "admin_side_option11": "Supporto",
    "admin_side_option12": "Home",
    "admin_side_option13": "Utenti",
    "admin_side_option14": "Escort",
    "admin_side_option15": "Report",
    "admin_sidefooter_option1": "Elimina account",
    "admin_sidefooter_option2": "Esci",
    "admin_dashboard_title1": "Preferiti",
    "admin_dashboard_subtitle1": "Utenti che stanno seguendo tu",
    "admin_dashboard_title2": "Clic sugli annunci",
    "admin_dashboard_subtitle2": "Ultimi 30 giorni",
    "admin_dashboard_title3": "I tuoi crediti",
    "admin_dashboard_subtitle3": "I crediti sono scaduti",
    "admin_dashboard_title4": "Stato dell'abbonamento",
    "admin_dashboard_subtitle4": "Quasi in scadenza",
    "admin_dashboard_credit": "credito",
    "admin_dashboard_announcement": "credAnnouncementit",
    "admin_dashboard_visit": "Visite al tuo profilo",
    "admin_dashboard_visits": "visite",
    "admin_dashboard_activead": "annuncio attivo",
    "admin_dashboard_aboutme_success": "Informazioni su di me aggiornate correttamente",
    "admin_dashboard_aboutme_title": "Informazioni su di me",
    "admin_dashboard_aboutme_phone": "Telefono",
    "admin_dashboard_aboutme_whats": "Whatsapp",
    "admin_dashboard_aboutme_telegram": "Telegramma",
    "admin_dashboard_aboutme_size": "Dimensioni",
    "admin_dashboard_aboutme_weight": "Peso",
    "admin_dashboard_aboutme_nationality": "Naznality",
    "admin_dashboard_aboutme_haircolor": "Colore dei capelli",
    "admin_dashboard_aboutme_breasts": "Seno",
    "admin_dashboard_aboutme_eyecolor": "Colore degli occhi",
    "admin_dashboard_aboutme_gender": "Preferenza (genere)",
    "admin_dashboard_credits_credit": "credito",
    "admin_dashboard_credits_franc": "franchi",
    "admin_dashboard_credits_item1_title": "Come funziona l'abbonamento?",
    "admin_dashboard_credits_item1_subtitle": "Puoi abbonarti per un periodo di tempo specifico per essere presente sulla piattaforma.",
    "admin_dashboard_credits_item1_button": "30 giorni",
    "admin_dashboard_credits_item1_footer": "Dopo questo periodo, devi rinnovare l'abbonamento in modo che i tuoi annunci vengano evidenziati sulla piattaforma e aumenta le possibilità di essere assunto dagli utenti.",
    "admin_dashboard_credits_item2_title": "Crediti e periodi",
    "admin_dashboard_credits_item2_subtitle": "Per ogni giorno di annuncio in evidenza, avrai bisogno di 1 credito.",
    "admin_dashboard_credits_item2_footer": "Per ogni giorno di annuncio in evidenza, avrai bisogno di 1 credito.",
    "admin_dashboard_credits_item3_title": "Acquisto di crediti",
    "admin_dashboard_credits_item3_subtitle": "Seleziona quanti crediti vuoi acquistare.",
    "admin_dashboard_credits_title": "I tuoi crediti",
    "admin_dashboard_credits_subtitle": "I tuoi crediti sono scaduti. Devi acquistare più crediti per essere presente sulla piattaforma.",
    "admin_dashboard_plans1_title": "Piano base",
    "admin_dashboard_plans1_subtitle": "In evidenza per 5 giorni",
    "admin_dashboard_plans1_text": "Puoi avere il tuo annuncio in evidenza per 5 giorni.",
    "admin_dashboard_plans1_label": "Scegli il piano",
    "admin_dashboard_plans2_title": "Piano bronzo",
    "admin_dashboard_plans2_subtitle": "In evidenza per 15 giorni",
    "admin_dashboard_plans2_text": "Puoi avere il tuo annuncio in evidenza per 2 settimane.",
    "admin_dashboard_plans2_label": "Scegli il piano",
    "admin_dashboard_plans3_title": "Piano premium",
    "admin_dashboard_plans3_subtitle": "In evidenza per 30 giorni",
    "admin_dashboard_plans3_text": "Puoi avere il tuo annuncio in evidenza per 1 mese.",
    "admin_dashboard_plans3_label": "Scegli il piano",
    "admin_dashboard_plans_title": "I tuoi crediti",
    "admin_dashboard_plans_subtitle": "I tuoi crediti sono scaduti. Devi acquistare più crediti per essere presente sulla piattaforma.",
    "admin_dashboard_plans_choose": "Scegli un piano",
    "admin_dashboard_plans_buymore": "Acquista più crediti",
    "admin_dashboard_stats_12": "12 mesi",
    "admin_dashboard_stats_6": "6 mesi",
    "admin_dashboard_stats_30": "30 giorni",
    "admin_dashboard_stats_7": "7 giorni",
    "admin_dashboard_opt1_title": "Aggiunto ai preferiti",
    "admin_dashboard_opt1_text": "aggiunte",
    "admin_dashboard_opt2_title": "Clic sul pulsante Whatsapp",
    "admin_dashboard_opt2_text": "clic",
    "admin_dashboard_sup_subject": "Oggetto",
    "admin_dashboard_sup_message": "Messaggio",
    "admin_dashboard_sup_fill": "Compila tutti i campi",
    "admin_dashboard_sup_success": "Supporto inviato con successo",
    "admin_dashboard_sup_title": "Se hai domande, il nostro team di supporto sarà lieto di rispondere.",
    "admin_dashboard_sup_action": "Invia messaggio",
    "admin_dashboard_supfooter_email": "E-mail",
    "admin_dashboard_supfooter_phone": "Telefono",
    "admin_dashboard_supfooter_item1_title": "Domande frequenti",
    "admin_dashboard_supfooter_item1_subtitle": "Abbiamo separato alcune domande che sono richieste qui.",
    "admin_dashboard_supfooter_item1_label": "Accedi alle FAQ",
    "admin_dashboard_supfooter_item2_title": "Informativa sulla privacy",
    "admin_dashboard_supfooter_item2_subtitle": "Scopri di più sulle informative sulla privacy.",
    "admin_dashboard_supfooter_item2_label": "Accesso",
    "admin_dashboard_supfooter_item3_title": "Termini del servizio",
    "admin_dashboard_supfooter_item3_subtitle": "Scopri di più sui termini del servizio.",
    "admin_dashboard_supfooter_item3_label": "Accesso",
    "admin_dashboard_delete_text": "Se hai deciso di non continuare a utilizzare la nostra piattaforma, ti offriamo la possibilità di eliminare definitivamente il tuo account. Questo processo è irreversibile e comporterà la rimozione completa di tutte le informazioni associate al tuo account, inclusi il tuo profilo, la cronologia delle attività e i dati personali. Tieni presente che quando elimini il tuo account, perderai l'accesso a tutte le funzionalità e ai servizi offerti dalla piattaforma. Inoltre, tutte le transazioni, i messaggi o le interazioni completati in precedenza verranno eliminati definitivamente e non potranno essere recuperati. Se sei sicuro di voler procedere con l'eliminazione del tuo account, conferma la tua decisione di seguito. Una volta confermato, il tuo account verrà eliminato definitivamente e verrai automaticamente disconnesso dalla piattaforma",
    "admin_dashboard_delete_title": "Cosa devi sapere prima di eliminare il tuo account?",
    "admin_dashboard_delete_action": "Elimina il mio account",
    "what_are_you_looking_for": "Cosat stai cercando?",
    "admin_dashboardowner_item1_title": "Nuovi utenti",
    "admin_dashboardowner_item1_subtitle": "Ultimi 30 giorni",
    "admin_dashboardowner_item1_legend": "nuovi utenti",
    "admin_dashboardowner_item2_title": "Nuova escort",
    "admin_dashboardowner_item2_subtitle": "Ultimi 30 giorni",
    "admin_dashboardowner_item2_legend": "nuovi modelli",
    "admin_dashboardowner_item3_title": "Visite al sito web",
    "admin_dashboardowner_item3_subtitle": "Ultimi 30 giorni",
    "admin_dashboardowner_item4_title": "Metodi di pagamento",
    "admin_dashboardowner_item4_subtitle": "Metodo utilizzato dai tuoi clienti",
    "admin_dashboardowner_item4_value": "stripe",
    "admin_dashboardowner_escortcontrol_title": "Escort da approvare",
    "admin_dashboardowner_escortcontrol_subtitle": "Assicurati che il modello rispetti tutte le policy della piattaforma.",
    "admin_dashboardowner_escortcontrol_seeall": "Visualizza tutte le richieste",
    "admin_dashboardowner_escortcontrol_approve": "Da approvare",
    "admin_dashboardowner_escortcontrol_reject": "Rifiuta",
    "admin_dashboardowner_escortcontrol_seemore": "Visualizza altro",
    "admin_dashboardowner_top_title": "Escort migliori",
    "admin_dashboardowner_top_subtitle": "Ultimi 30 giorni",
    "admin_dashboardowner_top_complete": "Classifica completa",
    "admin_dashboardowner_older": "Più vecchio",
    "admin_dashboardowner_newest": "Più recente",
    "admin_dashboardowner_user": "Utenti attivi",
    "admin_dashboardowner_deleted": "Hai eliminato i tuoi account",
    "admin_dashboardowner_platformuser": "Utenti della piattaforma",
    "admin_dashboardowner_usersince": "Utente da",
    "admin_dashboardowner_lastaccess": "Ultimo accesso",
    "admin_dashboardowner_selectperiod": "Seleziona il periodo",
    "admin_dashboardowner_sortby": "Ordina per",
    "admin_dashboardowner_newuser": "nuovi utenti",
    "admin_dashboardowner_newescorts": "nuove escort",
    "admin_dashboardowner_newmodels": "nuove modelle",
    "admin_dashboardowner_modelstitle": "Modelle",
    "admin_dashboardowner_userstitle": "Utenti",
    "admin_dashboardowner_modelranking": "Classifica modello",
    "admin_dashboardowner_busiestaccess": "Orari di accesso più trafficati sulla piattaforma",
    "admin_dashboardowner_hits": "hits",
    "admin_dashboardowner_accessesperiod": "Accessi per periodo",
    "admin_dashboardowner_models": "modelli",
    "admin_dashboardowner_activeuser": "utenti attivi",
    "admin_dashboardowner_deletedaccount": "Eliminati i tuoi account",
    "admin_dashboardowner_user_name": "Nome",
    "admin_dashboardowner_user_email": "E-mail",
    "admin_dashboardowner_user_phone": "Telefono",
    "admin_dashboardowner_user_whatsapp": "Whatsapp",
    "admin_dashboardowner_user_telegram": "Telegramma",
    "admin_dashboardowner_user_usersince": "Utente da",
    "admin_dashboardowner_user_status": "Stato",
    "admin_dashboardowner_user_active": "Attivo",
    "admin_dashboardowner_user_inactive": "Inattivo",
    "admin_dashboardowner_user_lastaccess": "Ultimo accesso",
    "admin_dashboardowner_user_modify": "Modifica informazioni",
    "admin_dashboardowner_user_save": "Salva",
    "admin_dashboardowner_user_redefine": "Ridefinisci password",
    "admin_dashboardowner_user_suspend": "Sospendi account",
    "admin_dashboardowner_user_sendemail": "Invia email",
    "admin_dashboardowner_user_success": "Aggiornato con successo",
    "admin_dashboardowner_user_successsuspend": "Sospeso con successo",
    "admin_dashboardowner_user_successreset": "Inviata email di reimpostazione password",
    "admin_dashboardowner_user_back": "Indietro",
    "admin_dashboardowner_user_actions": "Azioni amministratore",
    "admin_dashboardowner_escorts_it1_title": "Modelli attivi",
    "admin_dashboardowner_escorts_it2_title": "Eliminati i tuoi account",
    "admin_dashboardowner_escorts_it3_title": "Escort bannate",
    "admin_dashboardowner_escorts_col1_title": "Utenti della piattaforma",
    "admin_dashboardowner_escorts_col2_title": "Utente da",
    "admin_dashboardowner_escorts_col3_title": "Ultimo accesso",
    "admin_dashboardowner_escorts_col4_title": "Data di eliminazione dell'account",
    "admin_dashboardowner_escorts_col5_title": "Data di divieto",
    "admin_dashboardowner_loadmore": "Carica altro",
    "availability_hours": "Orari di disponibilità",
    "price": "Prezzo",
    "where_do_you_serve": "Dove servi",
    "availability": "Disponibilità",
    "duration": "Durata",
    "value": "Valore",
    "location": "Posizione",
    "about_me": "Informazioni su di me",
    "service": "Servizio",
    "hygiene": "Igiene",
    "socialtime": "Tempo sociale",
    "nodata": "Data non disponibile",
    "nocomment": "Nessun commento disponibile.",
    "annon": "Anonimo",
    "noreview": "Nessuna recensione disponibile.",
    "addverification": "Aggiungi badge di verifica",
    "removeerification": "Rimuovi badge di verifica",
    "admin_dashboardowner_morning": "Mattina",
    "admin_dashboardowner_afternoon": "Pomeriggio",
    "admin_dashboardowner_night": "Notte",
    "admin_dashboardowner_early_morning": "Mattina presto",
    "admin_dashboardowner_activeescorts": "Unaescort attive",
    "admin_dashboardowner_activeusers": "Utenti attivi",
    "admin_dashboardowner_bannedescorts": "Escort bannate",
    "admin_dashboardowner_bannedusers": "Utenti bannati",
    "admin_dashboardowner_visitprofile": "Visita il profilo",
    "city_state": "Città Stato",
    "posts": "post",
    "videos": "video",
    "likes": "mi piace",
    "comments": "commenti",
}