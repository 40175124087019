export const static_translation = {
    "dexsire_is_respect": "Dexsire est le respect,",
    "safety_and_dignity": "sécurité et dignité",
    "register_right_now": "Inscrivez-vous dès maintenant.",
    "register": "S'inscrire",
    "our_escorts": "Nos escortes",
    "discover_some_models_available_to_you": "Découvrez quelques modèles à votre disposition",
    "load_more": "Charger plus",
    "connecting_you_to_the_highest_level_of_experience": "Vous connecter au plus haut niveau d'expérience.",
    "find_escorts_by_region": "Trouver des escortes par région",
    "contact_us": "Nous contacter",
    "frequently_asked_questions": "Foire aux questions",
    "copyright_2024_dexsire": "© Copyright 2024 Dexsire, Suisse. Tous droits réservés.",
    "privacy_policy": "Politique de confidentialité",
    "terms_of_service": "Conditions de service",
    "and": "et",
    "service_terms": "Conditions de service",
    "canton": "Canton",
    "ville": "Ville",
    "services_offered": "Services offerts",
    "category": "Catégorie",
    "clear_filter": "Effacer le filtre",
    "find_escorts": "Trouver des escortes",
    "find_the_ideal_model": "Trouvez le modèle idéal pour vous",
    "login_register": "Connexion/Inscription",
    "announcement": "Annonce",
    "my_profile": "Mon profil",
    "exit": "Sortie",
    "home": "Accueil",
    "advanced_search": "recherche avancée",
    "advertise_here": "Annoncez ici",
    "contact": "Contact",
    "im_a_escorts": "Je suis une escorte",
    "im_a_customer": "Je suis un client",
    "i_already_have_registration": "Je suis déjà inscrit",
    "i_want_to_sign_up": "Je veux m'inscrire",
    "login": "Connexion",
    "enter_your_username_and_password": "Entrez votre nom d'utilisateur et votre mot de passe.",
    "i_forgot_my_password": "J'ai oublié mon mot de passe",
    "to_enter": "Pour entrer",
    "email": "E-mail",
    "password": "Mot de passe",
    "fill_email_field": "Remplir le champ e-mail",
    "fill_name_field": "Remplir le champ nom",
    "fill_password_field": "Remplir le champ mot de passe",
    "fill_360_video": "Remplir la vidéo 360",
    "minimum_4_photos": "4 photos minimum",
    "fill_pseudo_field": "Remplir le champ Pseudo",
    "fill_category_field": "Remplir le champ Catégorie",
    "fill_bio_field": "Remplir le champ Bio",
    "fill_age_field": "Remplir le champ Âge",
    "your_age_must_be_over_18": "Votre âge doit être supérieur à 18 ans",
    "fill_phone_field": "Remplir le champ Téléphone",
    "fill_canton_field": "Remplir le champ Canton",
    "fill_ville_field": "Remplir le champ Ville",
    "fill_nationality_field": "Remplir le champ Nationalité",
    "fill_size_field": "Remplir le champ Taille",
    "fill_weight_field": "Remplir le champ Poids",
    "fill_hair_color_field": "Remplir le champ Couleur des cheveux",
    "fill_breasts_field": "Remplir le champ Poitrine",
    "fill_eyes_color_field": "Remplir le champ Couleur des yeux",
    "select_at_least_one_mobility_option": "Sélectionnez au moins une option de mobilité",
    "select_at_least_one_payment_option": "Sélectionnez au moins une option de paiement",
    "select_at_least_one_language_option": "Sélectionnez au moins une option de langue",
    "fill_in_your_details": "Remplissez vos coordonnées",
    "user_registration": "Enregistrement de l'utilisateur",
    "plan": "Plan",
    "select_a_plan": "Sélectionnez un plan",
    "personal_data": "Données personnelles",
    "profile": "Profil",
    "appearance": "Apparence",
    "payment": "Paiement",
    "model_registration": "Enregistrement du modèle",
    "buy_highlights": "Acheter des points forts",
    "fill_in_your_profile": "Remplissez votre profil",
    "name": "Nom",
    "confirm_password": "Confirmer le mot de passe",
    "fill_all_fields": "Remplir tous les champs",
    "invalid_email": "E-mail invalide",
    "password_and_confirmation_not_match": "Le mot de passe et la confirmation ne correspondent pas",
    "account_successfully_registred": "Compte enregistré avec succès !",
    "registration_completed_successfully": "Inscription terminée avec succès",
    "you_must_verify": "Vous devez vérifier votre e-mail pour activer votre compte. Si vous ne le trouvez pas dans votre boîte de réception, veuillez vérifier votre dossier spam.",
    "take_the_opportunity": "Profitez de l'occasion pour mettre en avant votre profil, investissez dans des crédits pour rester au top.",
    "want_to_buy_later": "Je veux acheter plus tard",
    "i_want_to_buy_credits_now": "Je veux acheter des crédits maintenant",
    "advance": "Avance",
    "now_its_time_to_report_your_appearance": "Il est maintenant temps de signaler votre apparition",
    "verification_photo": "Photo de vérification",
    "exemple": "Exemple",
    "send_a_photo_holding": "envoyez une photo tenant un papier avec dexsire.com écrit dessus",
    "drag_the_image_here_or_click_here": "Faites glisser l'image ici ou cliquez ici",
    "i_agree_with_the_platform": "Je suis d'accord avec les platform's",
    "accept_terms_to_continue": "Accepter les conditions pour continuer",
    "pseudo": "Pseudo",
    "bio": "Bio",
    "age": "Âge",
    "phone": "Téléphone",
    "nationality": "Nationalité",
    "size": "Taille",
    "weight": "Poids",
    "height": "Taille",
    "language": "Langue",
    "hair_color": "Couleur des cheveux",
    "breasts": "Seins",
    "eye_color": "Couleur des yeux",
    "mobility": "Mobilité",
    "payments_accepted": "Paiements acceptés",
    "languages_spoken": "Langues parlées",
    "what_services_do_you_offer": "Quels services proposez-vous ?",
    "describe_yourself_below": "Décrivez-vous ci-dessous :",
    "note": "Remarque :",
    "the_file_is_too_long": "Le fichier est trop volumineux. La taille maximale est de 50 Mo.",
    "upload_a_360": "Télécharger une vidéo à 360 °",
    "instruction_on_how": "Instructions pour enregistrer une vidéo à 360 °",
    "drag_video": "Faites glisser la vidéo ici ou cliquez ici",
    "supported_video": "Fichiers pris en charge : MP4 ou AVI",
    "maximum_video": "Taille maximale du fichier : 50 Mo",
    "upload": "Télécharger",
    "drag_image": "Faites glisser l'image ici ou cliquez ici",
    "supported_image": "Fichiers pris en charge : JPG et PNG",
    "maximum_image": "Taille maximale du fichier : 8 Mo. Minimum 4 photos.",
    "the_image_is_too_big": "Le fichier est trop volumineux. La taille maximale est de 8 Mo.",
    "in_addition": "En plus de la mise en surbrillance périodique incluse dans le cadre des fonctionnalités du panneau de configuration, vous aurez également la possibilité d'acheter des mises en surbrillance supplémentaires. Cela vous permettra de mettre vos annonces en avant plus fréquemment ou pendant des périodes plus longues, augmentant encore votre visibilité sur la plateforme.",
    "license": "Licence",
    "license_5days": "5 jours - Licence",
    "license_5days_price": "89,90 francs",
    "payment_via": "Le paiement de votre abonnement s'effectuera via",
    "continue": "Continuer",
    "choose_plan": "Choisir un plan",
    "announcement_title_1": "Interface claire et intuitive",
    "announcement_desc_1": "Nous proposons une interface claire et intuitive afin que les modèles puissent gérer leurs annonces efficacement et sans complications. Notre plateforme est conçue pour être facile à utiliser, offrant une expérience de gestion des annonces sans stress et agréable.",
    "announcement_title_2": "Options de gestion des annonces",
    "announcement_desc_2": "Ayez un contrôle total sur vos annonces grâce à nos options de création, de modification et de suppression. Vous pouvez créer de nouvelles annonces, modifier les informations existantes et supprimer des annonces selon vos besoins, le tout au même endroit.",
    "announcement_title_3": "Mise à jour des informations personnelles",
    "announcement_desc_3": "Gardez vos informations à jour en toute simplicité. Avec notre plateforme, vous pouvez mettre à jour des informations personnelles telles que des photos et des descriptions, garantissant ainsi que vos clients reçoivent les dernières informations sur vos services.",
    "announcement_title_4": "Mettez en avant toutes les 45 minutes",
    "announcement_desc_4": "Démarquez-vous toutes les 45 minutes pour maximiser votre visibilité et attirer plus de clients. En un seul clic, vous pouvez vous assurer que votre annonce est affichée de manière visible, se démarquant des autres pour une plus grande exposition.",
    "announcement_title_5": "Acheter des mises en avant",
    "announcement_desc_5": "Augmentez votre visibilité et votre portée en achetant des mises en avant supplémentaires pour vos annonces. Nous proposons des options flexibles afin que vous puissiez choisir le plan qui correspond le mieux à vos besoins et à votre budget, en veillant à ce que votre profil reçoive l'attention qu'il mérite.",
    "announcement_title_ads": "Faites de la publicité ici",
    "announcement_desc_ads_1": "Rejoignez-nous et profitez d'une plateforme dédiée à la promotion de votre sécurité, de votre visibilité et de votre réussite.",
    "announcement_desc_ads_2": "Lorsque vous vous inscrivez sur LUXEO, vous aurez accès à une série d'avantages exclusifs, notamment :",
    "announcement_desc_ads_3": "Ne perdez pas de temps ! Inscrivez-vous maintenant et embarquez pour ce voyage réussi avec nous chez LUXEO.",
    "announcement_action_ads": "Inscrivez-vous maintenant",
    "announcement_section_title1": "Profil personnalisé",
    "announcement_section_desc1": "Créez un profil unique et attrayant qui met en valeur vos talents, vos services et votre personnalité.",
    "announcement_section_title2": "Élargissez votre visibilité",
    "announcement_section_desc2": "Touchez un public plus large et augmentez vos opportunités commerciales en étant présenté dans notre section \"Featured Girls\".",
    "announcement_section_title3": "Facilité d'utilisation",
    "announcement_section_desc3": "Notre plateforme intuitive et conviviale simplifie la gestion de votre profil et l'interaction avec des clients potentiels.",
    "announcement_section_title4": "Avis positifs",
    "announcement_section_desc4": "Recevez des commentaires précieux de clients satisfaits et augmentez votre réputation dans la communauté.",
    "announcement_section_title5": "Promotions spéciales",
    "announcement_section_desc5": "Obtenez un accès exclusif à des offres spéciales et à des événements promotionnels pour booster votre activité.",
    "announcement_form_title": "Connexion",
    "announcement_form_subtitle": "Je suis déjà annonceur",
    "announcement_form_action1": "S'inscrire",
    "announcement_form_action_title": "Je n'ai aucun contact sur la plateforme",
    "announcement_form_action2": "Créer un compte",
    "announcement_form_email": "E-mail",
    "announcement_form_password": "Mot de passe",
    "announcement_form_forgot": "J'ai oublié mon mot de passe",
    "announcement_form_submit": "Connexion",
    "forgot_title": "J'ai oublié mon mot de passe",
    "forgot_subtitle": "Entrez votre e-mail d'inscription",
    "forgot_input_email": "E-mail",
    "forgot_action": "Envoyer le lien pour le renouvellement du mot de passe",
    "forgot_success_title": "E-mail envoyé avec succès.",
    "forgot_success_text": "Nous vous avons envoyé un e-mail avec un lien pour renouveler votre mot de passe. Si vous ne le trouvez pas, vérifiez votre dossier spam.",
    "create_password_validate1": "Remplissez le champ du nouveau mot de passe",
    "create_password_validate2": "Remplissez le champ de confirmation du nouveau mot de passe",
    "create_password_validate3": "Le mot de passe et la confirmation du mot de passe ne correspondent pas",
    "create_password_success": "Mot de passe créé avec succès",
    "create_password_title": "J'ai oublié mon mot de passe",
    "create_password_text": "Créer un nouveau mot de passe",
    "create_password_input_1": "Nouveau mot de passe",
    "create_password_input_2": "Confirmation du nouveau mot de passe",
    "create_password_input_submit": "Enregistrer",
    "purchase_title": "Mettez en valeur votre profil",
    "purchase_subtitle": "Achat de crédits",
    "purchase_text": "Sélectionnez le nombre de crédits que vous souhaitez acheter",
    "purchase_action": "Je souhaite acheter plus tard",
    "purchase_selected": "Sélectionné",
    "purchase_select": "Sélectionner",
    "customer_profile_name": "Nom",
    "customer_profile_user": "Utilisateur depuis",
    "customer_profile_status": "Statut",
    "customer_profile_email": "E-mail",
    "customer_profile_subs_type": "Type d'abonnement",
    "customer_profile_subs_value": "Valeur de l'abonnement",
    "customer_profile_saved": "Enregistré",
    "customer_profile_save": "Enregistrer",
    "customer_profile_edit": "Modifier les informations",
    "customer_profile_delete": "Supprimer le compte",
    "customer_profile_fav": "Escortes que vous avez ajoutées aux favoris",
    "gallery_video": "Galerie vidéo",
    "gallery_image": "Galerie photo",
    "gallery_load_more": "Charger plus",
    "aboutme_contact": "Contactez-moi",
    "aboutme_contact_text": "Je ne réponds pas aux appels vocaux",
    "aboutme_contact_some_photos": "Voir quelques photos et vidéos",
    "aboutme_contact_photos": "Photos",
    "aboutme_contact_videos": "Vidéos",
    "review_service": "Service",
    "review_hygiene": "Hygiène",
    "review_socialtime": "Moment social",
    "review_apparence": "Apparence",
    "review_location": "Emplacement",
    "review_comment": "Commentaire",
    "review_fill_all_fields": "Remplir tous les champs",
    "review_what_customer_saying": "Ce que disent certains clients :",
    "review_left_comment": "A laissé votre commentaire",
    "review_add_your_review": "Ajoutez votre avis",
    "review_sent_review": "Avis envoyé",
    "escortservice_success": "Enregistré avec succès",
    "escortservice_title": "Contactez-moi",
    "escortservice_text": "Je ne réponds pas aux appels vocaux",
    "contact_title": "Contact",
    "contact_email": "E-mail",
    "contact_whats_desc": "Parlez-nous via WhatsApp.",
    "contact_whats_label": "Cliquez ici",
    "contact_privacy_title": "Politique de confidentialité",
    "contact_privacy_desc": "Recevez des commentaires précieux de clients satisfaits et augmentez votre réputation dans la communauté.",
    "contact_privacy_label": "Accéder à la FAQ",
    "contactform_name": "Votre nom",
    "contactform_email": "Votre meilleur email",
    "contactform_subject": "Sujet",
    "contactform_message": "Message",
    "contactform_fill_fields": "Remplir tous les champs",
    "contactform_invalidmail": "E-mail invalide",
    "contactform_success": "Contact envoyé avec succès",
    "contactform_title": "Besoin d'aide ? Ce sera un plaisir de vous parler.",
    "contactform_action": "Envoyer un message",
    "faq_title": "Questions fréquemment posées",
    "faq_subtitle": "Questions fréquemment posées par les utilisateurs sur l'utilisation de l'application, ses fonctionnalités et ses politiques.",
    "faq_text": "Vous n'avez pas trouvé ce que vous cherchiez ?",
    "faq_subtext": "Contactez-nous.",
    "faq_action": "Envoyer un message",
    "notfound_title": "Erreur 404",
    "notfound_text": "La page à laquelle vous avez essayé d'accéder n'existe pas ou est temporairement inaccessible.",
    "notfound_action": "Retour à l'accueil",
    "advancedsearch_canton": "Canton",
    "advancedsearch_age": "Âge",
    "advancedsearch_services": "Services offerts",
    "advancedsearch_category": "Catégorie",
    "advancedsearch_customerreviews": "Avis clients",
    "advancedsearch_nationality": "Nationalité/Origine ethnique",
    "advancedsearch_lang": "Langues parlées",
    "advancedsearch_appearance": "Apparence physique",
    "advancedsearch_find": "Trouver des escortes",
    "advancedsearch_title": "Recherche avancée",
    "terms_title1": "1. Introduction",
    "terms_content1": "La page Conditions d'utilisation est une partie essentielle de notre plateforme, définissant les termes et conditions qui régissent l'utilisation de nos services par les utilisateurs. Cette page établit les droits et responsabilités des utilisateurs et de l'entreprise, garantissant une expérience sûre et transparente pour toutes les personnes impliquées.",
    "terms_title2": "2. Acceptation des conditions",
    "terms_content2": "Nous expliquons que l'utilisation de notre plateforme implique uneacceptation automatique et contraignante des Conditions d'utilisation.",
    "terms_title3": "3. Description des services",
    "terms_content3": "Nous détaillons les services offerts par notre plateforme, y compris leurs fonctionnalités, caractéristiques et limites. Cela permet aux utilisateurs de comprendre clairement ce à quoi ils peuvent s'attendre lorsqu'ils utilisent nos services.",
    "terms_title4": "4. Responsabilités de l'utilisateur",
    "terms_content4": "Nous clarifions les responsabilités des utilisateurs lors de l'utilisation de notre plateforme, notamment : fournir des informations exactes et à jour lors de l'inscription, respecter les droits de propriété intellectuelle, maintenir la sécurité des identifiants d'accès et ne pas violer les lois applicables ou les droits de tiers.",
    "terms_title5": "5. Propriété intellectuelle",
    "terms_content5": "Nous informons les utilisateurs de la propriété des droits d'auteur, des marques déposées et d'autres droits de propriété intellectuelle liés à notre plateforme et à son contenu. Les utilisateurs s'engagent à respecter ces droits et à ne pas utiliser notre contenu de manière non autorisée.",
    "terms_title6": "6. Politique de confidentialité",
    "terms_content6": "Nous faisons référence à notre politique de confidentialité et expliquons comment nous traitons les informations des utilisateurs. Les utilisateurs sont encouragés à consulter notre politique de confidentialité pour comprendre comment leurs informations sont collectées, utilisées et protégées.",
    "terms_title7": "7. Modifications des conditions d'utilisation",
    "terms_content7": "Nous nous réservons le droit de modifier ou de mettre à jour les conditions d'utilisation si nécessaire. Les modifications entrent en vigueur dès la publication des conditions révisées sur notre plateforme.",
    "terms_title8": "8. Limitation de responsabilité",
    "terms_content8": "Nous exonérons la société de toute responsabilité pour les dommages directs, indirects, accessoires, consécutifs ou punitifs résultant de l'utilisation de nos services. Les utilisateurs acceptent d'utiliser nos services à leurs propres risques.",
    "terms_title9": "9. Résiliation du service",
    "terms_content9": "Nous nous réservons le droit de résilier ou de suspendre l'accès des utilisateurs à notre plateforme, sans préavis, en cas de violation des Conditions d'utilisation.",
    "terms_title10": "10. Dispositions générales",
    "terms_content10": "Nous incluons des dispositions sur la loi applicable, la juridiction, la renonciation aux droits, l'intégrité du contrat et d'autres dispositions générales pour assurer l'applicabilité et l'efficacité des Conditions d'utilisation.",
    "terms_title11": "11. Contact",
    "terms_content11": "Nous fournissons des coordonnées afin que les utilisateurs puissent nous contacter pour toute question, préoccupation ou problème concernant les Conditions d'utilisation.",
    "terms_title": "Conditions d'utilisation",
    "terms_subtitle": "Lisez attentivement les conditions d'utilisation de la plateforme avant de continuer.",
    "purchasedetails_title": "Détails de l'achat",
    "purchasedetails_text1": "Vous aurez votre publicité",
    "purchasedetails_text2": "Après cette période, vous devez réactiver votre abonnement.",
    "purchasedetails_text3": "Vous pouvez suspendre votre publicité à tout moment avant la fin du délai. De cette façon, les crédits seront également mis en pause pour que vous puissiez les utiliser quand vous le souhaitez.",
    "purchasedetails_text4": "Valeur du forfait",
    "purchasedetails_text_francs": "francs",
    "purchasedetails_text_confirm": "Confirmer le paiement",
    "purchasedetails_text_congratulations1": "Félicitations. Votre",
    "purchasedetails_text_congratulations2": "le forfait en vedette a été activé avec succès !",
    "profilemodal_option1": "Profil client",
    "profilemodal_option2": "Page interne",
    "profilemodal_option3": "Propriétaire administratif",
    "profilemodal_option4": "Escorte administrative",
    "profilemodal_title": "Choisissez le type de profil",
    "privacy_title1": "1. Introduction",
    "privacy_conte1": "Notre page de confidentialité vise à informer nos utilisateurs sur la manière dont nous collectons, utilisons et protégeons leurs informations personnelles lorsqu'ils utilisent notre plateforme. La confidentialité de nos utilisateurs est une priorité pour nous, et cette page détaille nos pratiques de confidentialité conformément aux lois et réglementations applicables.",
    "privacy_title2": "2. Informations collectées",
    "privacy_conte2": "Dans cette section, nous décrivons les types d'informations personnelles que nous collectons auprès des utilisateurs et les méthodes par lesquelles ces informations sont obtenues. Cela comprend, mais sans s'y limiter : Les informations fournies par les utilisateurs lors de l'inscription, telles que le nom, l'e-mail, la date de naissance, etc. Les données d'utilisation telles que l'historique de navigation, les interactions avec la plateforme, les préférences de l'utilisateur, etc. Les informations collectées automatiquement via les cookies et technologies similaires.",
    "privacy_title3": "3. Utilisation des informations",
    "privacy_conte3": "Nous expliquons comment les informations personnelles des utilisateurs sont utilisées par notre plateforme. Cela peut inclure : La personnalisation de l'expérience utilisateur et la fourniture de contenu pertinent. L'amélioration continue de nos services et fonctionnalités. Communiquer avec les utilisateurs sur les mises à jour, les offres spéciales, etc. Respect des obligations légales et réglementaires.",
    "privacy_title4": "4. Partage d'informations",
    "privacy_conte4": "Nous détaillons avec qui nous partageons les informations des utilisateurs et les raisons de ce partage. Cela peut impliquer : Les partenaires de service qui nous aident à fournir",
    "privacy_title5": "5. Protection des informations",
    "privacy_conte5": "Nous expliquons les mesures de sécurité que nous mettons en œuvre pour protéger les informations des utilisateurs contre tout accès non autorisé, utilisation abusive, altération ou divulgation. Cela peut inclure : Cryptage des données. Contrôles d'accès. Surveillance régulière des systèmes et des réseaux.",
    "privacy_title6": "6. Droits des utilisateurs",
    "privacy_conte6": "Nous informons les utilisateurs de leurs droits concernant leurs informations personnelles, y compris le droit d'accéder, de corriger, de supprimer ou de limiter l'utilisation de leurs données. Nous fournissons également des informations sur la manière dont les utilisateurs peuvent exercer ces droits.",
    "privacy_title7": "7. Modifications de la politique de confidentialité",
    "privacy_conte7": "Nous nous réservons le droit de mettre à jour ou de modifier notre politique de confidentialité si nécessaire. Tout changement important sera communiqué aux utilisateurs via notre plateforme ou par d'autres moyens appropriés.",
    "privacy_title8": "8. Contact",
    "privacy_conte8": "Nous fournissons des informations de contact afin que les utilisateurs puissent nous contacter s'ils ont des questions, des préoccupations ou des demandes liées à la confidentialité.",
    "privacy_title9": "9. Date d'entrée en vigueur",
    "privacy_conte9": "Nous indiquons la date d'entrée en vigueur de notre politique de confidentialité et informons les utilisateurs de l'importance de consulter régulièrement cette page pour rester à jour avec nos pratiques de confidentialité.",
    "privacy_title10": "10. Conformité légale",
    "privacy_conte10": "Nous déclarons notre engagement à respecter les lois et réglementations applicables en matière de protection des données et informons les utilisateurs de leurs droits et recours en cas de violation de la vie privée.",
    "privacy_title": "Politique de confidentialité",
    "privacy_subtitle": "Lisez attentivement les conditions d'utilisation de la plateforme avant de continuer.",
    "deleteaccount_success": "Compte supprimé avec succès",
    "deleteaccount_title": "Vous êtes sur le point de supprimer définitivement votre compte.",
    "deleteaccount_text": "Si vous avez décidé de ne pas supprimer votre compte pour le moment, vous pouvez simplement fermer cette page et continuer à utiliser notre plateforme comme d'habitude. Si vous avez des questions ou avez besoin d'aide, n'hésitez pas à contacter notre équipe d'assistance. Nous sommes là pour vous aider !",
    "deleteaccount_confirm": "Confirmer la suppression du compte",
    "deleteaccount_cancel": "Je ne veux pas supprimer",
    "deleteaccount_success_title": "Votre compte a été supprimé avec succès.",
    "deleteaccount_success_text": "Ce fut un plaisir de vous avoir parmi nous. N'oubliez pas que vous êtes le bienvenu chaque fois que vous avez besoin de nous. Nous serons là pour vous aider.",
    "ageverification_title": "Vérification de l'âge",
    "ageverification_text1": "Ce site Web contient du contenu réservé aux personnes de plus de 18 ans. En cliquant sur « Confirmer », vous certifiez que vous avez l'âge légal en vertu des lois de votre juridiction. Si vous n'avez pas l'âge minimum requis, veuillez quitter ce site immédiatement.",
    "ageverification_text2": "Une fausse confirmation d'âge est une violation des conditions d'utilisation de ce site.",
    "ageverification_text3": "Avez-vous plus de 18 ans",
    "ageverification_text4": "ans ?",
    "ageverification_yes": "Oui",
    "ageverification_no": "Non",
    "ageverification_policy": "Politique de confidentialité",
    "ageverification_terms": "Conditions d'utilisation",
    "escortinfo_reviews": "Avis laissés par les clients",
    "follower": "Follower",
    "unfollow": "Suivre",
    "imagesuccessfully": "Image téléchargée avec succès",
    "admin_side_option1": "Accueil",
    "admin_side_option2": "À propos de moi",
    "admin_side_option3": "Photos",
    "admin_side_option4": "Vidéos",
    "admin_side_option5": "Notes",
    "admin_side_option6": "Services",
    "admin_side_option7": "Publicités",
    "admin_side_option8": "Crédits supplémentaires",
    "admin_side_option9": "Plans",
    "admin_side_option10": "Statistiques",
    "admin_side_option11": "Assistance",
    "admin_side_option12": "Accueil",
    "admin_side_option13": "Utilisateurs",
    "admin_side_option14": "Escortes",
    "admin_side_option15": "Rapports",
    "admin_sidefooter_option1": "Supprimer le compte",
    "admin_sidefooter_option2": "Quitter",
    "admin_dashboard_title1": "Favoris",
    "admin_dashboard_subtitle1": "Utilisateurs qui vous suivent",
    "admin_dashboard_title2": "Publicité clics",
    "admin_dashboard_subtitle2": "Les 30 derniers jours",
    "admin_dashboard_title3": "Vos crédits",
    "admin_dashboard_subtitle3": "Les crédits sont terminés",
    "admin_dashboard_title4": "Statut de l'abonnement",
    "admin_dashboard_subtitle4": "Presque expiré",
    "admin_dashboard_credit": "crédit",
    "admin_dashboard_announcement": "credAnnouncementit",
    "admin_dashboard_visit": "Visites sur votre profil",
    "admin_dashboard_visits": "visites",
    "admin_dashboard_activead": "annonce active",
    "admin_dashboard_aboutme_success": "À propos de moi mis à jour avec succès",
    "admin_dashboard_aboutme_title": "À propos de moi",
    "admin_dashboard_aboutme_phone": "Téléphone",
    "admin_dashboard_aboutme_whats": "Whatsapp",
    "admin_dashboard_aboutme_telegram": "Télégramme",
    "admin_dashboard_aboutme_size": "Taille",
    "admin_dashboard_aboutme_weight": "Poids",
    "admin_dashboard_aboutme_nationality": "Nationalité",
    "admin_dashboard_aboutme_haircolor": "Couleur des cheveux",
    "admin_dashboard_aboutme_breasts": "Poitrine",
    "admin_dashboard_aboutme_eyecolor": "Couleur des yeux",
    "admin_dashboard_aboutme_gender": "Préférence (Sexe)",
    "admin_dashboard_credits_credit": "crédit",
    "admin_dashboard_credits_franc": "francs",
    "admin_dashboard_credits_item1_title": "Comment fonctionne l'abonnement ?",
    "admin_dashboard_credits_item1_subtitle": "Vous pouvez vous abonner pour une durée déterminée pour être mis en avant sur la plateforme.",
    "admin_dashboard_credits_item1_button": "30 jours",
    "admin_dashboard_credits_item1_footer": "Après cette période, vous devez renouveler votre abonnement afin que vos annonces soient mises en avant sur la plateforme et augmentent les chances d'être embauché par les utilisateurs.",
    "admin_dashboard_credits_item2_title": "Crédits et périodes",
    "admin_dashboard_credits_item2_subtitle": "Pour chaque jour d'annonce en vedette, vous aurez besoin de 1 crédit.",
    "admin_dashboard_credits_item2_footer": "Pour chaque jour d'annonce en vedette, vous aurez besoin de 1 crédit.",
    "admin_dashboard_credits_item3_title": "Achat de crédits",
    "admin_dashboard_credits_item3_subtitle": "Sélectionnez le nombre de crédits que vous souhaitez acheter.",
    "admin_dashboard_credits_title": "Vos crédits",
    "admin_dashboard_credits_subtitle": "Vos crédits ont expiré. Vous devez acheter plus de crédits pour être mis en avant sur la plateforme.",
    "admin_dashboard_plans1_title": "Plan de base",
    "admin_dashboard_plans1_subtitle": "En vedette pendant 5 jours",
    "admin_dashboard_plans1_text": "Votre annonce peut être mise en avant pendant 5 jours.",
    "admin_dashboard_plans1_label": "Choisir un plan",
    "admin_dashboard_plans2_title": "Plan Bronze",
    "admin_dashboard_plans2_subtitle": "En vedette pendant 15 jours",
    "admin_dashboard_plans2_text": "Votre annonce peut être mise en avant pendant 2 semaines.",
    "admin_dashboard_plans2_label": "Choisir un plan",
    "admin_dashboard_plans3_title": "Plan Premium",
    "admin_dashboard_plans3_subtitle": "En vedette pendant 30 jours",
    "admin_dashboard_plans3_text": "Vous pouvez faire figurer votre annonce pendant 1 mois.",
    "admin_dashboard_plans3_label": "Choisir un plan",
    "admin_dashboard_plans_title": "Vos crédits",
    "admin_dashboard_plans_subtitle": "Vos crédits ont expiré. Vous devez acheter plus de crédits pour apparaître sur la plateforme.",
    "admin_dashboard_plans_choose": "Choisir un plan",
    "admin_dashboard_plans_buymore": "Acheter plus de crédits",
    "admin_dashboard_stats_12": "12 mois",
    "admin_dashboard_stats_6": "6 mois",
    "admin_dashboard_stats_30": "30 jours",
    "admin_dashboard_stats_7": "7 jours",
    "admin_dashboard_opt1_title": "Ajouté aux favoris",
    "admin_dashboard_opt1_text": "ajouts",
    "admin_dashboard_opt2_title": "Clics sur le bouton Whatsapp",
    "admin_dashboard_opt2_text": "clics",
    "admin_dashboard_sup_subject": "Sujet",
    "admin_dashboard_sup_message": "Message",
    "admin_dashboard_sup_fill": "Remplir tous les champs",
    "admin_dashboard_sup_success": "Support envoyé avec succès",
    "admin_dashboard_sup_title": "Si vous avez des questions, notre équipe d'assistance se fera un plaisir de vous répondre.",
    "admin_dashboard_sup_action": "Envoyer un message",
    "admin_dashboard_supfooter_email": "E-mail",
    "admin_dashboard_supfooter_phone": "Téléphone",
    "admin_dashboard_supfooter_item1_title": "Questions fréquemment posées",
    "admin_dashboard_supfooter_item1_subtitle": "Nous avons séparé certaines questions qui sont demandées ici.",
    "admin_dashboard_supfooter_item1_label": "Accéder à la FAQ",
    "admin_dashboard_supfooter_item2_title": "Politique de confidentialité",
    "admin_dashboard_supfooter_item2_subtitle": "En savoir plus sur les politiques de confidentialité.",
    "admin_dashboard_supfooter_item2_label": "Accès",
    "admin_dashboard_supfooter_item3_title": "Conditions de service",
    "admin_dashboard_supfooter_item3_subtitle": "En savoir plus sur les conditions de service.",
    "admin_dashboard_supfooter_item3_label": "Accès",
    "admin_dashboard_delete_text": "Si vous avez décidé de ne plus utiliser notre plateforme, nous vous proposons la possibilité de supprimer définitivement votre compte. Ce processus est irréversible et entraînera la suppression complète de toutes les informations associées à votre compte, y compris votre profil, votre historique d'activité et vos données personnelles. Veuillez noter que lorsque vous supprimez votre compte, vous perdrez l'accès à toutes les fonctionnalités et services offerts par la plateforme. De plus, toutes les transactions, messages ou interactions précédemment effectués seront définitivement supprimés et ne pourront pas être récupérés. Si vous êtes sûr de vouloir procéder à la suppression de votre compte, veuillez confirmer votre décision ci-dessous. Une fois confirmé, votre compte sera définitivement supprimé et vous serez automatiquementcally déconnecté de la plateforme",
    "admin_dashboard_delete_title": "Que devez-vous savoir avant de supprimer votre compte ?",
    "admin_dashboard_delete_action": "Supprimer mon compte",
    "what_are_you_looking_for": "Que recherchez-vous ?",
    "admin_dashboardowner_item1_title": "Nouveaux utilisateurs",
    "admin_dashboardowner_item1_subtitle": "30 derniers jours",
    "admin_dashboardowner_item1_legend": "nouveaux utilisateurs",
    "admin_dashboardowner_item2_title": "Nouvelle escorte",
    "admin_dashboardowner_item2_subtitle": "30 derniers jours",
    "admin_dashboardowner_item2_legend": "nouveaux modèles",
    "admin_dashboardowner_item3_title": "Visites sur le site Web",
    "admin_dashboardowner_item3_subtitle": "Les 30 derniers jours",
    "admin_dashboardowner_item4_title": "Modes de paiement",
    "admin_dashboardowner_item4_subtitle": "Méthode utilisée par vos clients",
    "admin_dashboardowner_item4_value": "stripe",
    "admin_dashboardowner_escortcontrol_title": "Escorts à approuver",
    "admin_dashboardowner_escortcontrol_subtitle": "Assurez-vous que le modèle respecte toutes les politiques de la plateforme.",
    "admin_dashboardowner_escortcontrol_approve": "Escorts à approuver",
    "admin_dashboardowner_escortcontrol_seeall": "Voir toutes les demandes",
    "admin_dashboardowner_escortcontrol_reject": "Rejeter",
    "admin_dashboardowner_escortcontrol_seemore": "Voir plus",
    "admin_dashboardowner_top_title": "Meilleures escortes",
    "admin_dashboardowner_top_subtitle": "30 derniers jours",
    "admin_dashboardowner_top_complete": "Classement complet",
    "admin_dashboardowner_older": "Plus ancien",
    "admin_dashboardowner_newest": "Le plus récent",
    "admin_dashboardowner_user": "Utilisateurs actifs",
    "admin_dashboardowner_deleted": "Comptes supprimés",
    "admin_dashboardowner_platformuser": "Utilisateurs de la plateforme",
    "admin_dashboardowner_usersince": "Utilisateur depuis",
    "admin_dashboardowner_lastaccess": "Dernier accès",
    "admin_dashboardowner_selectperiod": "Sélectionnez la période",
    "admin_dashboardowner_sortby": "Trier par",
    "admin_dashboardowner_newuser": "nouveaux utilisateurs",
    "admin_dashboardowner_newescorts": "nouvelles escortes",
    "admin_dashboardowner_newmodels": "nouveaux modèles",
    "admin_dashboardowner_modelstitle": "Modèles",
    "admin_dashboardowner_userstitle": "Utilisateurs",
    "admin_dashboardowner_modelranking": "Classement des modèles",
    "admin_dashboardowner_busiestaccess": "Heures d'accès les plus chargées sur la plateforme",
    "admin_dashboardowner_hits": "hits",
    "admin_dashboardowner_accessesperiod": "Accès par période",
    "admin_dashboardowner_models": "modèles",
    "admin_dashboardowner_activeuser": "utilisateurs actifs",
    "admin_dashboardowner_deletedaccount": "Vos comptes supprimés",
    "admin_dashboardowner_user_name": "Nom",
    "admin_dashboardowner_user_email": "E-mail",
    "admin_dashboardowner_user_phone": "Téléphone",
    "admin_dashboardowner_user_whatsapp": "Whatsapp",
    "admin_dashboardowner_user_telegram": "Télégramme",
    "admin_dashboardowner_user_usersince": "Utilisateur depuis",
    "admin_dashboardowner_user_status": "Statut",
    "admin_dashboardowner_user_active": "Actif",
    "admin_dashboardowner_user_inactive": "Inactif",
    "admin_dashboardowner_user_lastaccess": "Dernier accès",
    "admin_dashboardowner_user_modify": "Modifier les informations",
    "admin_dashboardowner_user_save": "Enregistrer",
    "admin_dashboardowner_user_redefine": "Redéfinir le mot de passe",
    "admin_dashboardowner_user_suspend": "Suspendre compte",
    "admin_dashboardowner_user_sendemail": "Envoyer un e-mail",
    "admin_dashboardowner_user_success": "Mise à jour réussie",
    "admin_dashboardowner_user_successsuspend": "Suspendu avec succès",
    "admin_dashboardowner_user_successreset": "E-mail de réinitialisation du mot de passe envoyé",
    "admin_dashboardowner_user_back": "Retour",
    "admin_dashboardowner_user_actions": "Actions de l'administrateur",
    "admin_dashboardowner_escorts_it1_title": "Modèles actifs",
    "admin_dashboardowner_escorts_it2_title": "Vos comptes supprimés",
    "admin_dashboardowner_escorts_it3_title": "Escortes bannies",
    "admin_dashboardowner_escorts_col1_title": "Utilisateurs de la plateforme",
    "admin_dashboardowner_escorts_col2_title": "Utilisateur depuis",
    "admin_dashboardowner_escorts_col3_title": "Dernier accès",
    "admin_dashboardowner_escorts_col4_title": "Date de suppression du compte",
    "admin_dashboardowner_escorts_col5_title": "Date d'interdiction",
    "admin_dashboardowner_loadmore": "Charger plus",
    "availability_hours": "Heures de disponibilité",
    "price": "Prix",
    "where_do_you_serve": "Où servez-vous",
    "availability": "Disponibilité",
    "duration": "Durée",
    "value": "Valeur",
    "location": "Emplacement",
    "about_me": "À propos de moi",
    "service": "Service",
    "hygiene": "Hygiène",
    "socialtime": "Temps social",
    "nodata": "Date non disponible",
    "nocomment": "Aucun commentaire disponible.",
    "annon": "Anonyme",
    "noreview": "Aucun avis disponible.",
    "addverification": "Ajouter un badge de vérification",
    "removeerification": "Supprimer le badge de vérification",
    "admin_dashboardowner_morning": "Matin",
    "admin_dashboardowner_afternoon": "Après-midi",
    "admin_dashboardowner_night": "Nuit",
    "admin_dashboardowner_early_morning": "Tôt le matin",
    "admin_dashboardowner_activeescorts": "Escortes actives",
    "admin_dashboardowner_activeusers": "Utilisateurs actifs",
    "admin_dashboardowner_bannedescorts": "Escortes bannies",
    "admin_dashboardowner_bannedusers": "Utilisateurs bannis",
    "admin_dashboardowner_visitprofile": "Visiter profil",
    "city_state": "Cité-État",
    "posts": "posts",
    "videos": "vidéos",
    "likes": "j'aime",
    "comments": "commentaires",
}